@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.image-viewer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999998;
  padding: 25px;
  color: #65707A;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
.image-viewer .top-row {
  display: flex;
  width: 100%;
}
.image-viewer .gallery {
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(#353535, #616161);
}
.image-viewer .gallery .img-container {
  overflow: hidden;
}
.image-viewer .gallery .img-container .full-preview-element .loading {
  display: flex;
  justify-content: center;
}
.image-viewer .gallery .img-container .full-preview-element #image-preview {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  transform-origin: 0 0;
  display: flex;
  justify-content: center;
}
.image-viewer .gallery .img-container .full-preview-element #image-preview > img {
  width: auto;
  height: auto;
  max-width: 100%;
}
.image-viewer .gallery .bottom-nav {
  background-color: rgba(29, 29, 29, 0.65);
  display: grid;
  grid-template-columns: 1fr 160px 1fr;
  position: relative;
  bottom: 48px;
  margin-bottom: -48px;
}
.image-viewer .gallery .bottom-nav .image-title-container {
  padding: 13px 0 0 13px;
  font-weight: 100;
  color: #EFEFEF;
}
.image-viewer .gallery .bottom-nav .nav-button-container {
  display: flex;
  justify-content: center;
  grid-column: 2;
}
.image-viewer .gallery .bottom-nav .nav-button-container .button-container {
  width: 50px;
}
.image-viewer .gallery .bottom-nav .fullscreen-button-container {
  display: flex;
  justify-content: flex-end;
  grid-column: 3;
}
.image-viewer .gallery .bottom-nav .image-number-count {
  align-content: center;
  color: #EFEFEF;
  padding-bottom: 4px;
  font-weight: 100;
}