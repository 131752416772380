@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.order-status-pill {
  padding: 2px 4px 2px;
  border-radius: 5px;
  font-size: 11px;
  margin-left: 3px;
  color: #FFFFFF;
}
.order-status-pill.pending {
  background-color: #FFDF00;
}
.order-status-pill.awaiting-approval, .order-status-pill.awaiting-po, .order-status-pill.sending-to-erp, .order-status-pill.confirmed-awaiting-po {
  background-color: #FFAC00;
}
.order-status-pill.sourcing, .order-status-pill.sourced {
  background-color: #22bda9;
}
.order-status-pill.fulfilling, .order-status-pill.confirmed {
  background-color: #4A90E2;
}
.order-status-pill.complete, .order-status-pill.ready-for-erp, .order-status-pill.invoiced {
  background-color: #699A2E;
}
.order-status-pill.erp-error {
  background-color: #9F0620;
}
.order-status-pill.canceled, .order-status-pill.order-validation-error {
  background-color: #e26464;
}