.stepper-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 40px;
}
.stepper-control .stepper-divider {
  flex: 1;
  height: 1px;
}
.stepper-control .stepper-divider.complete {
  background-color: #373a3c;
}
.stepper-control .stepper-divider.incomplete {
  background-color: #ccc;
}
.stepper-control .stepper-item {
  position: relative;
}
.stepper-control .stepper-item .title {
  position: absolute;
  bottom: 15px;
  left: -45px;
  width: 100px;
  font-size: 12px;
  color: #ababab;
  text-align: center;
}
.stepper-control .stepper-item .title.active {
  bottom: 30px;
  left: -35px;
  font-size: 16px;
  color: #373a3c;
}
.stepper-control .stepper-item .title.complete {
  color: #373a3c;
}
.stepper-control .stepper-item .circle {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}
.stepper-control .stepper-item .circle.active {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid #373a3c;
}