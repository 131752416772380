@import "../../theme/theme";

.item-chooser {
  .item-chooser-search-cell {
    &.highlighted {
      background-color: $C_GREY_WARM_6;
    }

    &.flash {
      background-color: $C_BLUE_LIGHT;
      color: #444 !important;
    }

    &.fade {
      transition: background-color 0.45s linear;
    }

    .inventory-processing-info {
      .label {
        color: $C_RED !important;
      }

      &.green-label {
        .label {
          color: #3e7800 !important;
        }
      }
    }

    .item-number {

      .explore {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .item-desc {
      color: $C_GREY_COOL_8;
      font-size: 14px;
      font-weight: $F_WEIGHT_LIGHT;

      &.qty-in-standard {
        font-weight: $F_WEIGHT_REGULAR;
      }

      p {
        margin: 0;
      }
    }
  }

  .diff-items-container {
    height: 100%;
    overflow-y: auto;
    padding: 20px 20px 80px 20px;

    .no-changes {
      margin-top: 20px;
      color: $C_GREY_COOL_2;
      font-size: 22px;
      text-align: center;
    }

    .grid-title {
      font-weight: $F_WEIGHT_MEDIUM;
      font-size: 22px;
      margin-top: 10px;
    }
  }
}
