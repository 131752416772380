@import "../../../../theme/theme";

.receipt-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $C_GREY_WARM_6;

  .receipt-bins-container {
    display: flex;
    overflow-x: scroll;
  }

  .shipment-container,
  .kit-container,
  .tray-container,
  .pkg-container,
  .bin-container {
    display: inline-block;
    padding: 5px;

    &.errors {
      .info {
        border-color: $C_RED;

        .details {
          .errors {
            color: $C_RED;
          }
        }
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      background-color: transparent;
      border: 1px solid $C_GREY_WARM_2;
      padding: 10px;
      min-height: 90px;
      min-width: 140px;
      cursor: default;

      > .header {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: $C_GREY_COOL_7;
        font-weight: 400;

        .header-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        span {
          cursor: pointer;
        }
      }

      > .details {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .line {
          display: flex;
          flex-direction: row;

          .count {
            flex: 1;
            text-align: right;
            margin-right: 5px;
          }

          .label {
            flex: 2;
            text-align: left;
          }
        }
      }

      > .footer {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    &.entered {
      &:not(.selected) {
        > .info:hover {
          cursor: pointer;
        }
      }

      > .info {
        background-color: $C_WHITE;

        &:hover {
          border-color: $C_BLUE;
          transition: border 0.3s linear;

        }

        &.errors {
          > .info {
            border-color: $C_RED_PASTEL;
          }
        }
      }
    }

    &.selected {
      > .info {
        border-color: $C_BLUE;
      }
    }

    .title-pill {
      background-color: transparent;
    }
  }
}
