@import "../theme/theme";

.open-variance-pill {
  padding: 5px 5px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 14px;

  border-style: solid;
  border-width: 1px;
  font-weight: 200;

  &.success {
    border-color: $C_GREEN;
    background-color: $C_GREEN_LIGHT;
    color: $C_GREEN;
  }

  &.fail {
    border-color: $C_RED_PASTEL;
    background-color: $C_RED_PASTEL_LIGHTER;
    color: $C_RED_PASTEL;
  }
}
