@import "../../../../../theme/theme";

.participant-modal {
  > .participant-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: default;
    height: 49px;
    justify-content: inherit;
    width: 100%;
    color: #4c5660;

    .title {
      margin-left: 5px;
      overflow: inherit;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: inherit;
    }

    > div {
      min-width: 25px;
    }
  }
}