@import "../theme/theme";

.tab-box {
  margin: 20px 0;

  > div:first-child {
    background-color: $C_GREY_COOL_12 !important;
    border-radius: $S_BORDER_RADIUS $S_BORDER_RADIUS 0 0;

    button {
      font-weight: 400 !important;
      text-transform: none !important;
      border-radius: 2px;
      color: $C_GREY_COOL_1 !important;
      z-index: 1;
    }
  }

  @media (max-width: 750px) {
    > div:first-child {
      display: flex;
      flex-wrap: wrap;

      > button {
        width: auto !important;
        margin: 0 10px !important;
      }
    }

    > div:nth-child(2) {
      display: none !important;
    }
  }
}

.tab-content {
  min-height: 100px;
  padding-top: 18px;
  overflow-x: clip;

  &.detail-page {
    position: relative;
    bottom: 48px;
    margin-bottom: -48px;
  }

  .child-margin-right {
    > * {
      margin-right: 10px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}
