@import "../theme/theme";

.tooltip-container:hover {
  cursor: help;
}

.tooltip-container .tooltip {
  visibility: hidden;
  position: absolute;
  text-align: center;
}

.tooltip-container:hover .tooltip {
  opacity: .95;
  z-index: 1000;
  visibility: visible;
  background-color: $C_WHITE;
  margin: 8px;
  padding: 12px;
  border: $C_GREY_WARM_4 1px solid;
  border-radius: 9px;
  width: 220px;
  color: $C_TEXT;
}

.sourcing-matrix-tooltip {
  font-size: 12px;
  font-weight: initial;
  text-align: initial;

  &__order-line {
    top: -0px;
    left: 56px;
  }
}
