@import "../../../../../theme/theme";

$header-row-height: 45px;
$header-height: $header-row-height * 2;
$container-val: $header-height + 10px;
$gutter: 45px;
$message-container-h-padding: 20px;
$participants-gutter: $gutter + $message-container-h-padding;
$header-divider-color: rgba(0, 0, 0, 0.05);

.conversation-detail {
  background-color: #fff;

  .title-subnav {
    z-index: $Z_SUB_NAV_BAR + 1;
    box-shadow: none;

    .purpose {
      font-size: 22px;
      margin-right: 10px;
    }
  }

  .participants-subnav {
    box-shadow: none;
    max-height: 49px;
    min-height: 49px;
    padding-right: 0;
    height: auto;

    .add-participant {
      padding-left: 10px;
    }

    .participants-container {
      display: flex;
      flex-wrap: wrap;
      align-self: flex-start;
      flex: 10;

      .participant-component {
        &:first-of-type {
          justify-content: flex-start;
        }

        justify-content: center;
      }
    }
  }

  .messages-panel {
    background-color: $C_WHITE;
    min-height: 60px;
    border-top: 2px solid $C_BLUE;
    border-bottom: 2px solid $C_BLUE;
    padding-top: $S_GUTTER;
    padding-bottom: $S_GUTTER;

    width: 100%;
    position: fixed;
    overflow: auto;
    top: 157px;
    bottom: 102px;
    display: flex;
    flex-flow: column;

    .from {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      margin-left: 26px;
      margin-right: $S_GUTTER;

      .name {
        color: $C_GREY_COOL_1;
        font-size: 15px;
        font-weight: $F_WEIGHT_REGULAR;
        margin-left: 8px;
      }
    }

    .day {
      font-size: 15px;
      color: #666;
      text-align: center;
      border-top: 1px solid #bbc2ca;
      margin-top: 15px;
      margin-bottom: -25px;
    }

    .day-label {
      position: relative;
      top: -17px;
      margin: 0 auto;
      background-color: #fff;
      display: inline-block;
      padding: .25rem .75rem;
    }

    > :first-child {
      margin-top: auto !important;
    }
  }

  .messages-panel > :first-child {
    margin-top: auto !important;
  }
}
