@import "../../../../../theme/theme";

.move-right-drawer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    user-select: none;
    padding: 10px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .sub-header {
    user-select: none;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 10px;
  }

  .content {
    flex: 1;
    display: flex;
  }
}
