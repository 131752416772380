@import "../theme/theme";

.order-status-pill {
  padding: 2px 4px 2px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  margin-left: 3px;
  color: $C_WHITE;

  &.pending {
    background-color: $C_GOLD;
  }

  &.awaiting-approval, &.awaiting-po, &.sending-to-erp, &.confirmed-awaiting-po {
    background-color: $C_ORANGE;
  }

  &.sourcing, &.sourced {
    background-color: $C_MINT;
  }

  &.fulfilling, &.confirmed {
    background-color: $C_BLUE;
  }

  &.complete, &.ready-for-erp, &.invoiced {
    background-color: $C_GREEN;
  }

  &.erp-error {
    background-color: $C_RED;
  }

  &.canceled,
  &.order-validation-error {
    background-color: $C_DEV_RED;
  }
}
