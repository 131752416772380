@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.product-chooser {
  overflow: revert;
}
.product-chooser .add-shell {
  height: 100%;
}
.product-chooser .add-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 410px;
  min-width: 1138px;
}
.product-chooser .add-container .choose-items-container {
  padding: 2px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
}
.product-chooser .add-container .choose-items-container .top-bar {
  display: flex;
  justify-content: space-between;
}
.product-chooser .add-container .no-bottom-padding {
  padding-bottom: 0;
}
.product-chooser .selected-items-container {
  height: calc(50vh - 105px);
  min-height: calc(50vh - 105px);
  min-width: 1000px;
  padding: 5px 20px 20px 20px;
}
.product-chooser .selected-items-container.expanded {
  height: calc(100vh - 150px);
}
.product-chooser .selected-items-container .selected-items-grid-container {
  height: 100%;
}
.product-chooser .half-height {
  height: 50%;
  min-height: 140px;
}
.product-chooser .full-height {
  height: 100%;
}
.product-chooser .selected-items-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  min-width: 1100px;
}
.product-chooser .selected-items-header .filter-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 900px;
  align-items: center;
}
.product-chooser .selected-items-header .item-actions {
  display: flex;
  align-items: center;
}
.product-chooser .selected-items-title {
  font-size: 1.5em;
  font-weight: 500;
  width: 230px;
}
.product-chooser .container-contents-title {
  padding: 10px 20px 10px;
  color: #31373D;
  font-size: 17px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.product-chooser .container-content {
  padding: 0 20px 20px 20px;
  overflow: auto;
  flex: 1;
}
.product-chooser .no-bottom-padding {
  padding-bottom: 0;
}
.product-chooser .item-desc {
  font-size: 0.8em;
  color: #868E97;
}
.product-chooser .product-chooser-search-cell.highlighted {
  background-color: #CCCCCC;
}
.product-chooser .product-chooser-search-cell.flash {
  background-color: #78ACEA;
  color: #444 !important;
}
.product-chooser .product-chooser-search-cell.fade {
  transition: background-color 0.45s linear;
}
.product-chooser .product-chooser-search-cell .inventory-processing-info .label {
  color: #9F0620 !important;
}
.product-chooser .product-chooser-search-cell .inventory-processing-info.green-label .label {
  color: #3e7800 !important;
}
.product-chooser .product-chooser-search-cell .item-number .explore {
  margin-left: 5px;
  font-size: 12px;
}
.product-chooser .product-chooser-search-cell .description {
  color: #999999;
  font-size: 14px;
  font-weight: 300;
}
.product-chooser .product-chooser-search-cell .description.qty-in-standard {
  font-weight: 400;
}
.product-chooser .product-chooser-search-cell .description p {
  margin: 0;
}
.product-chooser .review-items-container {
  height: 100%;
  overflow-y: auto;
  padding: 0 40px 80px 40px;
  min-width: 1020px;
}
.product-chooser .review-items-container .no-changes {
  margin-top: 20px;
  color: #BBC2CA;
  font-size: 22px;
  text-align: center;
}
.product-chooser .review-items-container .grid-title {
  font-weight: 500;
  font-size: 22px;
  margin-top: 10px;
}
.product-chooser .review-component-title {
  padding-top: 9px;
  padding-bottom: 4px;
  color: #31373D;
  font-size: 19px;
  display: grid;
  min-width: 895px;
  grid-template-columns: 50px 1fr 100px;
}
.product-chooser .review-component-title.catalog {
  align-items: center;
  padding: 7px 0;
}
.product-chooser .review-component-title.disabled {
  opacity: 0.4;
}
.product-chooser .review-component-title .catalog-title {
  display: flex;
  align-items: center;
  font-weight: 200;
}
.product-chooser .review-component-title .item-title {
  color: #4C5660;
  font-weight: 300;
  grid-column-start: 2;
}
.product-chooser .review-component-title .item-title .main-title-block {
  display: flex;
}
.product-chooser .review-component-title .item-title .item-desc {
  font-size: 14px;
  margin-bottom: 5px;
}
.product-chooser .qty-buttons {
  display: flex;
  position: relative;
  align-items: center;
}
.product-chooser .nested-bundle-qty-buttons {
  display: flex;
  position: relative;
  align-items: center;
}
.product-chooser .action-buttons {
  justify-self: end;
  padding: 0;
  display: flex;
  position: relative;
  align-items: center;
  left: 5px;
}
.product-chooser .review-component-container {
  padding-bottom: 20px;
}
.product-chooser .review-component-container .class-header {
  background-color: #7BA3CC;
  color: #FFFFFF;
  text-align: center;
  font-weight: 100;
  font-size: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: 894px;
  border-radius: 5px 5px 0 0;
}
.product-chooser .review-component-container .class-header .class-title {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 36px;
  grid-column-start: 2;
}
.product-chooser .review-component-container .class-header .class-title.nested-bundle {
  padding-right: 20px;
}
.product-chooser .review-component-container .class-header .toggle-expand {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 200;
}
.product-chooser .catalog-items-container {
  padding: 40px 20px;
  min-width: 1056px;
}
.product-chooser .catalog-items-container .catalog-title {
  padding-top: 7px;
  color: #4C5660;
  font-size: 19px;
  display: flex;
  justify-content: center;
  font-weight: 200;
  margin: 7px;
}
.product-chooser .catalog-items-container.background {
  padding: 0 20px;
  background-color: #FDFDFD;
  border-radius: 6px;
  border: solid 1px #D6D6D6;
  margin-top: 18px;
  box-shadow: 0 0 10px #dce2e7;
  min-width: 1024px;
}
.product-chooser .bundle-component-container {
  padding: 0 20px;
  border-radius: 5px;
  border: solid 1px #D6D6D6;
  background-color: #FDFDFD;
  margin-top: 15px;
  min-width: 1076px;
}
.product-chooser .bundle-component-container:hover {
  box-shadow: 0 0 10px #dce2e7;
}
.product-chooser .bundle-component-container .grid-container.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.product-chooser .nested-bundle-component-container {
  padding: 0 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #FAFAFA;
  border: solid 1px #EFEFEF;
  font-weight: 300;
}
.product-chooser .nested-bundle-component-container:hover {
  border-color: #dce2e7;
}
.product-chooser .nested-bundle-component-container .nested-bundle-title {
  padding-top: 9px;
  padding-bottom: 4px;
  color: #31373D;
  font-size: 19px;
  display: grid;
  min-width: 895px;
  grid-template-columns: 50px 1fr 82px;
}
.product-chooser .nested-bundle-component-container .nested-bundle-title .item-title {
  grid-column-start: 2;
}
.product-chooser .nested-bundle-component-container .nested-bundle-title .item-title .main-title-block {
  display: flex;
  justify-content: space-between;
}
.product-chooser .nested-bundle-component-container .grid-container.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.product-chooser .empty-message-container {
  text-align: center;
  padding-top: 130px;
}
.product-chooser .empty-message-container .items-empty {
  display: inline-block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #BBC2CA;
  background-color: #D6D6D6;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.product-chooser .empty-message-container .items-empty:hover {
  background-color: #E0E0E0;
}
.product-chooser .empty-message-container .items-empty .title {
  font-size: 22px;
}
.product-chooser .empty-message-container .items-empty .sub-title {
  font-size: 16px;
  color: #616161;
  padding-top: 7px;
  font-weight: 200;
}