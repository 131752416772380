@import "../theme/theme";

.adjustment-pill {
  margin-left: 5px;
  padding: 2px 4px 2px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  background-color: $C_GOLD;
  display: inline;
  cursor: pointer;
}
