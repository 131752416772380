@import "../../../../../../theme/theme";

.process-picks-2-page {
  .content-header {
    .actions-sidebar {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header {
        user-select: none;
        padding: 10px;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .content {
        flex: 1;
        display: flex;

        .title {
          display: flex;
          flex-direction: row;
          align-items: center;

          .action-number {
            width: 25px;
            font-size: 14px;
            margin-right: 10px;
          }

          .process-type {
            color: $C_MINT;
            font-size: 12px;
          }

          .timestamp {
            color: $C_GREY_COOL_2;
            font-size: 11px;
          }
        }

        .issues {
          font-size: 12px;
          color: $C_GREY_COOL_2;

          &.errors {
            color: $C_RED;
          }
        }

        .undo:hover {
          cursor: pointer;
        }
      }
    }
  }

}
