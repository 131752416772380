@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.savedsearch-dashboard .message-board-container {
  background-color: #FFFFFF;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #EFEFEF;
  max-height: 150px;
  overflow-y: auto;
}
.savedsearch-dashboard .message-board-container .header {
  font-weight: bold;
  margin-bottom: 10px;
}
.savedsearch-dashboard .actions-container {
  display: flex;
  height: 50px;
}
.savedsearch-dashboard .actions-container .filter-types {
  display: flex;
  flex-direction: row;
  flex: 10;
  gap: 20px;
  justify-content: center;
  margin-bottom: 10px;
}
.savedsearch-dashboard .actions-container .filter-types button {
  border-radius: 99px !important;
}
.savedsearch-dashboard .actions-container .filter-types button:hover {
  background-color: var(--brand-color-lighter) !important;
  border: 2px solid var(--brand-color-lighter) !important;
  color: #FFFFFF !important;
  opacity: 0.5;
}
.savedsearch-dashboard .actions-container .configuration-container {
  flex: 1;
  align-self: end;
  font-size: 11px;
  color: #6D757E;
  text-align: right;
}
.savedsearch-dashboard .group-container .grouping {
  margin-bottom: 25px;
}
.savedsearch-dashboard .group-container .grouping .header {
  border-bottom: 1px solid #CCCCCC;
  font-size: 16px;
  margin-bottom: 10px;
}
.savedsearch-dashboard .group-container .grouping .tile-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.savedsearch-dashboard .group-container .grouping .tile-container .tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
  max-width: 150px;
}
.savedsearch-dashboard .group-container .grouping .tile-container .tile .title {
  text-align: center;
  margin-bottom: 10px;
  color: #65707A;
  font-size: 13px;
}
.savedsearch-dashboard .group-container .grouping .tile-container .tile .description {
  align-self: flex-end;
  width: 15px !important;
  height: 15px !important;
  margin: -5px !important;
  padding: 0px !important;
}
.savedsearch-dashboard .group-container .grouping .tile-container .tile .content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  color: white;
  font-size: 35px;
}
.savedsearch-dashboard .group-container .grouping .tile-container .tile a .content:hover {
  outline: 1px solid var(--brand-color);
}
.savedsearch-dashboard .move-grid .move-grid-body {
  border: none;
}