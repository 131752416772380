@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.item-chooser .item-chooser-search-cell.highlighted {
  background-color: #CCCCCC;
}
.item-chooser .item-chooser-search-cell.flash {
  background-color: #78ACEA;
  color: #444 !important;
}
.item-chooser .item-chooser-search-cell.fade {
  transition: background-color 0.45s linear;
}
.item-chooser .item-chooser-search-cell .inventory-processing-info .label {
  color: #9F0620 !important;
}
.item-chooser .item-chooser-search-cell .inventory-processing-info.green-label .label {
  color: #3e7800 !important;
}
.item-chooser .item-chooser-search-cell .item-number .explore {
  margin-left: 5px;
  font-size: 12px;
}
.item-chooser .item-chooser-search-cell .item-desc {
  color: #999999;
  font-size: 14px;
  font-weight: 300;
}
.item-chooser .item-chooser-search-cell .item-desc.qty-in-standard {
  font-weight: 400;
}
.item-chooser .item-chooser-search-cell .item-desc p {
  margin: 0;
}
.item-chooser .diff-items-container {
  height: 100%;
  overflow-y: auto;
  padding: 20px 20px 80px 20px;
}
.item-chooser .diff-items-container .no-changes {
  margin-top: 20px;
  color: #BBC2CA;
  font-size: 22px;
  text-align: center;
}
.item-chooser .diff-items-container .grid-title {
  font-weight: 500;
  font-size: 22px;
  margin-top: 10px;
}