@import "../../../../../../theme/theme";

.move-within-location-build-shell {
  .last-scanned {
    font-weight: 300;
    font-size: 16px;
    color: $C_GREY_COOL_1A;
    margin-bottom: 1rem;
  }

  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ccc;
      opacity: .8;
    }
  }
}
