@import "../theme/theme";

.inspection-due-level-pill {
  padding: 1px 3px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  font-weight: 500;
  color: $C_WHITE;
  margin-right: auto;
  background-color: $C_GREY_COOL_6;

  &.large {
    padding: 4px 8px;
  }

  &.red {
    background-color: $C_DEV_RED;
    border: 1px solid $C_DEV_RED;
  }

  &.orange {
    background-color: $C_ORANGE_BURNT;
    border: 1px solid $C_ORANGE_BURNT;
  }

  &.yellow {
    background-color: $C_YELLOW;
    border: 1px solid $C_YELLOW_ORANGE;
    color: $C_ORANGE_BURNT;
  }
}
