@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.detail-panel, .summaryDetail-panel {
  background-color: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 23px;
  overflow: hidden;
  padding: 7px 15px 15px 20px;
}
.detail-panel.dashboard, .summaryDetail-panel.dashboard {
  padding: 0;
}
.detail-panel .header, .summaryDetail-panel .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
}
.detail-panel .header.dashboard, .summaryDetail-panel .header.dashboard {
  padding: 10px 23px 0 23px;
}
.detail-panel .header .title, .summaryDetail-panel .header .title {
  font-size: 16px;
  flex: 1;
}
.detail-panel .body .row:first-child, .summaryDetail-panel .body .row:first-child {
  border-top: none;
}
.detail-panel .closed-panel .closed-row, .summaryDetail-panel .closed-panel .closed-row {
  display: none;
}

.detail-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #e3e3e3;
}
.detail-panel-header .panel-title {
  color: #4c5660;
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  padding-top: 4px;
}

.detail-panel-body {
  padding: 5px 10px 10px;
  font-size: 13px;
  color: #89929b;
}
.detail-panel-body .row {
  margin: 0;
  border-top: 1px solid whitesmoke;
}
.detail-panel-body .row:first-child {
  border-top: none;
}