@import "../theme/theme";

.detail-panel, .summaryDetail-panel {
  background-color: $C_WHITE;
  border: 1px solid $C_BORDER_PRIMARY;
  border-radius: $S_BORDER_RADIUS;
  font-size: 12px;
  margin-bottom: $S_GUTTER;
  overflow: hidden;
  padding: 7px 15px 15px 20px;

  &.dashboard {
    padding: 0;
  }



  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;

    &.dashboard {
      padding: 10px $S_GUTTER 0 $S_GUTTER;
    }

    .title {
      font-size: 16px;
      flex: 1;
    }
  }

  .body {
    .row {

      &:first-child {
        border-top: none;
      }
    }
  }

  .closed-panel {
    .closed-row {
      display: none;
    }
  }
}

.detail-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #fff;
  padding: 0 10px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #e3e3e3;

  .panel-title {
    color: #4c5660;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    padding-top: 4px;
  }
}

.detail-panel-body {
  padding: 5px 10px 10px;
  font-size: 13px;
  color: rgb(137, 146, 155);

  .row {
    margin: 0;
    border-top: 1px solid rgb(245, 245, 245);

    &:first-child {
      border-top: none;
    }
  }
}
