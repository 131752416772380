@import "../../../../../../../theme/theme";

.transfer-pack-component {
  margin-bottom: 10px;

  .pack-title {
    font-size: 18px;

    .pack-tote-select {
      margin-left: 5px;
      font-size: 12px;
    }
  }
}
