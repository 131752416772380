@import "../../../../../theme/theme";

.conversation-cell {
  user-select: none;
  cursor: pointer;

  .purpose {
    color: $C_TEXT;
    font-size: 15px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message-preview {
    color: $C_TEXT;
    font-size: 12px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  .timestamp-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .timestamp {
      text-align: right;
      font-size: 13px;
      font-weight: $F_WEIGHT_LIGHT;
    }
  }

  .pill-container {
    text-align: right;
    margin-right: 24px;
    margin-top: -5px;
    margin-bottom: 5px;
  }
}
