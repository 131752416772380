@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.audit-product-group-container {
  margin-bottom: 12px;
}
.audit-product-group-container .product-group-display {
  background-color: #FFFFFF;
  padding: 12px;
  border: 1px solid #D6D6D6;
  cursor: pointer;
  box-shadow: 0px 15px 7px -15px #D6D6D6;
}
.audit-product-group-container .product-group-display.has-audit-items-to-display {
  border-bottom: none;
}
.audit-product-group-container .product-group-display .product-group-name {
  font-size: 14px;
  font-weight: bold;
  color: #1B82CC;
}
.audit-product-group-container .product-group-display .product-group-counted {
  font-size: 13px;
}
.audit-product-group-container .product-group-display .product-group-diff {
  font-size: 13px;
  color: red;
}