@import "../theme/theme";

$SIDEBAR_BORDER: 1px solid $C_BORDER_PRIMARY;
$SIDEBAR_GUTTER: calc($S_GUTTER/2);

.filter-sidebar {
  width: $S_FILTER_WIDTH;
  transition: margin 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  flex: 0 0 $S_FILTER_WIDTH;
  display: flex;
  flex-direction: column;

  background-color: $C_WHITE;
  border: $SIDEBAR_BORDER;
  scrollbar-color: $C_GREY_COOL_10 $C_WHITE_2;
  border-radius: 0 $S_BORDER_RADIUS 0 0;

  font-weight: 400;

  &.no-border-radius {
    border-radius: 0;
  }
  &.all-border-radius {
    border-radius: $S_BORDER_RADIUS;
  }

  .header {
    border-bottom: $SIDEBAR_BORDER;
    font-size: 14px;
    height: 32px;
    line-height: 38px;
    padding: 0 14px;
    flex-wrap: wrap;
    align-content: center;

    .clear-anchor {
      font-size: 11px;
      margin-left: 5px;
    }
  }

  .content {
    flex: 1;
    padding: $SIDEBAR_GUTTER $SIDEBAR_GUTTER 64px $SIDEBAR_GUTTER;
    overflow-y: auto;
    overflow-x: hidden;

    .divider {
      margin: $SIDEBAR_GUTTER 0;
      background-color: darken($C_GREY_WARM_2, 5%);
      height: 1px;
    }

    .filter-field {
      margin: 10px 0px;
      padding: 5px 10px;
      border: 1px solid $C_GREY_WARM_2;
      border-radius: 5px;
      background-color: $C_GREY_WARM_8;

      &:first-child {
        margin-top: 0;
      }

      .filter-lbl {
        font-size: 12px;
        //margin-bottom: 4px;
        span:not(:last-child) {
          padding-right: 5px;
        }
      }
    }

    @-moz-document url-prefix() {
      &:after {
        content: "";
        display: block;
        height: 64px;
        opacity: 0;
      }
    }
  }
}
