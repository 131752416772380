@import "../theme/theme";

.image-viewer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999998;

  padding: 25px;
  color: $C_GREY_COOL_4;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;

  .top-row {
    display: flex;
    width: 100%;
  }

  .gallery {
    align-items: center;
    justify-content: space-between;
    background-image:linear-gradient($C_GREY_WARM_10, $C_GREY_COOL_1A);

     .img-container {
      overflow: hidden;

      .full-preview-element {

        .loading {
          display: flex;
          justify-content: center;
        }

         #image-preview {
          width: 100%;
          height: 100%;
          transition: transform .3s;
          transform-origin: 0 0;

          display: flex;
          justify-content: center;

          > img {
            width: auto;
            height: auto;
            max-width: 100%;
          }
        }
      }
    }

     .bottom-nav {
      background-color: rgba(29, 29, 29, .65);
      display: grid;
      grid-template-columns: 1fr 160px 1fr;
      position: relative;
      bottom: 48px;
      margin-bottom: -48px;

      .image-title-container{
        padding: 13px 0 0 13px;
        font-weight: 100;
        color: $C_GREY_WARM_2;

      }

      .nav-button-container {
        display: flex;
        justify-content: center;
        grid-column: 2;

        .button-container {
          width: 50px;
        }
      }

      .fullscreen-button-container {
        display: flex;
        justify-content: flex-end;
        grid-column: 3;
      }

      .image-number-count {
        align-content: center;
        color: $C_GREY_WARM_2;
        padding-bottom: 4px;
        font-weight: 100;
      }
    }
  }
}
