@import "../theme/theme";

.view-reserved-stock-pill {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  background-color: $C_PURPLE_LIGHT;
  display: inline;
  cursor: pointer;
}
