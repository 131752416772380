@import "../../../theme/theme";

.choose-to-case-page {
  display: flex;
  flex: 1;
  justify-content: center;

  .choose-to-case {
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 400px;

    .case-selector-container {
      flex: 1;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .case-selector {
        flex: 1;
        background-color: $C_WHITE;
        border: 1px solid $C_GREY_WARM_2;
        box-shadow: 0 2px 2px 0 rgba(160, 167, 175, 0.14);
        padding: 60px;
        color: $C_GREY_COOL_5;
      }

      div {
        flex: 1;
      }
    }

    .info {
      margin: 20px auto 0;
      flex: 2;
      font-size: 16px;
      color: $C_GREY_COOL_5;
    }
  }
}
