@import "../../../../../../../theme/theme";

.add-org-unit {
  $header-footer-height: 54px;
  $filter-border: 1px solid $C_GREY_COOL_3;
  $filter-gutter: calc($S_GUTTER/2);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $Z_FULL_SCREEN;
  background-color: $C_GREY_WARM_1;

  > .header {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: $Z_APP_BAR;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $S_GUTTER;
    height: $header-footer-height;

    background-color: $C_MINT;
    color: $C_WHITE;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px, rgba(0, 0, 0, 0.117647) 0 1px 4px;
  }

  > .content {
    position: absolute;
    top: $header-footer-height;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 10px 20px 0;

    display: flex;
    flex-direction: column;
    overflow: auto;

    > .top {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .add-lbl {
        margin-bottom: 4px;
        font-weight: bold;
        margin-top: 10px
      }

      .add-inventory-permissions {
        margin-top: auto;
        align-self: center;
      }
    }

    > .bottom {
      border-top: $filter-border;
      margin-bottom: 74px;
      margin-top: 15px;

      .tab-box {
        margin: 0;
      }
    }
  }

  > .footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: $header-footer-height;
    z-index: $Z_APP_BAR;
    background-color: $C_MINT;
    opacity: 0.9;
    border-top: 1px solid $C_GREY_COOL_3;
    padding: 0 $S_GUTTER;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
