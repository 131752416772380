@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.picks-component {
  height: 100%;
}
.picks-component .picks-grid-row .home-location {
  font-size: 11px;
  color: #9c9c9c;
  line-height: 12px;
  margin-bottom: 5px;
}
.picks-component .picks-grid-row .resp-party {
  font-size: 11px;
  color: #026847;
  line-height: 12px;
  margin-bottom: 5px;
}
.picks-component .picks-grid-row .inventory-type {
  font-size: 11px;
  color: #3DBFC6;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
}
.picks-component .picks-grid-row .case-event,
.picks-component .picks-grid-row .demand-loan,
.picks-component .picks-grid-row .consignment-loan {
  font-size: 11px;
  color: #9f0620;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
}
.picks-component .picks-grid-row .ops-data {
  color: #0020d4;
  cursor: pointer;
}
.picks-component .picks-grid-row .secondary-data {
  border-top: 1px solid #EFEFEF;
  margin-top: 5px;
  padding-top: 2px;
  padding-bottom: 5px;
}
.picks-component .picks-grid-row .secondary-data .label {
  font-size: 10px;
  color: #616161;
  padding-right: 2px;
  font-weight: 500;
}
.picks-component .picks-grid-row .secondary-data .value {
  font-size: 10px;
  color: #616161;
}