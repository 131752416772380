@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.source-to-case-review-shell .filter-sidebar {
  z-index: 0;
}
.source-to-case-review-shell .search-header {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 10px;
}
.source-to-case-review-shell .search-header .text-search-container {
  flex-basis: 275px;
}
.source-to-case-review-shell .search-header .add-btns {
  margin-right: 10px;
}
.source-to-case-review-shell .search-header .scan-count {
  margin-left: auto;
  font-weight: 300;
  font-size: 16px;
  color: #616161;
}
.source-to-case-review-shell .msg-box {
  text-align: center;
  padding: 10px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
}
.source-to-case-review-shell .footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.source-to-case-review-shell .footer-container .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  opacity: 0.8;
}
.source-to-case-review-shell .item-chooser-cell .secondary-data {
  border-top: 1px solid #EFEFEF;
  margin-top: 5px;
  padding-top: 2px;
  padding-bottom: 5px;
}
.source-to-case-review-shell .item-chooser-cell .secondary-data .label,
.source-to-case-review-shell .item-chooser-cell .inventory-processing-info .label {
  font-size: 10px;
  color: #616161;
  padding-right: 2px;
  font-weight: 500;
}
.source-to-case-review-shell .item-chooser-cell .secondary-data .value,
.source-to-case-review-shell .item-chooser-cell .inventory-processing-info .value {
  font-size: 10px;
  color: #616161;
}
.source-to-case-review-shell .item-chooser-cell .qty-col {
  display: flex;
  flex-direction: row;
  align-items: center;
}