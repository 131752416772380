@import "../../../../theme/theme";

.tableau-workbook {
  &-list {
    margin-bottom: 24px;

    &-header {
      margin-left: 8px;
      font-size: 16px;
    }

    &-items {
      display: flex;
    }
  }

  &-card {
    display: inline-block;
    background-color: $C_WHITE;
    border: 1px solid $C_GREY_WARM_3;
    border-radius: 2px;
    margin: 8px 8px $S_GUTTER;
    cursor: pointer;

    &__preview-image {
      background-color: $C_GREY_WARM_3; // Just in case the workbook doesn't have a preview image
      width: 300px;
      height: 300px;

      img {
        width: 300px;
        height: 300px;
      }
    }

    &__name {
      border-top: 1px solid $C_GREY_WARM_3;
      padding: 10px;
      font-size: 14px;
    }
  }
}
