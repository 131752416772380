@import "../../../../../../../../theme/theme";

.usage-order-component {
  margin-top: 10px;

  .order-name {
    font-size: 22px;
    margin-right: 10px;
  }

  .order-field-container {
    flex: 1;
    min-width: 350px;
    margin: 0 20px;

    &.order-field-container--small {
      flex: initial;
      min-width: 250px;
    }

    .order-field-label {
      font-size: 10px;
      margin-bottom: -10px;
    }
  }
}
