@import "../theme/theme";

.pending-transfer-status-pill {
  border-radius: $S_BORDER_RADIUS;
  padding: 4px 8px;
  color: $C_WHITE;
  white-space: nowrap;

  &.ready {
    background-color: $C_GREEN;
  }

  &.pending-erp {
    background-color: $C_ORANGE;
  }

  &.erp-error, &.transfer-failed {
    background-color: $C_DEV_RED;
  }

  &.complete {
    background-color: $C_GREY_COOL_5;
  }

  &.option-disabled {
    background-color: $C_GREY_COOL_3;
  }

  &.small {
    padding: 2px 4px 2px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 3px;
  }
}
