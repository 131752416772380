.item-select-desc {
  display: none;
}

.select2-results__option {
  .item-select-desc {
    display: block;
    color: rgb(137, 146, 155);
    font-size: 10px;
  }
}