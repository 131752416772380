@import "../theme/theme";

.item-hold-qty-pill {
  display: block;
  padding: 2px 4px;
  border: 1px solid $C_BLUE;
  border-radius: $S_BORDER_RADIUS;
  color: $C_BLUE;
  font-size: 10px;
  margin-left: 5px;
  white-space: nowrap;
  text-align: center;

  &:hover {
    border-color: $C_BLUE_DARKER;
    color: $C_BLUE_DARKER;
  }
}
