.schedule-search-page {
  overflow-y: hidden;
}
.schedule-search-page .filter-sidebar {
  height: 91.5vh;
}
.schedule-search-page .fc-body {
  background-color: #fff !important;
}
.schedule-search-page .fc-body a {
  color: #fff !important;
}
.schedule-search-page .results-container-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.schedule-search-page .schedule-content-container {
  overflow-y: hidden;
  padding-top: 0;
}

.schedule-tool-bar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}