@import "../../../../../theme/theme";
.reports-list {

  .report-panel {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 15px;

    &:hover {
      background-color: #e4e4e4;
    }

    .icon {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.orange {
        background-color: #FFAC00;
      }

      &.green {
        background-color: #69C00A;
      }

      &.brand {
        background-color: var(--brand-color-lighter);
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
    }

    .desc {
      font-size: 12px;
    }
  }
}
