@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.rfid-el .queued-batch-container {
  background-color: #edf1f5;
  border-bottom: none !important;
  border-radius: 4px;
  padding: 10px;
}
.rfid-el .tag-read-batch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCCCCC;
}
.rfid-el .tag-read-batch-container .batch-container,
.rfid-el .tag-read-batch-container .kit-container,
.rfid-el .tag-read-batch-container .waiting-container,
.rfid-el .tag-read-batch-container .start-container {
  display: inline-block;
  padding: 10px 10px 10px 0;
  max-width: 200px;
}
.rfid-el .tag-read-batch-container .batch-container .title,
.rfid-el .tag-read-batch-container .kit-container .title,
.rfid-el .tag-read-batch-container .waiting-container .title,
.rfid-el .tag-read-batch-container .start-container .title {
  width: 100%;
  margin-left: 5px;
  margin-bottom: 10px;
  color: #CCCCCC;
}
.rfid-el .tag-read-batch-container .batch-container.canManuallyStopReader .listening,
.rfid-el .tag-read-batch-container .kit-container.canManuallyStopReader .listening,
.rfid-el .tag-read-batch-container .waiting-container.canManuallyStopReader .listening,
.rfid-el .tag-read-batch-container .start-container.canManuallyStopReader .listening {
  display: block;
  opacity: 1;
  transition: opacity 0.6s linear;
}
.rfid-el .tag-read-batch-container .batch-container.canManuallyStopReader .stopHint,
.rfid-el .tag-read-batch-container .kit-container.canManuallyStopReader .stopHint,
.rfid-el .tag-read-batch-container .waiting-container.canManuallyStopReader .stopHint,
.rfid-el .tag-read-batch-container .start-container.canManuallyStopReader .stopHint {
  font-size: 12px;
  color: #FFFFFF;
  display: none;
  opacity: 0;
  transition: opacity 0.6s linear;
}
.rfid-el .tag-read-batch-container .batch-container.canManuallyStopReader > :hover,
.rfid-el .tag-read-batch-container .kit-container.canManuallyStopReader > :hover,
.rfid-el .tag-read-batch-container .waiting-container.canManuallyStopReader > :hover,
.rfid-el .tag-read-batch-container .start-container.canManuallyStopReader > :hover {
  background-color: #CC453F;
  transition: background-color 0.6s linear;
  cursor: pointer;
}
.rfid-el .tag-read-batch-container .batch-container.canManuallyStopReader > :hover .stopHint,
.rfid-el .tag-read-batch-container .kit-container.canManuallyStopReader > :hover .stopHint,
.rfid-el .tag-read-batch-container .waiting-container.canManuallyStopReader > :hover .stopHint,
.rfid-el .tag-read-batch-container .start-container.canManuallyStopReader > :hover .stopHint {
  display: block;
  opacity: 1;
  transition: opacity 0.6s linear;
}
.rfid-el .tag-read-batch-container .batch-container.canManuallyStopReader > :hover .listening,
.rfid-el .tag-read-batch-container .kit-container.canManuallyStopReader > :hover .listening,
.rfid-el .tag-read-batch-container .waiting-container.canManuallyStopReader > :hover .listening,
.rfid-el .tag-read-batch-container .start-container.canManuallyStopReader > :hover .listening {
  display: none;
  opacity: 0;
  transition: opacity 0.6s linear;
}
.rfid-el .tag-read-batch-container .batch-container.errors .info,
.rfid-el .tag-read-batch-container .kit-container.errors .info,
.rfid-el .tag-read-batch-container .waiting-container.errors .info,
.rfid-el .tag-read-batch-container .start-container.errors .info {
  border-color: #9F0620;
}
.rfid-el .tag-read-batch-container .batch-container.errors .info .details .errors,
.rfid-el .tag-read-batch-container .kit-container.errors .info .details .errors,
.rfid-el .tag-read-batch-container .waiting-container.errors .info .details .errors,
.rfid-el .tag-read-batch-container .start-container.errors .info .details .errors {
  color: #9F0620;
}
.rfid-el .tag-read-batch-container .batch-container > .info,
.rfid-el .tag-read-batch-container .kit-container > .info,
.rfid-el .tag-read-batch-container .waiting-container > .info,
.rfid-el .tag-read-batch-container .start-container > .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  padding: 10px;
  min-height: 90px;
  min-width: 140px;
}
.rfid-el .tag-read-batch-container .batch-container > .info > .header,
.rfid-el .tag-read-batch-container .kit-container > .info > .header,
.rfid-el .tag-read-batch-container .waiting-container > .info > .header,
.rfid-el .tag-read-batch-container .start-container > .info > .header {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #979797;
  font-weight: 400;
}
.rfid-el .tag-read-batch-container .batch-container > .info > .header .header-row,
.rfid-el .tag-read-batch-container .kit-container > .info > .header .header-row,
.rfid-el .tag-read-batch-container .waiting-container > .info > .header .header-row,
.rfid-el .tag-read-batch-container .start-container > .info > .header .header-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.rfid-el .tag-read-batch-container .batch-container > .info > .header span,
.rfid-el .tag-read-batch-container .kit-container > .info > .header span,
.rfid-el .tag-read-batch-container .waiting-container > .info > .header span,
.rfid-el .tag-read-batch-container .start-container > .info > .header span {
  cursor: pointer;
}
.rfid-el .tag-read-batch-container .batch-container > .info > .details,
.rfid-el .tag-read-batch-container .kit-container > .info > .details,
.rfid-el .tag-read-batch-container .waiting-container > .info > .details,
.rfid-el .tag-read-batch-container .start-container > .info > .details {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.rfid-el .tag-read-batch-container .batch-container > .details {
  padding-top: 10px;
}
.rfid-el .tag-read-batch-container .batch-container:hover {
  cursor: pointer;
}
.rfid-el .tag-read-batch-container .batch-container:hover > .info {
  border-color: #979797;
  transition: border 0.3s linear;
}
.rfid-el .tag-read-batch-container .batch-container:hover.errors > .info {
  border-color: #CC453F;
}
.rfid-el .tag-read-batch-container .start-container .info {
  background-color: #22bda9;
  opacity: 1;
  cursor: default;
}
.rfid-el .tag-read-batch-container .start-container .info .details {
  color: #FFFFFF;
}
.rfid-el .tag-read-batch-container .start-container.canStart .info:hover {
  opacity: 0.8;
  cursor: pointer;
}
.rfid-el .tag-read-batch-container .kit-group {
  border: 1px dashed #CCCCCC;
  margin: 5px;
}
.rfid-el .tag-read-batch-container .kit-group > .info {
  padding: 10px;
}
.rfid-el .tag-read-batch-container .kit-group > .info > .header .item-type-pill.kit {
  background-color: #D6D6D6;
}
.rfid-el .tag-read-batch-container .kit-group > .info > .header .header-row,
.rfid-el .tag-read-batch-container .kit-group > .info > .header .inspection-failed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rfid-el .tag-read-batch-container .kit-group > .info > .header .view-inspection {
  cursor: pointer;
}
.rfid-el .tag-read-batch-container .kit-group.failed-inspection {
  border-color: #9F0620;
}
.rfid-el .tag-read-batch-container .kit-group.inspected {
  border-style: solid;
}
.rfid-el .tag-read-batch-container .kit-group.inspected > .info > .header .item-type-pill.kit {
  background-color: #FF9889;
}
.rfid-el .tag-read-batch-container .kit-group.inspected > .info > .header .inspection-failed {
  font-size: 12px;
  color: #9F0620;
  cursor: pointer;
  margin-right: 10px;
}
.rfid-el .tag-read-batch-container .kit-group .containers {
  display: flex;
  flex-direction: row;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container {
  display: flex;
  padding-bottom: 0;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container:first-child {
  padding-left: 0;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container:last-child {
  padding-right: 0;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container .info {
  min-height: unset;
  background-color: transparent;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container .info .item-type-pill {
  background-color: transparent;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container.picked .info {
  background-color: #FFFFFF;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container.picked:hover {
  cursor: pointer;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container.picked:hover > .info {
  border-color: #979797;
  transition: border 0.3s linear;
}
.rfid-el .tag-read-batch-container .kit-group .containers .kit-container.picked:hover.errors > .info {
  border-color: #CC453F;
}
.rfid-el .tag-read-batch-container .tote-batch-container {
  border: 1px dashed #CCCCCC;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 5px;
}
.rfid-el .tag-read-batch-container .tote-batch-container .tote-batch-contents {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.rfid-el .tag-read-batch-container .tote-batch-container .tote-batch-contents .batch-container {
  padding-right: 10px;
}

.barcode-footer {
  height: 48px;
}

.rfid-footer {
  margin-bottom: 30px;
  font-size: 12px;
  padding-top: 10px;
  border-top: 1px solid #CCCCCC;
}

.rfid-input-type {
  display: flex;
  flex: 1;
  align-items: center;
}

.tag-info {
  font-size: 12px;
  color: #979797;
}
.tag-info .tag-number {
  font-weight: 400;
}
.tag-info .tag-batch {
  font-weight: 300;
}