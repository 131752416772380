@import "../../../../../../theme/theme";

.react-datepicker__close-icon {
  &::after {
    background-color: transparent;
    color: $C_GREY_COOL_2;
    padding: 0;
    right: 3px;
    font-size: 16px;
  }
}
