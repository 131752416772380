@import "../../../../../../theme/theme";

.dashboard-grid-cell {
  height: 45px;
  cursor: pointer;
  padding: 2px 0;

  &.no-click {
    cursor: default;
  }
}
