@import "../theme/theme";

.lotserial-pill-container {
  display: flex;
  flex-direction: column;

  .lotserial-pill {
    padding: 2px 4px 2px;
    border-radius: 4px;
    font-size: 11px;
    color: $C_WHITE;
    margin-right: auto;
    word-break: keep-all;

    &.unknown {
      padding: 0 5px;
      background-color: $C_WHITE;
      border: 1px solid $C_GREY_COOL_4;
      color: $C_GREY_COOL_4;
    }

    &.lotScrubbedNotice {
      padding: 0 5px;
      background-color: $C_WHITE;
      border: 1px solid $C_BLUE_GREY;
      color: $C_BLUE_GREY;
    }

    &.lot {
      background-color: $C_ORANGE;
    }

    &.serial {
      background-color: $C_BLUE_DEEP;
    }

    &.spacing-top {
      margin-top: 5px;
    }

    &.spacing-bottom {
      margin-bottom: 5px;
    }
  }
}
