@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.move-grid {
  scrollbar-color: #D0D0D0 #FDFDFD;
  font-weight: 400;
}
.move-grid .move-grid-body > div:nth-of-type(even) {
  background-color: #f5f7f8;
}
.move-grid.full-height, .move-grid.fixed-height {
  display: flex;
  flex-direction: column;
}
.move-grid.full-height .move-grid-body, .move-grid.fixed-height .move-grid-body {
  overflow-y: auto;
}
.move-grid.full-height .move-grid-footer, .move-grid.fixed-height .move-grid-footer {
  min-height: 32px;
  z-index: 1;
}
.move-grid.full-height .move-grid-body {
  flex: 1;
}
.move-grid.fixed-height .move-grid-body {
  max-height: 400px;
}
@media screen and (min-height: 1024px) {
  .move-grid.fixed-height .move-grid-body {
    max-height: 600px;
  }
}
.move-grid .move-grid-header {
  border: 1px solid #D6D6D6;
  border-radius: 5px 5px 0 0;
  background-color: #f7f8fb;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 32px;
  position: relative;
}
.move-grid .move-grid-header.no-border-radius {
  border-radius: 0;
}
.move-grid .move-grid-header .move-grid-header-cell {
  user-select: none;
  cursor: default;
  font-weight: 400;
  font-size: 12px;
  color: #5f5f5f;
  text-transform: uppercase;
}
.move-grid .move-grid-header .move-grid-header-cell.sortable {
  cursor: pointer;
}
.move-grid .move-grid-header .move-grid-header-cell .carrot {
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
  transform: scale(1, 0.5);
}
.move-grid .move-grid-body {
  border: 1px solid #D6D6D6;
  background-color: #fff;
  margin-top: -1px;
  z-index: 1;
  overflow: hidden;
}
.move-grid .move-grid-body.no-footer {
  border-radius: 0 0 5px 5px;
}
.move-grid .move-grid-body.no-border-radius {
  border-radius: 0;
}
.move-grid .move-grid-body .move-grid-cell {
  border-bottom: 1px solid #f5f7f8;
}
.move-grid .move-grid-body .move-grid-cell.child-row {
  border-left: 10px solid #AFAEAE;
}
.move-grid .move-grid-body .move-grid-cell.child-row:not(.selection-row) > div > div:first-child {
  margin-right: 0 !important;
}
.move-grid .move-grid-body .move-grid-cell.selected {
  background-color: #d7eef7;
}
.move-grid .move-grid-body .move-grid-cell.selected:hover {
  background-color: #bbe2f2;
}
.move-grid .move-grid-body .move-grid-cell.highlighted {
  background-color: #ECF6FF;
}
.move-grid .move-grid-body .move-grid-cell.highlighted:hover {
  background-color: #d6e5f6;
}
.move-grid .move-grid-body .move-grid-cell.highlighted.grey {
  background-color: #f5f7f8;
}
.move-grid .move-grid-body .move-grid-cell.highlighted.grey:hover {
  background-color: #EFEFEF;
}
.move-grid .move-grid-body .move-grid-cell.highlighted.red {
  background-color: #FFEDEB;
}
.move-grid .move-grid-body .move-grid-cell.highlighted.red:hover {
  background-color: #FFD4CE;
}
.move-grid .move-grid-body .move-grid-cell.highlighted.green {
  background-color: #f0f7e7;
}
.move-grid .move-grid-body .move-grid-cell.highlighted.green:hover {
  background-color: #e4f5cf;
}
.move-grid .move-grid-body .move-grid-cell:hover {
  background-color: var(--brand-color-lightest);
}
.move-grid .move-grid-body .move-grid-cell:last-child {
  border-bottom: none;
}
.move-grid .move-grid-body .move-grid-cell > .checkbox-cell {
  flex-shrink: 0;
}
.move-grid .move-grid-body .move-grid-cell,
.move-grid .move-grid-body .move-grid-cell > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.move-grid .move-grid-body .move-grid-loading-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}
.move-grid .move-grid-footer {
  border-left: 1px solid #D6D6D6;
  border-right: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
  border-radius: 0 0 5px 5px;
  background-color: #f7f8fb;
  padding: 5px 20px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.move-grid .move-grid-footer > * {
  margin-left: 10px;
  cursor: pointer;
}
.move-grid .move-grid-footer.disabled > * {
  cursor: not-allowed;
}

.receiving-page .move-grid-header {
  position: sticky;
  z-index: 10;
  top: -20px;
}

.detail-page-component .page-content:not(.tab-reset-overflow) {
  overflow: initial;
}
.detail-page-component .move-grid-header {
  position: sticky;
  z-index: 10;
  top: -20px;
}

.full-screen-modal .full-screen-modal-content .page-content {
  overflow: auto;
}
.full-screen-modal .full-screen-modal-content .move-grid-header {
  position: sticky;
  z-index: 10;
  top: -20px;
}
.full-screen-modal .full-screen-modal-content .audit-processing-page .page-content {
  overflow: initial;
}
.full-screen-modal .full-screen-modal-content .audit-processing-page .move-grid-header {
  top: 0;
}