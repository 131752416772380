@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.case-event-status-pill {
  border-radius: 5px;
  padding: 4px 8px;
  color: #FFFFFF;
  white-space: nowrap;
}
.case-event-status-pill.planning {
  background-color: #FFAC00;
}
.case-event-status-pill.confirmed {
  background-color: #4A90E2;
}
.case-event-status-pill.preparing {
  background-color: #0FC6B5;
}
.case-event-status-pill.awaiting-delivery {
  background-color: #DAA479;
}
.case-event-status-pill.ready {
  background-color: #699A2E;
}
.case-event-status-pill.in-progress {
  background-color: #3DBFC6;
}
.case-event-status-pill.post-op {
  background-color: #AF22C7;
}
.case-event-status-pill.complete {
  background-color: #868E97;
}
.case-event-status-pill.cancelled {
  background-color: #e26464;
}
.case-event-status-pill.gathering-usage {
  background-color: #D790E3;
}
.case-event-status-pill.closed {
  background-color: #65707A;
}
.case-event-status-pill.option-disabled {
  background-color: #D6D6D6;
}
.case-event-status-pill.small {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  margin-left: 3px;
}