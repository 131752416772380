@import "../theme/theme";

.move-step-control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.disabled,
  &.disabled .divisor,
  &.disabled .total {
    color: $C_GREY_COOL_2;
  }

  .quantity {
    font-size: 22px;
  }

  .divisor {
    font-size: 10px;
    margin-left: 2px;
    color: $C_GREY_COOL_1A;
  }

  .total {
    font-size: 10px;
    color: $C_GREY_COOL_1A;
  }
}
