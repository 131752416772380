@import "../../../../../../theme/theme";

.transfer-review-shell {

  .last-scanned {
    font-weight: 300;
    font-size: 16px;
    color: $C_GREY_COOL_1A;
    margin-bottom: 1rem;
  }

  .stock-search-into-container {
    font-size: 10px;
  }

  .overview-display-boxes {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    column-gap: 1rem;

    .display-box {
      position: relative;
      flex: 0 0 25rem;
      flex-basis: 30rem;
      border-radius: .3rem;
      padding: 0.5rem 2rem;

      .export-unreconciled-icon {
        position: absolute !important;
        top: 0;
        right: 0;
      }

      .heading {
        font-size: 3rem;
      }

      .type {
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: bold;
      }

      .links {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        a {
          color: $C_BLUE_GREY !important;
        }
      }
    }
  }

  .segmented-control-header {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0 !important;

    .segmented-control-left {
      white-space: nowrap;
      min-width: 360px;

      a {
        margin-left: 1rem;
        font-size: 1rem;
      }
    }

    .segmented-control {
      width: 100%;
    }

    .segmented-control-right {
      min-width: 360px;
      flex-shrink: 0;
      text-align: right;
    }
  }

  .unreconciled-info {
    margin: 1rem 0 2rem 0;
    color: $C_GREY_COOL_1A;
    border: 1px solid $C_GREY_COOL_1A;
    border-radius: 0.2rem;
    padding-right: 2rem;
    width: fit-content;

    a {
      margin-left: 1rem;
      font-size: 1rem;
    }
  }

  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ccc;
      opacity: .8;
    }
  }
}
