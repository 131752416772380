@import "../../../../../theme/theme";

.process-picks-2-page {
  .sub-nav-bar {
    border-bottom: solid 1px $C_BORDER_PRIMARY;
  }

  .content-header {
    .actions-btn {
      float: right;
    }
  }

  .detail-panel {
    > .body {
      padding: 10px 20px;
    }
  }

  .container-title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    color: $C_GREY_COOL_1;
  }

  .scan-section,
  .actions-section,
  .issues-section,
  .picks-section,
  .success-section {
    margin-bottom: 5px;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  .picks-section,
  .issues-section {
    margin-top: 25px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;

        .errors {
          color: $C_RED;
        }

        .results-count {
          color: $C_GREY_COOL_4;
          font-size: 14px;
          margin-left: 10px;
        }
      }

      .filter-section {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .status-filter {
          margin-right: 15px;
        }

        .search-filter {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }

  .picks-section {
    .picks-grid {
      display: block !important;

      .child-pick-row {
        overflow: hidden;
        background-color: $C_GREY_WARM_1;

        &:hover {
          background-color: #F5F5F5;
        }
      }
    }
  }

  .issues-section {
    .issue-item-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &:nth-child(n+2) {
        margin-top: 5px;
      }

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .lot-serial {
          margin: 0 10px;
        }
      }

      .tag {
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .supporting-msg {
      font-size: 12px;
      font-weight: 400;
      color: $C_GREY_COOL_2;
    }
  }
}
