@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.sub-nav-bar {
  z-index: 1100;
  min-height: 54px;
  padding: 0 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  font-size: 15px;
}
.sub-nav-bar.bottom-border {
  border-bottom: 1px solid #D6D6D6 !important;
}
.sub-nav-bar .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.sub-nav-bar.no-bottom-border {
  border-bottom: none !important;
  box-shadow: none;
}

.tab-sub-nav-bar {
  z-index: 1100;
  min-height: 54px;
  padding-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  bottom: 12px;
}
.tab-sub-nav-bar .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.tab-sub-nav-bar.no-bottom-border {
  border-bottom: none !important;
  box-shadow: none;
}