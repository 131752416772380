@import "../../../../../../../theme/theme";

.usage-chooser {
  $header-footer-height: 80px;
  $filter-border: 1px solid $C_GREY_COOL_3;
  $filter-gutter: calc($S_GUTTER/2);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $Z_FULL_SCREEN;
  background-color: $C_GREY_WARM_1;

  > .header {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: $Z_APP_BAR;
    height: $header-footer-height;
    background-color: $C_MINT;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    padding: 0 $S_GUTTER;

    .cancel-container {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .title-container {
      text-align: center;
      color: $C_WHITE;

      .title {
        font-size: 22px;
        text-shadow: #808b96 1px 1px 1px;
        padding: 2px 0 2px;
      }
    }
  }

  > .content {
    position: absolute;
    top: $header-footer-height;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .add-container {

    .search-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0 10px;

      .disposition-container {
        //margin-top: -15px;
        margin-right: 10px;
        flex-basis: 150px;

        .title-lbl {
          font-size: 10px;
        }
      }

      .text-search-container {
        margin-top: -15px;
        flex-basis: 275px;

        .title-lbl {
          font-size: 10px;
        }
      }
    }

    .item-chooser-search-cell {

      &.highlighted {
        background-color: $C_GREY_WARM_2;
      }

      &.flash {
        background-color: $C_MINT_LIGHT;
      }

      &.fade {
        transition: background-color 0.3s linear;
      }

      .item-number {

        .explore {
          margin-left: 5px;
          font-size: 12px;
        }
      }

      .item-desc {
        color: $C_GREY_COOL_2;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .review-container {

    .search-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .flex-basis-290 {
        flex-basis: 290px;
      }

      .add-btns {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .item-chooser-cell {
      &.remove {
        color: #ccc;

        a, a:hover {
          color: #ccc;
        }

        .Select-value-label {
          color: #ccc !important;
        }
      }
    }
  }

  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ccc;
      opacity: .8;
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      z-index: 1;
    }
  }
}
