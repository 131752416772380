@import "../../../../../../../theme/theme";

$label-width: 25rem;

.future-stock-grid-container {
  margin: 2rem 0 0 2rem;

  .future-stock-column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 99;
    width: 100%;
  }

  .future-stock-row {
    display: inline-flex;
    flex-direction: row;

    .empty-cell {
      flex: 1;
      min-width: $label-width;
      padding: 2rem;
      margin-right: 5px;
    }

    .date-header-cell {
      flex: 1;
      min-width: 10rem;
      border-right: 5px solid $C_WHITE;
      box-sizing: border-box;
      border-radius: 0.2rem;
      background-color: $C_GREY_COOL_2;
      margin-top: -2px;
      padding: 1rem;
      font-weight: bold;
      text-align: center;
    }

    .summary-row-label {
      flex: 2;
      min-width: $label-width;
      border: 1px solid $C_GREY_COOL_2;
      box-sizing: border-box;
      position: sticky;
      left: 0;
      z-index: 99;
      border-radius: 0.2rem;
      background-color: $C_WHITE_2;
      padding: 1rem;
      margin-right: 5px;

      a {
        cursor: pointer;
      }

      .item-block {
        float: left;
        width: 20rem;
      }

      .info-block {
        float: right;

        .stock-quantity {
          border: 1px solid $C_GREY_COOL_2;
          padding: 0.3rem;
          border-radius: 9999px;
          width: 2.5rem;
          height: 2rem;
          font-size: 1rem;
          text-align: center;
        }

        .expand-collapse-icon {
          padding-top: 0.5rem;
          text-align: center;
        }
      }
    }

    .availability-cell {
      flex: 1;
      min-width: 5rem;
      border-right: 1px solid $C_WHITE;
      border-bottom: 1px solid $C_WHITE;
      box-sizing: border-box;
      border-radius: 0.2rem;
      color: $C_WHITE;
      opacity: 0.8;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      display: flex;

      &:nth-child(2n+1) {
        border-right: 5px solid $C_WHITE;
      }
    }

    .row-label-cell {
      flex: 2;
      min-width: $label-width;
      box-sizing: border-box;
      position: sticky;
      left: 0;
      z-index: 99;
      border-radius: 0.2rem;
      background-color: $C_GREY_WARM_1;
      margin-right: 5px;

      .inner-label {
        border-radius: 0.2rem;
        background-color: $C_GREY_WARM_3;
        padding: 0.5rem 1rem;
        width: 80%;
        margin: 1px;
        float: right;
      }
    }
  }
}

.future-stock-popover {
  padding: 1rem;

  .grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 1rem;
    grid-row-gap: 0.2rem;

    .label {
      font-weight: bold;
    }
  }

  .cancel-reservation {
    text-align: right;
    color: $C_DEV_RED;
    margin-top: 1rem;
    cursor: pointer;
  }
}
