@import "../../../../theme/theme";

.receiving-input {
  .barcode-footer {
    height: 48px;
  }

  .rfid-input-type {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .last-scanned {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;

    .scan-details {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .active-container {
    flex: 1;
    text-align: center;
  }

  .create-pkg {
    flex: 1;
    text-align: right;
  }

  .kit {
    border: 1px dashed $C_GREY_WARM_6;
    margin: 10px 10px 10px 0;

    > .info {
      padding: 0 10px;

      > .header {
        .item-type-pill.kit {
          border: none;
          background-color: $C_GREY_COOL_3;
        }

        .header-row,
        .inspection-failed {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .view-inspection {
          cursor: pointer;
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }

    &.inspected {
      border-style: solid;

      > .info {
        > .header {
          .item-type-pill.kit {
            background-color: $C_SALMON;
          }

          .inspection-failed {
            font-size: 12px;
            color: $C_RED;
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
