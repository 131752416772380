@import "theme";
@import "base";
@import "util";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: $C_TEXT;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
  box-shadow: inset 0 0 20px 20px #FFFFFF29;
}

html {
  height: 100%;
}

iframe {
  border: none;
}

body {
  background-color: $C_BACKGROUND_PRIMARY;
  color: $C_TEXT;
  height: 100%;
  scrollbar-color: $C_GREY_COOL_10 $C_BACKGROUND_PRIMARY;
  //min-width: 768px;

  @include RobotoRegular;
  font-size: $F_SIZE;
  font-weight: 400;

  &.bg-white {
    background-color: $C_WHITE_2;
  }

  &.bg-grey {
    background-color: $C_BACKGROUND_PRIMARY;
  }

  .filter-transition {
    transition: padding-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &.filter-nav {
      &.filter-open {
        padding-left: $S_FILTER_WIDTH;
      }

      &.filter-closed {
        padding-left: 0;
      }
    }

    &.filter-content {
      padding-top: $S_SUB_HEADER_HEIGHT;
      padding-right: $S_GUTTER;

      &.no-padding {
        padding-top: 0;
        padding-right: 0;
      }

      &.filter-open {
        padding-left: $S_FILTER_WIDTH + $S_GUTTER;

        &.no-padding {
          padding-left: $S_FILTER_WIDTH;
        }
      ;
      }

      &.filter-closed {
        padding-left: $S_GUTTER;

        &.no-padding {
          padding-left: 0;
        }
      }
    }
  }

  .nav-shell {
    height: 100%;
    display: flex;
    flex-direction: column;

    .app-content {
      flex: 1;
      overflow: auto;
      transition: padding 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }
  }

  .full-height-flex-column {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .full-height-flex-row {
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .page-content {
    flex: 1;
    overflow: auto;
    padding: 23px;

    &.no-top-padding {
      padding-top: 0;
    }

    &.empty-filter-container {

    }
  }

  .tab-page-content {
    flex: 1;
    overflow: auto;
    overflow-y: hidden;
  }


  .flex-sizing-min-height {
    min-height: 0;
  }

  .align-self-start {
    align-self: flex-start;
  }

  .wrap-line {
    word-wrap: break-word;
    hyphens: auto;
  }
}

.Select-clear {
  padding-top: 8px;
}

a {
  color: var(--brand-color-lighter) !important;
}

a:hover {
  color: var(--brand-color) !important;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
   .ie11-flex-content-column {
    display: flex;
    flex-direction: column;
  }
}
