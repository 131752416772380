@import "../../../../theme/theme";

.secondary-stocks-modal {
  .secondary-stocks-grid-row {
    .label {
      font-size: 10px;
      color: $C_GREY_COOL_1A;
      padding-right: 2px;
      font-weight: 500;
    }

    .value {
      font-size: 10px;
      color: $C_GREY_COOL_1A;
    }
  }
}
