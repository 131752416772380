@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.shipment-status-pill {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  margin-left: 3px;
  color: #FFFFFF;
}
.shipment-status-pill.pending {
  background-color: #FF9889;
}
.shipment-status-pill.awaiting-release {
  background-color: #D790E3;
}
.shipment-status-pill.released {
  background-color: #78ACEA;
}
.shipment-status-pill.assigned {
  background-color: #FFDF00;
}
.shipment-status-pill.ready-to-ship {
  background-color: #50E3C2;
}
.shipment-status-pill.shipped {
  background-color: #018D9E;
}
.shipment-status-pill.delivered {
  background-color: #69C00A;
}
.shipment-status-pill.complete {
  background-color: #C1C0EA;
}
.shipment-status-pill.exception {
  background-color: #CE6A00;
}
.shipment-status-pill.review {
  background-color: #DAA479;
}
.shipment-status-pill.cancelled {
  background-color: #868E97;
}