@import "../theme/theme";

.decommissioned-pill {
  padding: 2px 4px;
  margin-left: 5px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  color: $C_WHITE;
  background-color: $C_RED;
}
