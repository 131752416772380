@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.receipt-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCCCCC;
}
.receipt-detail-container .receipt-bins-container {
  display: flex;
  overflow-x: scroll;
}
.receipt-detail-container .shipment-container,
.receipt-detail-container .kit-container,
.receipt-detail-container .tray-container,
.receipt-detail-container .pkg-container,
.receipt-detail-container .bin-container {
  display: inline-block;
  padding: 5px;
}
.receipt-detail-container .shipment-container.errors .info,
.receipt-detail-container .kit-container.errors .info,
.receipt-detail-container .tray-container.errors .info,
.receipt-detail-container .pkg-container.errors .info,
.receipt-detail-container .bin-container.errors .info {
  border-color: #9F0620;
}
.receipt-detail-container .shipment-container.errors .info .details .errors,
.receipt-detail-container .kit-container.errors .info .details .errors,
.receipt-detail-container .tray-container.errors .info .details .errors,
.receipt-detail-container .pkg-container.errors .info .details .errors,
.receipt-detail-container .bin-container.errors .info .details .errors {
  color: #9F0620;
}
.receipt-detail-container .shipment-container > .info,
.receipt-detail-container .kit-container > .info,
.receipt-detail-container .tray-container > .info,
.receipt-detail-container .pkg-container > .info,
.receipt-detail-container .bin-container > .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  border: 1px solid #EFEFEF;
  padding: 10px;
  min-height: 90px;
  min-width: 140px;
  cursor: default;
}
.receipt-detail-container .shipment-container > .info > .header,
.receipt-detail-container .kit-container > .info > .header,
.receipt-detail-container .tray-container > .info > .header,
.receipt-detail-container .pkg-container > .info > .header,
.receipt-detail-container .bin-container > .info > .header {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #979797;
  font-weight: 400;
}
.receipt-detail-container .shipment-container > .info > .header .header-row,
.receipt-detail-container .kit-container > .info > .header .header-row,
.receipt-detail-container .tray-container > .info > .header .header-row,
.receipt-detail-container .pkg-container > .info > .header .header-row,
.receipt-detail-container .bin-container > .info > .header .header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.receipt-detail-container .shipment-container > .info > .header span,
.receipt-detail-container .kit-container > .info > .header span,
.receipt-detail-container .tray-container > .info > .header span,
.receipt-detail-container .pkg-container > .info > .header span,
.receipt-detail-container .bin-container > .info > .header span {
  cursor: pointer;
}
.receipt-detail-container .shipment-container > .info > .details,
.receipt-detail-container .kit-container > .info > .details,
.receipt-detail-container .tray-container > .info > .details,
.receipt-detail-container .pkg-container > .info > .details,
.receipt-detail-container .bin-container > .info > .details {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.receipt-detail-container .shipment-container > .info > .details .line,
.receipt-detail-container .kit-container > .info > .details .line,
.receipt-detail-container .tray-container > .info > .details .line,
.receipt-detail-container .pkg-container > .info > .details .line,
.receipt-detail-container .bin-container > .info > .details .line {
  display: flex;
  flex-direction: row;
}
.receipt-detail-container .shipment-container > .info > .details .line .count,
.receipt-detail-container .kit-container > .info > .details .line .count,
.receipt-detail-container .tray-container > .info > .details .line .count,
.receipt-detail-container .pkg-container > .info > .details .line .count,
.receipt-detail-container .bin-container > .info > .details .line .count {
  flex: 1;
  text-align: right;
  margin-right: 5px;
}
.receipt-detail-container .shipment-container > .info > .details .line .label,
.receipt-detail-container .kit-container > .info > .details .line .label,
.receipt-detail-container .tray-container > .info > .details .line .label,
.receipt-detail-container .pkg-container > .info > .details .line .label,
.receipt-detail-container .bin-container > .info > .details .line .label {
  flex: 2;
  text-align: left;
}
.receipt-detail-container .shipment-container > .info > .footer,
.receipt-detail-container .kit-container > .info > .footer,
.receipt-detail-container .tray-container > .info > .footer,
.receipt-detail-container .pkg-container > .info > .footer,
.receipt-detail-container .bin-container > .info > .footer {
  margin-top: 5px;
  font-size: 12px;
}
.receipt-detail-container .shipment-container.entered:not(.selected) > .info:hover,
.receipt-detail-container .kit-container.entered:not(.selected) > .info:hover,
.receipt-detail-container .tray-container.entered:not(.selected) > .info:hover,
.receipt-detail-container .pkg-container.entered:not(.selected) > .info:hover,
.receipt-detail-container .bin-container.entered:not(.selected) > .info:hover {
  cursor: pointer;
}
.receipt-detail-container .shipment-container.entered > .info,
.receipt-detail-container .kit-container.entered > .info,
.receipt-detail-container .tray-container.entered > .info,
.receipt-detail-container .pkg-container.entered > .info,
.receipt-detail-container .bin-container.entered > .info {
  background-color: #FFFFFF;
}
.receipt-detail-container .shipment-container.entered > .info:hover,
.receipt-detail-container .kit-container.entered > .info:hover,
.receipt-detail-container .tray-container.entered > .info:hover,
.receipt-detail-container .pkg-container.entered > .info:hover,
.receipt-detail-container .bin-container.entered > .info:hover {
  border-color: #4A90E2;
  transition: border 0.3s linear;
}
.receipt-detail-container .shipment-container.entered > .info.errors > .info,
.receipt-detail-container .kit-container.entered > .info.errors > .info,
.receipt-detail-container .tray-container.entered > .info.errors > .info,
.receipt-detail-container .pkg-container.entered > .info.errors > .info,
.receipt-detail-container .bin-container.entered > .info.errors > .info {
  border-color: #CC453F;
}
.receipt-detail-container .shipment-container.selected > .info,
.receipt-detail-container .kit-container.selected > .info,
.receipt-detail-container .tray-container.selected > .info,
.receipt-detail-container .pkg-container.selected > .info,
.receipt-detail-container .bin-container.selected > .info {
  border-color: #4A90E2;
}
.receipt-detail-container .shipment-container .title-pill,
.receipt-detail-container .kit-container .title-pill,
.receipt-detail-container .tray-container .title-pill,
.receipt-detail-container .pkg-container .title-pill,
.receipt-detail-container .bin-container .title-pill {
  background-color: transparent;
}