@import "../theme/theme";

.item-class-pill-container {
  display: flex;
  flex-direction: column;

  .item-class-pill {
    padding: 3px 4px;
    border-radius: $S_BORDER_RADIUS;
    font-size: 11px;
    color: $C_WHITE;
    margin-right: auto;
    font-weight: 500;

    &.implant {
      background-color: $C_BLUE_PASTEL_2;
    }

    &.instrument {
      background-color: $C_MINT;
    }

    &.mixed {
      background-color: $C_TEAL;
      padding: 3px 7px;
    }
  }
}
