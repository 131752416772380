@import "../theme/theme";

.view-item-alternates-pill {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  background-color: $C_GOLD;
  display: inline;
  cursor: pointer;
}
