@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.reports-list .report-panel {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 15px;
}
.reports-list .report-panel:hover {
  background-color: #e4e4e4;
}
.reports-list .report-panel .icon {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reports-list .report-panel .icon.orange {
  background-color: #FFAC00;
}
.reports-list .report-panel .icon.green {
  background-color: #69C00A;
}
.reports-list .report-panel .icon.brand {
  background-color: var(--brand-color-lighter);
}
.reports-list .report-panel .title {
  font-weight: 500;
  font-size: 16px;
}
.reports-list .report-panel .desc {
  font-size: 12px;
}