@import "../theme/theme";

.expiration-pill {
  margin-left: 5px;
  padding: 2px 4px 2px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  color: $C_WHITE;

  &.expired {
    background-color: $C_RED;
    display: inline;
  }

  &.good-date {
    display: none;
  }
}
