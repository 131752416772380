@import "../../../../../theme/theme";

.kit-pick-component {
  margin-top: 20px;

  .kit-block {
    font-size: 16px;
    margin-bottom: 5px;

    .serial {
      color: $C_BLUE;
    }
  }
}