@import "../../../../../theme/theme";

.grid-dashboard {
  .detail-panel {
    margin-bottom: 25px;

    > .header {
    }

    .dashboard-panel-body {
      height: 250px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 7px;

      border-top: solid 1px $C_GREY_WARM_3;

      &.without-grid {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: $S_GUTTER;
        border-top: none;
        overflow-y: hidden;
      }

      &.hide-overflow {
        overflow: hidden;
      }

      &.line-chart-padding {
        overflow-y: hidden;
        padding: 25px;
      }

      .showing-first-10 {
        padding: 10px;
      }
    }

    .dashboard-panel-body-no-grid {
      height: 250px;
      overflow-x: hidden;
      overflow-y: hidden;
      margin-top: 7px;
      border-top: none;

      .line-chart-padding {
        overflow-y: hidden;
        overflow-x: hidden;
        height: 250px;
        padding: 0 20px 20px 20px;
      }
    }
  }

  .move-grid .move-grid-body {
    border: none;
  }

  .unread-body {
    padding: 10px 0;
  }
}
