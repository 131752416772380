@import "../theme/theme";

.breadcrumbs {
  display: flex;
  flex-direction: row;
  font-size: 14px;

  .divider {
    margin: 0 5px;
  }

  *:last-child {
    color: $C_TEXT;
    font-weight: 400;
  }
}
