.erp-error-container {
  text-align: center;
  padding: 0 40px;

  .erp-error {
    padding: 10px;
    border-radius: 4px;
    background-color: #f0f0b5;
    border: 1px solid #d8d89f;
    color: #636348;
    font-size: 12px;
    text-align: left;

    .error-title {
      font-weight: 500;
      font-size: 16px;
    }
  }
}