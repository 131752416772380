@import "../../../../../../../theme/theme";

.preview-pref-card-modal {

  .search-card-shell {

    .filters-container {
      display: flex;
      padding-bottom: 15px;
    }
  }

  .search-grid-container {
    color: $C_TEXT;
    height: 600px;
  }

  .preview-items-container {
    color: $C_TEXT;

    .title-block-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 15px;

      .main-title-block {
        display: flex;
        justify-content: center;
        height: 23px;
        margin-bottom: 2px;
        align-items: center;

        .pill-container {
          margin: 4px 0;
        }

        .title {
          font-size: 19px;
          font-weight: 500;
        }
      }

      .subtitle {
        font-size: 18px;
        font-weight: 100;
        display: flex;
        justify-content: center;
      }

    }

    .items-grid-container {
      height: 545px;
    }

  }

}
