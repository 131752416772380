@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.transfer-review-shell .last-scanned {
  font-weight: 300;
  font-size: 16px;
  color: #616161;
  margin-bottom: 1rem;
}
.transfer-review-shell .stock-search-into-container {
  font-size: 10px;
}
.transfer-review-shell .overview-display-boxes {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  column-gap: 1rem;
}
.transfer-review-shell .overview-display-boxes .display-box {
  position: relative;
  flex: 0 0 25rem;
  flex-basis: 30rem;
  border-radius: 0.3rem;
  padding: 0.5rem 2rem;
}
.transfer-review-shell .overview-display-boxes .display-box .export-unreconciled-icon {
  position: absolute !important;
  top: 0;
  right: 0;
}
.transfer-review-shell .overview-display-boxes .display-box .heading {
  font-size: 3rem;
}
.transfer-review-shell .overview-display-boxes .display-box .type {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
}
.transfer-review-shell .overview-display-boxes .display-box .links {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.transfer-review-shell .overview-display-boxes .display-box .links a {
  color: #386CA9 !important;
}
.transfer-review-shell .segmented-control-header {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 !important;
}
.transfer-review-shell .segmented-control-header .segmented-control-left {
  white-space: nowrap;
  min-width: 360px;
}
.transfer-review-shell .segmented-control-header .segmented-control-left a {
  margin-left: 1rem;
  font-size: 1rem;
}
.transfer-review-shell .segmented-control-header .segmented-control {
  width: 100%;
}
.transfer-review-shell .segmented-control-header .segmented-control-right {
  min-width: 360px;
  flex-shrink: 0;
  text-align: right;
}
.transfer-review-shell .unreconciled-info {
  margin: 1rem 0 2rem 0;
  color: #616161;
  border: 1px solid #616161;
  border-radius: 0.2rem;
  padding-right: 2rem;
  width: fit-content;
}
.transfer-review-shell .unreconciled-info a {
  margin-left: 1rem;
  font-size: 1rem;
}
.transfer-review-shell .footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.transfer-review-shell .footer-container .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  opacity: 0.8;
}