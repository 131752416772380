@import "../theme/theme";

.shipment-state-pill {
  padding: 2px 5px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  margin-left: 3px;
  color: $C_WHITE;

  &.past-due {
    background-color: $C_RED_PASTEL;
  }

  &.pending {
    background-color: $C_ORANGE;
  }

  &.to-do {
    background-color: $C_GREY_COOL_5;
  }

  &.in-progress {
    background-color: $C_BLUE;
  }
}
