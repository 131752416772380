@import "../../../../../../../../theme/theme";

.picks-tab-section {
  .picks-row {
    display: block !important;

    .child-picks-row {
      overflow: hidden;
      background-color: $C_GREY_WARM_1;

      &:hover {
        background-color: #F5F5F5;
      }
    }
  }
}
