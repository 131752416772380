@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.receiving-page .receiving-header {
  z-index: 1200;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  padding: 0 11px 0 8px;
  height: 58px;
  position: relative;
  min-width: 730px;
  font-size: 17px;
  font-weight: 300;
  color: #FDFDFD;
  background-color: var(--brand-color-lighter);
  box-shadow: 0 3px 5px -3px #D4DCDF;
}
.receiving-page .receiving-header .center {
  display: flex;
  justify-content: center;
}
.receiving-page .receiving-header .right {
  display: flex;
  justify-content: flex-end;
}
.receiving-page .receiving-header > .center > .nav-bar-location {
  font-size: 17px;
}
.receiving-page .receiving-header > .center > .nav-bar-location.clickable {
  cursor: pointer;
}
.receiving-page .turn-around {
  color: #e26464;
}
.receiving-page .choose-location {
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 400px;
}
.receiving-page .choose-location .location-selector-container {
  flex: 1;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.receiving-page .choose-location .location-selector-container .location-selector {
  justify-content: flex-end;
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-shadow: 0 2px 2px 0 rgba(160, 167, 175, 0.14);
  padding: 60px;
  color: #868E97;
}
.receiving-page .choose-location .location-selector-container div {
  flex: 1;
}
.receiving-page .choose-location .info {
  margin-top: 20px;
  flex: 2;
  font-size: 16px;
  color: #868E97;
}
.receiving-page .process-tracking-number .tracking-number {
  font-size: 18px;
}
.receiving-page .process-tracking-number .clear-anchor {
  padding-left: 5px;
  font-size: 11px;
  text-transform: uppercase;
}
.receiving-page .counts {
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
}
.receiving-page .counts > * {
  margin-top: 2px;
}
.receiving-page .counts .unmatched-stocks {
  color: #4C5660;
}
.receiving-page .counts .extra-entry-needing-resolution,
.receiving-page .counts .unmatched-stocks-needing-resolution,
.receiving-page .counts .inspections-needing-attn {
  color: #9F0620;
}
.receiving-page .title-pill {
  padding: 2px 4px;
  color: #4c5660;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}
.receiving-page .tag-anchor {
  color: #4C5660 !important;
}
.receiving-page .tag-anchor:hover {
  color: #616161 !important;
  text-decoration: underline !important;
}
.receiving-page .count-box-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
.receiving-page .count-box-container .count-box {
  display: inline-block;
  padding: 24px 32px;
  margin-left: 6px;
  margin-right: 6px;
  border-width: 2px;
  border-style: solid;
  color: #000000;
  width: 150px;
  text-align: center;
}
.receiving-page .count-box-container .count-box .count-box__quantity {
  font-size: 24px;
}
.receiving-page .count-box-container .count-box .count-box__label {
  font-size: 12px;
}
.receiving-page .count-box-container .count-box.count-box--missing {
  background-color: #FFD4CE;
  border-color: #CC453F;
}
.receiving-page .count-box-container .count-box.count-box--incomplete {
  background-color: #F9EBC3;
  border-color: #FFAC00;
}
.receiving-page .count-box-container .count-box.count-box--reserved {
  background-color: #FBE5FF;
  border-color: #AF22C7;
}
.receiving-page .count-box-container .count-box.count-box--scanned {
  background-color: #ECF6FF;
  border-color: #4A90E2;
}