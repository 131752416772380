@import "theme";

.date-pill {
  display: inline-flex;
  width: 150px;
  padding: 10px;
  border: 1px solid var(--brand-color);
  color: var(--brand-color);
  border-radius: 4px;
  font-size: 14px;
  flex-direction: column;
  text-align: center;
  margin: 0 10px 10px 0;

  &:hover {
    background-color: var(--brand-color);
    color: $C_WHITE;
  }
}
