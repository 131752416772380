@import "../../../../../../../../theme/theme";

.usage-chooser-stock-modal {
  $header-footer-height: 80px;
  $filter-border: 1px solid $C_GREY_COOL_3;
  $filter-gutter: calc($S_GUTTER/2);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $Z_FULL_SCREEN + 10;
  background-color: $C_GREY_WARM_1;

  > .modal-header {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: $Z_APP_BAR;
    height: $header-footer-height;
    background-color: $C_GREY_COOL_1;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    padding: 0 $S_GUTTER;

    .cancel-container {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .title-container {
      flex: 1;
      text-align: center;
      color: $C_WHITE;

      .title {
        font-size: 16px;
        margin-top: 15px;
      }

      .item-info {
        font-size: 18px;
      }
    }

    .clear-container {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  > .content {
    position: absolute;
    top: $header-footer-height;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .add-items-container {
    //display: flex;
    //flex-direction: column;
    //height: 100%;

    .search-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .flex-basis-275 {
        flex-basis: 275px;
      }
    }

    .item-chooser-search-cell {

      &.highlighted {
        background-color: $C_GREY_WARM_2;
      }

      &.flash {
        background-color: $C_MINT_LIGHT;
      }

      &.fade {
        transition: background-color 0.3s linear;
      }

      .item-number {

        .explore {
          margin-left: 5px;
          font-size: 12px;
        }
      }

      .item-desc {
        color: $C_GREY_COOL_2;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .configure-items-container {
    //display: flex;
    //flex-direction: column;
    //height: 100%;

    .search-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .flex-basis-290 {
        flex-basis: 290px;
      }
    }
  }

  .diff-items-container {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 80px;

    .no-changes {
      margin-top: 20px;
      color: $C_GREY_COOL_2;
      font-size: 22px;
      text-align: center;
    }

    .grid-title {
      font-weight: $F_WEIGHT_MEDIUM;
      font-size: 22px;
      margin-top: $S_GUTTER;
    }

    .save-container {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        opacity: .3;
      }
    }
  }
}
