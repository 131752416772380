@import "../theme/theme";

.full-screen-modal {
  $header-footer-height: $S_HEADER_HEIGHT + $S_SUB_HEADER_HEIGHT;
  $filter-border: 1px solid $C_GREY_COOL_3;
  $filter-gutter: calc($S_GUTTER/2);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $Z_FULL_SCREEN;
  background-color: $C_GREY_WARM_1;

  > .full-screen-modal-header {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: $Z_APP_BAR;
    height: $header-footer-height;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    padding: 0 $S_GUTTER;
    display: flex;
    align-items: center;

    &.mint-header {
      background-color: $C_MINT;
    }

    &.brand-header {
      background-color: var(--brand-color-lighter);
    }

    &.dark-grey-header {
      background-color: $C_GREY_WARM_10;
    }

    .title-left-view,
    .cancel-container,
    .title-container,
    .title-right-view {
      flex: 1;
    }

    .title-left-view {
      display: flex;
      justify-content: flex-start;
    }

    .title-right-view {
      display: flex;
      justify-content: flex-end;
    }

    .cancel-container {
      position: absolute;
      left: 20px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .title-container {
      color: $C_WHITE;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &.no-subView {
        justify-content: center;
        height: 100%;
      }

      .title {
        font-size: 22px;
        padding: 5px 0 5px;
      }

      .subtitle {
        font-size: 16px;
      }
    }
  }

  > .full-screen-modal-content {
    position: absolute;
    top: $header-footer-height;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;

    .full-height-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .filter-transition {
      transition: padding-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }

    .filter-open {
      padding-left: $S_FILTER_WIDTH;
    }

    .search-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0 10px;

      .disposition-container {
        //margin-top: -15px;
        margin-right: 10px;
        flex-basis: 150px;

        .title-lbl {
          font-size: 10px;
        }
      }

      .text-search-container {
        flex-basis: 275px;

        .title-lbl {
          font-size: 10px;
        }
      }

      .flex-basis-290 {
        flex-basis: 290px;
      }

      .add-btns {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .no-changes {
      margin-top: 10px;
      color: $C_GREY_COOL_2;
      font-size: 22px;
      text-align: center;
    }

    .padding-for-full-screen-modal-bottom {
      padding-bottom: 80px;
    }
  }
}

.full-screen-modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  z-index: 1000;

  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);

    display: flex;
    align-items: center;
  }

  .left-el, .center-el, .right-el {
    display: flex;
    opacity: 1;
    flex: 1;
  }

  .left-el {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .center-el {
    justify-content: center;
  }

  .right-el {
    justify-content: flex-end;
    padding-right: 20px;
  }
}
