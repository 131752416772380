@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.loading-ellipsis {
  position: absolute;
  left: 50%;
  top: 50%;
}
.loading-ellipsis.active > span {
  width: 1em;
  height: 1em;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.5s infinite ease-in-out;
  animation: bouncedelay 1.5s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin-left: 2px;
}
.loading-ellipsis.active .bounce1 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.loading-ellipsis.active .bounce2 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    opacity: 0;
  }
}