@import "../theme/theme";

.message-cell {
  background-color: $C_WHITE;
  position: relative;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: darken($C_WHITE, 1%);
  }

  $C_SELECTED: #FFFBDC;

  &.unread,
  &.unread.selected {
    background-color: $C_SELECTED;

    &:hover {
      background-color: darken($C_SELECTED, 1%);
    }
  }

  &.selected {
    opacity: 1;
    //border-right: 1px solid rgb(221, 221, 221);
    box-shadow: 0 2px 2px 0 rgba(160, 167, 175, 0.14);
    z-index: 1; // show the shadow over non-selected cells
    background-color: $C_WHITE; // override default selected style
    &:hover {
      background-color: darken($C_WHITE, 2%);
    }
  }

  /* Urgency Div to left of checkbox*/

  .checkbox-container {
    padding-left: 10px;
    padding-right: 0;
  }

  .message-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre-wrap;
  }

  //&:before {
  //  content: "";
  //  display: block;
  //  width: 5px;
  //  background-color: $C_GREY_COOL_2;
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  bottom: 0;
  //  //opacity: 0.25;
  //}

  &.selected:before {
    opacity: 1;
  }

  &.late {
    &:before {
      background-color: $C_RED;
    }
  }

  &.today {
    &:before {
      background-color: $C_MINT;
    }
  }

  &.tomorrow {
    &:before {
      background-color: $C_BLUE;
    }
  }

  &.one-week,
  &.future {
    &:before {
      background-color: $C_GREY_COOL_1;
    }
  }

  /* Cell Content */

  .purpose {
    color: $C_TEXT;
    @include RobotoRegular;
    font-size: 15px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.selected {
    .purpose {
      color: $C_BLACK;
    }
  }

  .participants,
  .message-preview {
    color: $C_TEXT;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  .participants {
    font-weight: $F_WEIGHT_BOLD;
  }

  .message-preview {
    font-weight: $F_WEIGHT_LIGHT;
  }

  .timestamp-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .timestamp {
      text-align: right;
      font-size: 13px;
      font-weight: $F_WEIGHT_LIGHT;
    }
  }

  .pill-container {
    text-align: right;
    margin-right: 24px;

    &.tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.2;
    }
  }

}
