@import "../../../../../theme/theme";

.price-lookup-action-container {
  align-items: center;
  justify-content: space-between;
  min-width: 1425px;

  .select-menu {
    align-items: center;
    padding-right: 20px;
  }
}

.price-lookup-grid-container {
  position: inherit;
  padding-top: 20px;

  .price-lookup-grand-total-container {
    padding-bottom: 10px;

    .price-lookup-grand-total-container__label {
      font-weight: bold;
    }
  }
}
