@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

@font-face {
  font-family: "TTFirsNeue";
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
.auth-page {
  font-family: "TTFirsNeue", sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
}
.auth-page #logo.dev {
  filter: hue-rotate(325deg);
}
.auth-page #logo.qa {
  filter: hue-rotate(30deg);
}
.auth-page .flex-shrink-0 {
  flex-shrink: 0;
}
.auth-page #logo {
  max-width: 280px;
  height: auto;
  display: block;
  margin: 0 auto 20px auto;
}
.auth-page .login-header {
  font-size: 16px;
  font-weight: 700;
  color: white;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.auth-page .secondary-action {
  font-size: 14px;
  font-weight: 200;
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}
.auth-page .secondary-action a, .auth-page .secondary-action a:hover {
  color: white !important;
}
.auth-page .secondary-action a:hover {
  text-decoration: underline;
}
.auth-page #dev-url {
  font-size: 16px;
  font-weight: 200;
  color: white;
  width: 100%;
  text-align: center;
}
.auth-page #dev-url,
.auth-page #copyright {
  padding: 0 10px;
}
.auth-page .bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("https://static.movemedical.com/login-bg-new-20241024-193739.png"), linear-gradient(#171717, #000000);
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-size: cover;
  z-index: -1;
  filter: brightness(0.4) saturate(0.8);
}
.auth-page .bg.dev {
  filter: hue-rotate(325deg) brightness(0.4) saturate(0.8);
}
.auth-page .bg.qa {
  filter: hue-rotate(30deg) brightness(0.4) saturate(0.8);
}
.auth-page .form-container {
  padding: 25px;
  text-align: center;
}
.auth-page .form-container label {
  font-family: "TTFirsNeue", sans-serif;
  font-size: 14px;
  margin-bottom: 0;
  float: left;
  margin-left: 3px;
}
.auth-page .form-container button {
  font-family: "TTFirsNeue", sans-serif !important;
  background-color: var(--brand-color) !important;
}
.auth-page .form-container button:disabled {
  background-color: #edf1f5 !important;
}
.auth-page #copyright {
  position: fixed;
  bottom: 10px;
  font-weight: 200;
  opacity: 0.3;
  font-size: 12px;
  color: white;
  text-align: center;
  width: 100%;
}
.auth-page #build-number {
  position: fixed;
  bottom: 5px;
  right: 5px;
  color: #fff;
  font-size: 10px;
  opacity: 0.3;
}