.modal-form-field-label {
  color: #89929b;
  text-align: right;
  font-size: 13px;
  padding-top: 15px;

  &.no-padding {
    padding-top: 10px;
  }

  &.text-left {
    justify-content: flex-start !important;
    text-align: left;
  }

  // todo breakpoint detection. if small change to text-align left
}