@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.usage-tab .order-block {
  margin-bottom: 20px;
}
.usage-tab .order-block .order-block-header .order-name {
  font-size: 20px;
}
.usage-tab .order-block .order-block-header .bill-to {
  font-size: 12px;
  color: #BBC2CA;
  margin-left: 5px;
}
.usage-tab .order-block .construct-block {
  margin-left: 20px;
  margin-top: 20px;
}
.usage-tab .order-block .construct-block .construct-header-block .construct-title {
  font-size: 16px;
}
.usage-tab .order-block .construct-block .construct-header-block .construct-desc {
  font-size: 12px;
  color: #BBC2CA;
  margin-left: 5px;
}
.usage-tab .order-block .order-block-header,
.usage-tab .order-block .construct-header-block {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 5px;
}
.usage-tab .order-block .order-block-header > .left-col,
.usage-tab .order-block .construct-header-block > .left-col {
  display: flex;
  align-items: baseline;
  flex: 2;
}
.usage-tab .order-block .order-block-header > .right-col,
.usage-tab .order-block .construct-header-block > .right-col {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
}
.usage-tab .order-block .order-block-header > .right-col > .price-totals,
.usage-tab .order-block .construct-header-block > .right-col > .price-totals {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.usage-tab .order-block .order-block-header > .right-col > .price-totals .line-item,
.usage-tab .order-block .construct-header-block > .right-col > .price-totals .line-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.usage-tab .order-block .order-block-header > .right-col > .price-totals .line-item .label,
.usage-tab .order-block .construct-header-block > .right-col > .price-totals .line-item .label {
  display: flex;
  flex: 4;
  justify-content: flex-end;
  margin-right: 3px;
}
.usage-tab .order-block .order-block-header > .right-col > .price-totals .line-item .value,
.usage-tab .order-block .construct-header-block > .right-col > .price-totals .line-item .value {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}
.usage-tab .order-block .order-block-header > .right-col > .price-totals .bold,
.usage-tab .order-block .construct-header-block > .right-col > .price-totals .bold {
  font-weight: 600;
}