@import "../../../../../theme/theme";

.pick-container-component {
  position: relative;
  padding: 10px;
  width: 175px;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(250, 250, 250);
  border-radius: 4px;
  margin: 10px 10px 10px 0;
  cursor: pointer;
  user-select: none;

  .sub-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    color: rgb(0, 188, 212);
  }

  .print-button {
    position: absolute !important;
    right: 0;
  }

  &.selected {
    border: 1px solid rgb(240, 240, 181);
    background-color: rgb(255, 255, 196);
  }

  &.sealed {

  }
}
