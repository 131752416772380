@import "../theme/theme";

.detail-page-title, .summaryDetail-page-title {
  .title {
    font-size: 22px;
    font-weight: 400;
  }

  .subtitle {
    font-size: 14px;
    line-height: 12px;
    color: $C_GREY_COOL_8;
  }
}
