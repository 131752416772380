@import "../theme/theme";

.tab-qty-pill {
  padding: 1px 3px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  color: $C_WHITE;
  margin-left: 5px;
  background-color: var(--brand-color-lighter);
}
