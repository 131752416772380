@import "../../../../../../../theme/theme";

.process-picks-2-input {
  .container-manager {
    .pad {
      margin-top: 25px;
    }

    .grid-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .errors {
      color: $C_RED;
    }

    .container-cell {
      .contents-link {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .remove {
        text-align: right;
      }
    }
  }
}
