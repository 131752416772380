@import "../../../../../theme/theme";

.report-display-component {
  .report-title {
    font-size: 18px;
  }

  .sheet-lbl {
    font-size: 10px;
    text-transform: uppercase;
    color: var(--brand-color);
    font-weight: $F_WEIGHT_MEDIUM;
    margin-top: 20px;
    margin-bottom: -10px;
  }

  .page-select-container {
    width: 200px;
    margin-left: 20px;
    margin-top: -25px;
  }

  .report-filters {
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: $C_WHITE_2;
    border-radius: $S_BORDER_RADIUS;
    border: solid 1px $C_BORDER_PRIMARY;
    padding: 10px;

    .parameters-title {
      font-size: 16px;
      font-weight: 500;
    }

    > div.row + div.row {
      margin-top: 10px;
    }

    .filter-field-component {
      margin-top: 5px;

      .filter-field-title {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: -5px;
        font-weight: 400;

        &.required {
          font-weight: $F_WEIGHT_MEDIUM;
          color: var(--brand-color);
        }
      }

      .filter-field-sub-title {
        font-weight: 100;
        margin-bottom: -5px;
      }
    }
  }

  .report-content {

  }
}
