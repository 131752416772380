@import "../../../../theme/theme";

.tray-inspection-details {
  display: flex;
  min-width: 1290px;

  .directions-panel {
    display: inline-block;
    width: 40%;
    vertical-align: top;
    margin: 0 1rem 2rem 0;
    background-color: $C_WHITE;
    box-shadow: 0 0 10px $C_GREY_WARM_2;
  }

  .files-panel {
    display: inline-block;
    width: 60%;
    vertical-align: top;

    .grid-container {
      padding: 25px 80px;
    }
  }

  .title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
    padding: 20px 0 0 25px;
  }

  .directions {
    padding: 5px 25px 25px 25px;
    display: inline-block;
    width: 95%;
  }
}

.step-active {
    background-color: $C_MINT_LIGHTEST;

    &:hover {
        background-color: $C_MINT_LIGHTEST !important;
    }
}
.step-disabled {
    color: $C_GREY_COOL_8;
    background-color: $C_GREY_WARM_1;

    &:hover {
        background-color: $C_GREY_WARM_1 !important;
    }
}
