@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.order-tracking-modal-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.order-tracking-modal-container.no-map {
  display: flex;
}
.order-tracking-modal-container .left-container {
  overflow-y: auto;
  width: 100%;
}
.order-tracking-modal-container .left-container.with-map:not(.single-order) {
  height: 800px;
}
.order-tracking-modal-container .left-container .order-tracking-component {
  border-radius: 4px;
  background-color: #FFFFFF;
  padding: 10px 10px 10px 10px;
  margin: 20px;
  height: 600px;
}
.order-tracking-modal-container .left-container .order-tracking-component:not(.single-order) {
  box-shadow: 0 0 10px #CCCCCC;
  border: 1px solid #edf1f5;
}
.order-tracking-modal-container .left-container .order-tracking-component.selected:not(.single-order) {
  border: 1px solid #ECF6FF;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block {
  display: grid;
  grid-template-columns: 17px 115px 1fr 250px;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 8px;
  color: #31373D;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block .color-block {
  width: 7px;
  min-height: 20px;
  margin-right: 10px;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block .title {
  font-size: 20px;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block .sub-title {
  font-size: 16px;
  line-height: 12px;
  color: #999999;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block .action {
  margin: auto;
  color: #4A90E2;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block .location-block {
  width: 250px;
  padding-top: 10px;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block .location-block .sub-title {
  font-size: 13px;
  line-height: 12px;
  color: #65707A;
  padding-bottom: 2px;
}
.order-tracking-modal-container .left-container .order-tracking-component .title-block .location-block .title {
  font-size: 14px;
  color: #31373D;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container {
  background-color: #f5f7f8;
  padding: 7px;
  border-radius: 4px;
  margin-top: 10px;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container.single-order {
  margin-top: 40px;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container .shipment-header {
  display: grid;
  grid-template-columns: 1.2fr 0.7fr 1fr;
  padding: 15px 10px 15px 20px;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container .shipment-header .shipment-title {
  display: flex;
  align-items: center;
  color: #31373D;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container .shipment-header .header-block .sub-title {
  font-size: 13px;
  color: #999999;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container .shipment-header .header-block .title {
  color: #4C5660;
  font-size: 14px;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container .shipment-header .header-block .carrier {
  font-weight: 400;
}
.order-tracking-modal-container .left-container .order-tracking-component .shipment-container .shipment-header .pill-container {
  margin-top: -4px;
  margin-left: 4px;
}
.order-tracking-modal-container .left-container .order-tracking-component .no-shipments-container {
  background-color: #f7f8fb;
  padding: 30px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  font-weight: 100;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container:not(.disabled) {
  cursor: pointer;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container:not(.disabled):hover {
  box-shadow: 0 0 8px #BBC2CA;
}
.order-tracking-modal-container .left-container .order-tracking-component .empty-pkg-component {
  background-color: #FFFFFF;
  padding: 20px;
  margin: 10px;
  border-radius: 4px;
  color: #BBC2CA;
  font-size: 15px;
  text-align: center;
  font-weight: 100;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 50px;
  align-items: center;
  background-color: #FFFFFF;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  border: solid 1px #FFFFFF;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container.selected {
  box-shadow: 0 0 8px #edf1f5;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container.selected:hover {
  box-shadow: 0 0 8px #BBC2CA;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container .pkg-title {
  display: flex;
  align-items: center;
  color: #31373D;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container .pkg-status {
  align-items: center;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container .pkg-block .sub-title {
  font-size: 12px;
  color: #999999;
}
.order-tracking-modal-container .left-container .order-tracking-component .pkg-container .pkg-block .tracking {
  color: #4C5660;
  font-size: 16px;
}
.order-tracking-modal-container .location-block {
  width: 320px;
}
.order-tracking-modal-container .location-block .sub-title {
  font-size: 13px;
  line-height: 12px;
  color: #65707A;
  padding-bottom: 2px;
}
.order-tracking-modal-container .location-block .title {
  font-size: 14px;
  color: #31373D;
}
.order-tracking-modal-container .pkg-tooltip-container {
  cursor: help;
  position: relative;
  text-align: center;
}
.order-tracking-modal-container .pkg-tooltip-container:hover {
  cursor: help;
  z-index: 20;
}
.order-tracking-modal-container .pkg-tooltip {
  position: absolute;
  width: 180px;
  height: 77px;
  font-size: 15px;
  background-color: #FFFFFF;
  opacity: 0.95;
  bottom: 0;
  left: -80px;
  top: -70px;
  display: none;
  border: 1px solid #edf1f5;
  border-radius: 9px;
  color: #31373D;
  text-align: center;
  padding: 17px;
}
.order-tracking-modal-container .pkg-tooltip-container:hover .pkg-tooltip {
  display: block;
}