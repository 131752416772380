@import "../../../../../theme/theme";

.schedule-search-date-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .date-div {
    width: 240px;
    line-height: 48px;
    text-align: center;
    font-size: 20px;
    user-select: none;

    &.list-view {
      cursor: pointer;
    }

  }
}
