@import "../theme/theme";

.container-type-pill {
  padding: 2px 4px 2px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  color: $C_WHITE;

  &.bin {
    background-color: $C_GREEN;
  }

  &.tray {
    background-color: $C_BLUE;
  }

  &.tote {
    background-color: $C_MINT;
  }

  &.temp {
    background-color: $C_GOLD;
  }

  &.package {
    background-color: $C_ORANGE;
  }
}
