@import "../theme/theme";

.text-info-pill {
  display: inline-flex;
  padding: 2px 8px;
  border: 1px solid $C_GREY_COOL_5;
  color: $C_GREY_COOL_5;
  border-radius: 4px;
  font-size: 11px;
  margin-right: auto;
}
