@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.usage-chooser {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1300;
  background-color: #f7f8fb;
}
.usage-chooser > .header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  height: 80px;
  background-color: #22bda9;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  padding: 0 23px;
}
.usage-chooser > .header .cancel-container {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.usage-chooser > .header .title-container {
  text-align: center;
  color: #FFFFFF;
}
.usage-chooser > .header .title-container .title {
  font-size: 22px;
  text-shadow: #808b96 1px 1px 1px;
  padding: 2px 0 2px;
}
.usage-chooser > .content {
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  bottom: 0;
}
.usage-chooser .add-container .search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 10px;
}
.usage-chooser .add-container .search-header .disposition-container {
  margin-right: 10px;
  flex-basis: 150px;
}
.usage-chooser .add-container .search-header .disposition-container .title-lbl {
  font-size: 10px;
}
.usage-chooser .add-container .search-header .text-search-container {
  margin-top: -15px;
  flex-basis: 275px;
}
.usage-chooser .add-container .search-header .text-search-container .title-lbl {
  font-size: 10px;
}
.usage-chooser .add-container .item-chooser-search-cell.highlighted {
  background-color: #EFEFEF;
}
.usage-chooser .add-container .item-chooser-search-cell.flash {
  background-color: #50E3C2;
}
.usage-chooser .add-container .item-chooser-search-cell.fade {
  transition: background-color 0.3s linear;
}
.usage-chooser .add-container .item-chooser-search-cell .item-number .explore {
  margin-left: 5px;
  font-size: 12px;
}
.usage-chooser .add-container .item-chooser-search-cell .item-desc {
  color: #BBC2CA;
  font-size: 14px;
  font-weight: 300;
}
.usage-chooser .review-container .search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.usage-chooser .review-container .search-header .flex-basis-290 {
  flex-basis: 290px;
}
.usage-chooser .review-container .search-header .add-btns {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.usage-chooser .review-container .item-chooser-cell.remove {
  color: #ccc;
}
.usage-chooser .review-container .item-chooser-cell.remove a, .usage-chooser .review-container .item-chooser-cell.remove a:hover {
  color: #ccc;
}
.usage-chooser .review-container .item-chooser-cell.remove .Select-value-label {
  color: #ccc !important;
}
.usage-chooser .footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.usage-chooser .footer-container .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  opacity: 0.8;
}
.usage-chooser .footer-container .right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}