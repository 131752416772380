.attr-conversation-cell {
  .message-preview {
    color: #9c9c9c;
  }

  .sent-date {
    text-align: right;
  }

  &.unread {
    background-color: #FFFFE9;

    .sender,
    .subject,
    .sent-date {
      font-weight: 400;
    }

    &:hover {
      background-color: #FFFFC4;
    }
  }

  .cell {
    cursor: pointer;
  }
}