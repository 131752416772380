@import "../theme/theme";

.case-event-status-pill {
  border-radius: $S_BORDER_RADIUS;
  padding: 4px 8px;
  color: $C_WHITE;
  white-space: nowrap;

  &.planning {
    background-color: $C_ORANGE;
  }

  &.confirmed {
    background-color: $C_BLUE;
  }

  &.preparing {
    background-color: $C_TEAL_LIGHT;
  }

  &.awaiting-delivery {
    background-color: $C_TAN;
  }

  &.ready {
    background-color: $C_GREEN;
  }

  &.in-progress {
    background-color: $C_TEAL;
  }

  &.post-op {
    background-color: $C_PURPLE;
  }

  &.complete {
    background-color: $C_GREY_COOL_5;
  }

  &.cancelled {
    background-color: $C_DEV_RED;
  }

  &.gathering-usage {
    background-color: $C_PURPLE_LIGHT;
  }

  &.closed {
    background-color: $C_GREY_COOL_4;
  }

  &.option-disabled {
    background-color: $C_GREY_COOL_3;
  }

  &.small {
    padding: 2px 4px 2px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 3px;
  }
}
