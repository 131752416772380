@import "theme";

.item-availability-component {
  padding: 0 10px 10px 10px;
  font-size: 14px;
  color: $C_TEXT;

  .date {
    font-size: 19px;
    color: $C_TEXT;
  }
}

.no-availability-message {
  padding: 10px;
  color: $C_TEXT;
}

