@import "../../../../../../../../theme/theme";

.qty-container {
  display: flex;
  align-items: center;

  .usage-tooltip-container:hover {
    cursor: help;
  }

  .usage-tooltip-container {
    width: 35px;
  }

  .usage-tooltip-container .qty-tooltip {
    position: absolute;
    visibility: hidden;
    background-color: $C_WHITE;
    right: 20px;
    padding: 12px;
    border: $C_GREY_WARM_4 1px solid;
    z-index: 10000;
    border-radius: 9px;
    width: 180px;
    color: $C_TEXT;
    opacity: .9;
  }
  .usage-tooltip-container:hover .qty-tooltip {
    visibility: visible;
  }
}


