@import "../../../../../../theme/theme";

.tag-commission-page {
  .title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .reset-link {
    color: $C_RED !important;
    margin-right: 10px;
    font-size: 10px;
  }

  .item-lbl,
  .lot-serial-lbl {
    font-size: 16px;
    font-weight: 400;
    padding-right: 15px;
  }

  .none-selected {
    font-size: 14px;
    color: $C_GREY_COOL_2;
  }

  .tag-commission-grid {
    margin-top: 20px;
  }

  .tag-error {
    display: inline-block;
    margin: 5px 0;
    background: $C_RED;
    border-radius: 4px;
    padding: 5px 10px;
    color: $C_WHITE;
  }

  .tag-decommissioned {
    display: inline-block;
    margin: 5px 0;
    background: $C_BLUE_DEEP;
    border-radius: 4px;
    padding: 5px 10px;
    color: $C_WHITE;
  }
}
