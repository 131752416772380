@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.grid-dashboard .detail-panel {
  margin-bottom: 25px;
}
.grid-dashboard .detail-panel .dashboard-panel-body {
  height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 7px;
  border-top: solid 1px #dce2e7;
}
.grid-dashboard .detail-panel .dashboard-panel-body.without-grid {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 23px;
  border-top: none;
  overflow-y: hidden;
}
.grid-dashboard .detail-panel .dashboard-panel-body.hide-overflow {
  overflow: hidden;
}
.grid-dashboard .detail-panel .dashboard-panel-body.line-chart-padding {
  overflow-y: hidden;
  padding: 25px;
}
.grid-dashboard .detail-panel .dashboard-panel-body .showing-first-10 {
  padding: 10px;
}
.grid-dashboard .detail-panel .dashboard-panel-body-no-grid {
  height: 250px;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 7px;
  border-top: none;
}
.grid-dashboard .detail-panel .dashboard-panel-body-no-grid .line-chart-padding {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 250px;
  padding: 0 20px 20px 20px;
}
.grid-dashboard .move-grid .move-grid-body {
  border: none;
}
.grid-dashboard .unread-body {
  padding: 10px 0;
}