@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.requirements-container {
  padding-top: 5px;
  min-width: 1082px;
}
.requirements-container .component-container {
  background-color: #FDFDFD;
  border-radius: 5px;
  border: solid 1px #dce2e7;
  margin-bottom: 20px;
  min-width: 885px;
}
.requirements-container .component-container:hover {
  border-color: #D6D6D6;
  box-shadow: 0 0 4px #dce2e7;
}
.requirements-container .component-title {
  padding: 5px 0 5px 20px;
  color: #31373D;
  font-size: 16px;
  display: flex;
  min-width: 812px;
  align-items: center;
}
.requirements-container .component-title .catalog-title {
  display: flex;
  align-items: center;
}
.requirements-container .nested-bundle-container {
  margin: 0 20px 20px 20px;
  border-radius: 5px;
  background-color: #FAFAFA;
  border: solid 1px #EFEFEF;
}
.requirements-container .nested-bundle-container:hover {
  border-color: #dce2e7;
}
.requirements-container .empty-items-container {
  padding-top: 10px;
}
.requirements-container .item-number-title {
  display: flex;
}
.requirements-container .bundle-desc {
  font-size: 14px;
  color: #979797;
}
.requirements-container .catalog-title {
  color: #31373D;
  font-size: 17px;
}
.requirements-container .review-component-container {
  padding: 0 20px 20px 20px;
  min-width: 1040px;
}
.requirements-container .review-component-container.combined-view {
  padding: 0 0 20px 0;
}
.requirements-container .review-component-container .class-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: 200;
  font-size: 15px;
  padding: 3px 0;
  color: #FFFFFF;
  border-radius: 5px 5px 0 0;
}
.requirements-container .review-component-container .class-header.collapsed {
  border-radius: 5px;
}
.requirements-container .review-component-container .class-header.implant {
  background-color: #82A6CE;
}
.requirements-container .review-component-container .class-header.instrument {
  background-color: #7DBBB2;
}
.requirements-container .review-component-container .class-header.mixed {
  background-color: #76B1C4;
}
.requirements-container .review-component-container .class-header .expand-collapse-container {
  grid-column: 1;
  padding-left: 15px;
  display: flex;
  align-items: center;
}
.requirements-container .review-component-container .class-header .class-title {
  grid-column: 2;
  display: flex;
  justify-content: center;
  margin-top: 4px;
}