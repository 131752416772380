@import "../../theme/theme";

$HEADER_FOOTER_HEIGHT: 32px;

.move-grid {
  scrollbar-color: $C_GREY_COOL_10 $C_WHITE_2;
  font-weight: 400;

  .move-grid-body > div:nth-of-type(even) {
    background-color: $C_GREY_WARM_8;
  }

  &.full-height, &.fixed-height {
    display: flex;
    flex-direction: column;

    .move-grid-body {
      overflow-y: auto;

      .move-grid-cell:last-child {
        //border-bottom: 1px solid $C_BORDER_PRIMARY;
      }
    }

    .move-grid-footer {
      min-height: $HEADER_FOOTER_HEIGHT;
      z-index: 1;
    }
  }

  &.full-height {
    .move-grid-body {
      flex: 1;
    }
  }

  &.fixed-height {
    .move-grid-body {
      max-height: 400px;
    }

    @media screen and (min-height: 1024px) {
      .move-grid-body {
        max-height: 600px;
      }
    }
  }

  .move-grid-header {
    border: 1px solid $C_BORDER_PRIMARY;
    border-radius: $S_BORDER_RADIUS $S_BORDER_RADIUS 0 0;
    background-color: $C_BACKGROUND_PRIMARY;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: $HEADER_FOOTER_HEIGHT;
    position: relative;

    &.no-border-radius {
      border-radius: 0;
    }

    .move-grid-header-cell {
      user-select: none;
      cursor: default;
      font-weight: 400;
      font-size: 12px;
      color: #5f5f5f;
      text-transform: uppercase;

      &.sortable {
        cursor: pointer;
      }

      .carrot {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
        transform: scale(1, .5);
      }
    }
  }

  .move-grid-body {
    border: 1px solid $C_BORDER_PRIMARY;
    background-color: #fff;
    margin-top: -1px;
    z-index: 1;
    overflow: hidden;

    &.no-footer {
      border-radius: 0 0 $S_BORDER_RADIUS $S_BORDER_RADIUS;
    }

    &.no-border-radius {
      border-radius: 0;
    }

    .move-grid-cell {
      border-bottom: 1px solid $C_GREY_WARM_8;

      // Child lines
      &.child-row {
        border-left: 10px solid $C_GREY_COOL_9;

        &:not(.selection-row) > div > div:first-child {
          margin-right: 0 !important;
        }
      }

      &.selected {
        background-color: $C_BLUE_PASTEL_4;

        &:hover {
          background-color: $C_BLUE_PASTEL_3;
        }
      }

      &.highlighted {
        background-color: $C_BLUE_LIGHTER;

        &:hover {
          background-color: $C_BLUE_GREY_LIGHTEST;
        }

        &.grey {
          background-color: $C_GREY_WARM_8;
        }

        &.grey:hover {
          background-color: $C_GREY_WARM_2;
        }

        &.red {
          background-color: $C_RED_PASTEL_LIGHTER;
        }

        &.red:hover {
          background-color: $C_RED_LIGHT;
        }

        &.green {
          background-color: $C_GREEN_LIGHTEST;
        }

        &.green:hover {
            background-color: $C_GREEN_LIGHTER ;
        }
      }

      &:hover {
        background-color: var(--brand-color-lightest);
      }

      &:last-child {
        border-bottom: none;
      }

      > .checkbox-cell {
        flex-shrink: 0;
      }
    }

    .move-grid-cell,
    .move-grid-cell > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .move-grid-loading-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 10px;
    }
  }

  .move-grid-footer {
    border-left: 1px solid $C_BORDER_PRIMARY;
    border-right: 1px solid $C_BORDER_PRIMARY;
    border-bottom: 1px solid $C_BORDER_PRIMARY;
    border-radius: 0 0 $S_BORDER_RADIUS $S_BORDER_RADIUS;
    background-color: $C_BACKGROUND_PRIMARY;
    padding: 5px 20px 5px 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > * {
      margin-left: 10px;
      cursor: pointer;
    }

    &.disabled {
      > * {
        cursor: not-allowed;
      }
    }
  }
}

// Sticky Grids: The Receiving Page has a different layout from others
.receiving-page {
  .move-grid-header {
    position: sticky;
    z-index: 10;
    top: -20px;
  }
}

// Sticky Grids: Detail Pages
.detail-page-component {
  .page-content:not(.tab-reset-overflow) {
    overflow: initial;
  }

  .move-grid-header {
    position: sticky;
    z-index: 10;
    top: -20px;
  }
}

// Sticky Grids: Full Screen Modals
.full-screen-modal .full-screen-modal-content {
  .page-content {
    overflow: auto;
  }

  .move-grid-header {
    position: sticky;
    z-index: 10;
    top: -20px;
  }

  .audit-processing-page {
    .page-content {
      overflow: initial;
    }

    .move-grid-header {
      top: 0;
    }
  }
}
