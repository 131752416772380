@import "../theme/theme";

.item-allocated-qty-pill {
  display: block;
  padding: 2px 4px;
  border: 1px solid $C_GREY_COOL_5;
  color: $C_GREY_COOL_5;
  border-radius: $S_BORDER_RADIUS;
  font-size: 10px;
  margin-left: 5px;
  white-space: nowrap;
}
