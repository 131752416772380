@import "../theme/theme";

.restock-eligible-pill {
  display: block;
  padding: 2px 4px;
  border: 1px solid $C_TEAL;
  border-radius: $S_BORDER_RADIUS;
  color: $C_TEAL;
  font-size: 10px;
  margin-left: 5px;
  white-space: nowrap;
  text-align: center;

  &:hover {
    border-color: $C_TEAL;
    background-color: $C_TEAL;
    color: $C_WHITE;
  }
}
