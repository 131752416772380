@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.future-stock-grid-container {
  margin: 2rem 0 0 2rem;
}
.future-stock-grid-container .future-stock-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.future-stock-grid-container .sticky-header {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
}
.future-stock-grid-container .future-stock-row {
  display: inline-flex;
  flex-direction: row;
}
.future-stock-grid-container .future-stock-row .empty-cell {
  flex: 1;
  min-width: 25rem;
  padding: 2rem;
  margin-right: 5px;
}
.future-stock-grid-container .future-stock-row .date-header-cell {
  flex: 1;
  min-width: 10rem;
  border-right: 5px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background-color: #BBC2CA;
  margin-top: -2px;
  padding: 1rem;
  font-weight: bold;
  text-align: center;
}
.future-stock-grid-container .future-stock-row .summary-row-label {
  flex: 2;
  min-width: 25rem;
  border: 1px solid #BBC2CA;
  box-sizing: border-box;
  position: sticky;
  left: 0;
  z-index: 99;
  border-radius: 0.2rem;
  background-color: #FDFDFD;
  padding: 1rem;
  margin-right: 5px;
}
.future-stock-grid-container .future-stock-row .summary-row-label a {
  cursor: pointer;
}
.future-stock-grid-container .future-stock-row .summary-row-label .item-block {
  float: left;
  width: 20rem;
}
.future-stock-grid-container .future-stock-row .summary-row-label .info-block {
  float: right;
}
.future-stock-grid-container .future-stock-row .summary-row-label .info-block .stock-quantity {
  border: 1px solid #BBC2CA;
  padding: 0.3rem;
  border-radius: 9999px;
  width: 2.5rem;
  height: 2rem;
  font-size: 1rem;
  text-align: center;
}
.future-stock-grid-container .future-stock-row .summary-row-label .info-block .expand-collapse-icon {
  padding-top: 0.5rem;
  text-align: center;
}
.future-stock-grid-container .future-stock-row .availability-cell {
  flex: 1;
  min-width: 5rem;
  border-right: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 0.2rem;
  color: #FFFFFF;
  opacity: 0.8;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
}
.future-stock-grid-container .future-stock-row .availability-cell:nth-child(2n+1) {
  border-right: 5px solid #FFFFFF;
}
.future-stock-grid-container .future-stock-row .row-label-cell {
  flex: 2;
  min-width: 25rem;
  box-sizing: border-box;
  position: sticky;
  left: 0;
  z-index: 99;
  border-radius: 0.2rem;
  background-color: #f7f8fb;
  margin-right: 5px;
}
.future-stock-grid-container .future-stock-row .row-label-cell .inner-label {
  border-radius: 0.2rem;
  background-color: #dce2e7;
  padding: 0.5rem 1rem;
  width: 80%;
  margin: 1px;
  float: right;
}

.future-stock-popover {
  padding: 1rem;
}
.future-stock-popover .grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.2rem;
}
.future-stock-popover .grid .label {
  font-weight: bold;
}
.future-stock-popover .cancel-reservation {
  text-align: right;
  color: #e26464;
  margin-top: 1rem;
  cursor: pointer;
}