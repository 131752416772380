@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.circular-progress-with-label {
  position: relative;
  height: 50px;
  width: 50px;
  margin-right: 5px;
}
.circular-progress-with-label .percentage-label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.circular-progress-with-label-and-tooltip {
  position: relative;
  height: 50px;
  width: 50px;
  margin-right: 5px;
}
.circular-progress-with-label-and-tooltip .percentage-label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}
.circular-progress-with-label-and-tooltip:hover {
  cursor: help;
}

.circular-progress-with-label-and-tooltip .tooltip {
  visibility: hidden;
}

.circular-progress-with-label-and-tooltip:hover .tooltip {
  border: #edf1f5 1px solid;
  border-radius: 4px;
  padding: 9px 9px 9px 9px;
  display: block;
  background-color: #FFFFFF;
  z-index: 100;
  left: 63px;
  margin: -33px;
  width: 210px;
  position: absolute;
  top: 10px;
  text-decoration: none;
  opacity: 0.85;
  visibility: visible;
}