@import "../../../../../../theme/theme";

.global-search-sub-nav {
  position: relative;
  bottom: 23px;
  margin: 0 -20px;
  align-content: center;
  align-items: flex-start;

  .global-search-sub-nav-title {
    display: inline-flex;
    margin-right: 20px;
    align-items: center;
  }

  .global-search-shortcuts {
    background-color: $C_GREY_WARM_1;

    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

.global-search-grid-container {
  margin: 20px 0;
  height: 200px;
  overflow-y: auto;
}
