@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.detail-page-component .title-block, .summaryDetail-page-component .title-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 23px;
}
.detail-page-component .title-block .color-block, .summaryDetail-page-component .title-block .color-block {
  width: 7px;
  min-height: 30px;
  margin-right: 10px;
}
.detail-page-component .title-block .title, .summaryDetail-page-component .title-block .title {
  font-size: 19px;
}
.detail-page-component .title-block .sub-title, .summaryDetail-page-component .title-block .sub-title {
  font-size: 16px;
  line-height: 12px;
  color: #989898;
  padding-top: 4px;
}