.schedule-search-page {
  overflow-y: hidden;

  .filter-sidebar {
    height: 91.5vh;
  }

  .fc-body {
    background-color: #fff !important;

    a {
      color: #fff !important;
    }
  }

  .results-container-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .schedule-content-container {
    overflow-y: hidden;
    padding-top: 0;
  }

}

.schedule-tool-bar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
