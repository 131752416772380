@import "../../../../../../theme/theme";

.ops-dash-header-container {
  display: flex;
  flex-direction: row;
}

.ops-dash-header-section {
  border: 1px solid $C_GREY_WARM_6;
  background-color: $C_WHITE;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.ops-dash-header {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  margin: 2rem;
  align-items: center;
}

.ops-dash-grid {
  margin-top: 2rem;
}
