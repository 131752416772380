@import "../../../theme/theme";

.source-to-case-add-shell {
  .filter-sidebar {
    z-index: 0;
  }

  .search-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0 10px;
    flex-shrink: 0;

    .batch-container {
      margin-right: 10px;
      flex-basis: 150px;

      .title-lbl {
        font-size: 10px;
        margin-bottom: -10px;
      }
    }

    .loading-spinner-container {
      margin-right: 16px;
      margin-top: 6px;
    }

    // Toggling barcode and text search
    .scan-barcode-container {
      flex-basis: 275px;
      width: 250px;

      .barcode-input {
        margin-top: 17px;
        margin-bottom: 10px;
        padding-bottom: 0;
      }
    }

    .text-search-container {
      margin-top: 0 !important;
      flex-basis: 275px;
      width: 250px;

      .align-items-center {
        margin-top: 18px;
      }
    }

    .scan-count {
      margin-left: auto;
      font-weight: 300;
      font-size: 16px;
      color: $C_GREY_COOL_1A;
    }
  }

  .msg-box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
  }

  .add-cell {
    &.highlighted {
      background-color: $C_GREY_WARM_2;
    }

    &.flash {
      background-color: $C_MINT_LIGHT;
    }

    &.fade {
      transition: background-color 0.3s linear;
    }

    .qty-col {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
