@import "../theme/theme";

.full-screen-img-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;

  padding: 50px;
  background-color: rgba(29, 29, 29, .85);

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  .lightbox-top-row {
    display: flex;
    width: 100%;
  }

  .lightbox-gallery-row {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    > .img-container {
      width: inherit;
      height: inherit;
      padding: 30px 60px 100px;
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      font-size: 1.2em;
      font-weight: 100;
      color: $C_WHITE;

      > img {
        object-fit: contain;
        width: inherit;
        height: inherit;
        cursor: pointer;
      }

      > .img-title-container {
        display: flex;
        justify-content: center;
        padding-top: 20px;
      }

      > .img-number-count {
        display: flex;
        justify-content: center;
        padding-top: 8px;
        margin-bottom: 100px;
      }
    }
  }
}
