@import "../../../../../theme/theme";

.move-docs-cell {
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;

  .pending {
    color: $C_RED;
    font-size: 11px;
    margin-left: 5px;
  }

  .right {
    min-width: 80px;

    .timestamp {
      color: $C_GREY_COOL_5;
      font-size: 11px;
    }
  }
}