@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.full-screen-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1300;
  background-color: #f7f8fb;
}
.full-screen-modal > .full-screen-modal-header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  height: 112px;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  padding: 0 23px;
  display: flex;
  align-items: center;
}
.full-screen-modal > .full-screen-modal-header.mint-header {
  background-color: #22bda9;
}
.full-screen-modal > .full-screen-modal-header.brand-header {
  background-color: var(--brand-color-lighter);
}
.full-screen-modal > .full-screen-modal-header.dark-grey-header {
  background-color: #353535;
}
.full-screen-modal > .full-screen-modal-header .title-left-view,
.full-screen-modal > .full-screen-modal-header .cancel-container,
.full-screen-modal > .full-screen-modal-header .title-container,
.full-screen-modal > .full-screen-modal-header .title-right-view {
  flex: 1;
}
.full-screen-modal > .full-screen-modal-header .title-left-view {
  display: flex;
  justify-content: flex-start;
}
.full-screen-modal > .full-screen-modal-header .title-right-view {
  display: flex;
  justify-content: flex-end;
}
.full-screen-modal > .full-screen-modal-header .cancel-container {
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.full-screen-modal > .full-screen-modal-header .title-container {
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.full-screen-modal > .full-screen-modal-header .title-container.no-subView {
  justify-content: center;
  height: 100%;
}
.full-screen-modal > .full-screen-modal-header .title-container .title {
  font-size: 22px;
  padding: 5px 0 5px;
}
.full-screen-modal > .full-screen-modal-header .title-container .subtitle {
  font-size: 16px;
}
.full-screen-modal > .full-screen-modal-content {
  position: absolute;
  top: 112px;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
}
.full-screen-modal > .full-screen-modal-content .full-height-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.full-screen-modal > .full-screen-modal-content .filter-transition {
  transition: padding-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.full-screen-modal > .full-screen-modal-content .filter-open {
  padding-left: 300px;
}
.full-screen-modal > .full-screen-modal-content .search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 10px;
}
.full-screen-modal > .full-screen-modal-content .search-header .disposition-container {
  margin-right: 10px;
  flex-basis: 150px;
}
.full-screen-modal > .full-screen-modal-content .search-header .disposition-container .title-lbl {
  font-size: 10px;
}
.full-screen-modal > .full-screen-modal-content .search-header .text-search-container {
  flex-basis: 275px;
}
.full-screen-modal > .full-screen-modal-content .search-header .text-search-container .title-lbl {
  font-size: 10px;
}
.full-screen-modal > .full-screen-modal-content .search-header .flex-basis-290 {
  flex-basis: 290px;
}
.full-screen-modal > .full-screen-modal-content .search-header .add-btns {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.full-screen-modal > .full-screen-modal-content .no-changes {
  margin-top: 10px;
  color: #BBC2CA;
  font-size: 22px;
  text-align: center;
}
.full-screen-modal > .full-screen-modal-content .padding-for-full-screen-modal-bottom {
  padding-bottom: 80px;
}

.full-screen-modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  z-index: 1000;
}
.full-screen-modal-footer .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}
.full-screen-modal-footer .left-el, .full-screen-modal-footer .center-el, .full-screen-modal-footer .right-el {
  display: flex;
  opacity: 1;
  flex: 1;
}
.full-screen-modal-footer .left-el {
  justify-content: flex-start;
  padding-left: 20px;
}
.full-screen-modal-footer .center-el {
  justify-content: center;
}
.full-screen-modal-footer .right-el {
  justify-content: flex-end;
  padding-right: 20px;
}