@import "../theme/theme";

.detail-page-component, .summaryDetail-page-component {

  .title-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $S_GUTTER;

    .color-block {
      width: 7px;
      min-height: 30px;
      margin-right: 10px;
    }

    .title {
      font-size: 19px;
    }

    .sub-title {
      font-size: 16px;
      line-height: 12px;
      color: #989898;
      padding-top: 4px;
    }
  }

}
