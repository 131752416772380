@import "../../../../../../theme/theme";

.process-picks-2-input {
  .active-container {
    text-align: center;
  }

  .create-pkg {
    text-align: right;
  }
}
