@import "../../../theme/theme";

.sourcing-matrix-2-page {

  .sourcing-matrix-header {
    .center {
      color: $C_WHITE;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .title {
        font-size: 18px;
        padding: 5px 0;
      }
    }
  }

  .page-content {
    padding: 20px 20px 80px;
  }

  .filter-sidebar .header {
    padding-right: 0;
  }

  .selected-profile-details {
    font-size: 12px;

    .filter-title {
      margin-top: 5px;
      font-weight: 500;
    }

    .filter-value {
      color: $C_GREY_COOL_1A;
    }
  }
}

.sourcing-matrix-header {
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  height: 98px;
  background-color: var(--brand-color-lighter);

  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
}
