@import "../../../../../theme/theme";

.bundle-suggestion-action-container {
  align-items: center;
  justify-content: space-between;
  min-width: 1190px;

  .select-menu {
    align-items: center;
    padding-right: 20px;
  }
}

.full-height-grid-container.bundle-suggestion-grid-container {
  position: inherit;
  padding-top: 20px;
}

.loading-suggestions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;

  .title {
    padding-bottom: 25px;
    color: $C_GREY_COOL_7;
    font-size: 24px;
    font-weight: 300;
  }
}

.bundle-suggestion-component {
  width: 1200px;
  background-color: $C_WHITE_2;
  border-radius: 5px;
  margin: 20px auto 40px;
  padding: 20px;
  box-shadow: 0 0 14px $C_GREY_WARM_4;

  .main-bundle-suggestion-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 10px;

    .main-title {
      display: flex;
      grid-column-start: 2;
      margin: auto;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: $C_TEXT;
    }

    .total-price {
      display: flex;
      margin-left: auto;
      font-weight: 400;
      color: $C_TEXT;

      .price {
        padding-left: 5px;
        font-weight: 400;
      }
    }
  }

  .suggestion-container {
    padding: 25px;
  }
}

.main-bundle-suggestion-header {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
}

.bundle-suggestion-header {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  .suggestion-title-block {
    display: flex;
  }

  .bundle-desc {
    margin: 0 10px;
    color: $C_GREY_COOL_7;
    font-weight: 300;
  }

  .left-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .price {
    font-weight: 200;
  }
}

.remaining-usage-container {
  padding: 25px;
}



