@import "../../../../../../../../../theme/theme";

.contents-cell {
  display: block !important;

  &.empty-line {
    opacity: 0.5;
  }

  .allocated-picks {
    color: red;
  }

  .child-row {
    overflow: hidden;
    border-left-width: 7px !important;
  }
}

.pkg-cell {
  .allocated-picks {
    color: red;
  }
}
