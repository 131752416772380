@import "../../../../../theme/theme";

.savedsearch-dashboard {

  .message-board-container {
    background-color: $C_WHITE;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid $C_GREY_WARM_2;
    max-height: 150px;
    overflow-y: auto;

    .header {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .actions-container {
    display: flex;
    height: 50px;

    .filter-types {
      display: flex;
      flex-direction: row;
      flex: 10;
      gap: 20px;
      justify-content: center;
      margin-bottom: 10px;

      button {
        border-radius: 99px !important;

        &:hover {
          background-color: var(--brand-color-lighter) !important;
          border: 2px solid var(--brand-color-lighter) !important;
          color: $C_WHITE !important;
          opacity: 0.5;
        }
      }
    }

    .configuration-container {
      flex: 1;
      align-self: end;
      font-size: 11px;
      color: $C_GREY_COOL_6;
      text-align: right;
    }
  }

  .group-container {
    .grouping {
      margin-bottom: 25px;

      .header {
        border-bottom: 1px solid $C_GREY_WARM_6;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .tile-container {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .tile {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: $C_WHITE;
          padding: 12px;
          border-radius: 4px;
          border: 1px solid $C_GREY_WARM_2;
          max-width: 150px;

          .title {
            text-align: center;
            margin-bottom: 10px;
            color: $C_GREY_COOL_4;
            font-size: 13px;
          }

          .description {
            align-self: flex-end;
            width: 15px !important;
            height: 15px !important;
            margin: -5px !important;
            padding: 0px !important;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80px;
            width: 80px;
            border-radius: 100%;
            color: white;
            font-size: 35px;
          }

          a .content:hover {
            outline: 1px solid var(--brand-color);
          }
        }
      }
    }
  }

  .move-grid .move-grid-body {
    border: none;
  }
}
