@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.receiving-input .barcode-footer {
  height: 48px;
}
.receiving-input .rfid-input-type {
  display: flex;
  flex: 1;
  align-items: center;
}
.receiving-input .last-scanned {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
}
.receiving-input .last-scanned .scan-details {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.receiving-input .active-container {
  flex: 1;
  text-align: center;
}
.receiving-input .create-pkg {
  flex: 1;
  text-align: right;
}
.receiving-input .kit {
  border: 1px dashed #CCCCCC;
  margin: 10px 10px 10px 0;
}
.receiving-input .kit > .info {
  padding: 0 10px;
}
.receiving-input .kit > .info > .header .item-type-pill.kit {
  border: none;
  background-color: #D6D6D6;
}
.receiving-input .kit > .info > .header .header-row,
.receiving-input .kit > .info > .header .inspection-failed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.receiving-input .kit > .info > .header .view-inspection {
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
}
.receiving-input .kit.inspected {
  border-style: solid;
}
.receiving-input .kit.inspected > .info > .header .item-type-pill.kit {
  background-color: #FF9889;
}
.receiving-input .kit.inspected > .info > .header .inspection-failed {
  font-size: 12px;
  color: #9F0620;
  cursor: pointer;
  margin-right: 10px;
}