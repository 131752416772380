@import "../../../../theme/theme";

.source-to-case-batch-component {
  margin-bottom: 10px;

  .batch-title {
    font-size: 18px;
    display: flex;
    align-items: center;

    .batch-name {
      margin-right: 10px;
    }

    .batch-settings {
      font-size: 12px;
      line-height: 12px;
      margin-right: 10px;
    }

    .batch-tote-select {
      margin-left: 5px;
      font-size: 12px;
    }
  }
}
