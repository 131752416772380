@import "../../../../../theme/theme";

.create-message-component {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px;

  .from {
    margin-bottom: calc($S_GUTTER/2);
  }

  textarea {
    border-radius: calc($S_BORDER_RADIUS/2);
  }
}
