@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.sourcing-matrix-2-page .sourcing-matrix-header .center {
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.sourcing-matrix-2-page .sourcing-matrix-header .center .title {
  font-size: 18px;
  padding: 5px 0;
}
.sourcing-matrix-2-page .page-content {
  padding: 20px 20px 80px;
}
.sourcing-matrix-2-page .filter-sidebar .header {
  padding-right: 0;
}
.sourcing-matrix-2-page .selected-profile-details {
  font-size: 12px;
}
.sourcing-matrix-2-page .selected-profile-details .filter-title {
  margin-top: 5px;
  font-weight: 500;
}
.sourcing-matrix-2-page .selected-profile-details .filter-value {
  color: #616161;
}

.sourcing-matrix-header {
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  height: 98px;
  background-color: var(--brand-color-lighter);
}
.sourcing-matrix-header .left {
  flex: 1;
}
.sourcing-matrix-header .right {
  flex: 1;
}