@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.add-org-unit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1300;
  background-color: #f7f8fb;
}
.add-org-unit > .header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 23px;
  height: 54px;
  background-color: #22bda9;
  color: #FFFFFF;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px, rgba(0, 0, 0, 0.117647) 0 1px 4px;
}
.add-org-unit > .content {
  position: absolute;
  top: 54px;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px 20px 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.add-org-unit > .content > .top {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.add-org-unit > .content > .top .add-lbl {
  margin-bottom: 4px;
  font-weight: bold;
  margin-top: 10px;
}
.add-org-unit > .content > .top .add-inventory-permissions {
  margin-top: auto;
  align-self: center;
}
.add-org-unit > .content > .bottom {
  border-top: 1px solid #D6D6D6;
  margin-bottom: 74px;
  margin-top: 15px;
}
.add-org-unit > .content > .bottom .tab-box {
  margin: 0;
}
.add-org-unit > .footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 54px;
  z-index: 1200;
  background-color: #22bda9;
  opacity: 0.9;
  border-top: 1px solid #D6D6D6;
  padding: 0 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}