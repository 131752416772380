@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.receipt-box-container {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCCCCC;
}
.receipt-box-container .shipment-container,
.receipt-box-container .kit-container,
.receipt-box-container .tray-container,
.receipt-box-container .loan-container,
.receipt-box-container .bin-container {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
}
.receipt-box-container .shipment-container > .info,
.receipt-box-container .kit-container > .info,
.receipt-box-container .tray-container > .info,
.receipt-box-container .loan-container > .info,
.receipt-box-container .bin-container > .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  padding: 10px;
  opacity: 0.8;
  min-height: 170px;
  min-width: 190px;
}
.receipt-box-container .shipment-container > .info > .line,
.receipt-box-container .kit-container > .info > .line,
.receipt-box-container .tray-container > .info > .line,
.receipt-box-container .loan-container > .info > .line,
.receipt-box-container .bin-container > .info > .line {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.receipt-box-container .shipment-container.selected > .info,
.receipt-box-container .kit-container.selected > .info,
.receipt-box-container .tray-container.selected > .info,
.receipt-box-container .loan-container.selected > .info,
.receipt-box-container .bin-container.selected > .info {
  border-color: #4A90E2;
  opacity: 1;
}
.receipt-box-container .shipment-container .counts,
.receipt-box-container .kit-container .counts,
.receipt-box-container .tray-container .counts,
.receipt-box-container .loan-container .counts,
.receipt-box-container .bin-container .counts {
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
}
.receipt-box-container .shipment-container .counts > *,
.receipt-box-container .kit-container .counts > *,
.receipt-box-container .tray-container .counts > *,
.receipt-box-container .loan-container .counts > *,
.receipt-box-container .bin-container .counts > * {
  margin-top: 2px;
}
.receipt-box-container .shipment-container .counts .unmatched-stocks,
.receipt-box-container .kit-container .counts .unmatched-stocks,
.receipt-box-container .tray-container .counts .unmatched-stocks,
.receipt-box-container .loan-container .counts .unmatched-stocks,
.receipt-box-container .bin-container .counts .unmatched-stocks {
  color: #4C5660;
}
.receipt-box-container .shipment-container .counts .extra-entry-needing-resolution,
.receipt-box-container .shipment-container .counts .unmatched-stocks-needing-resolution,
.receipt-box-container .shipment-container .counts .inspections-needing-attn,
.receipt-box-container .kit-container .counts .extra-entry-needing-resolution,
.receipt-box-container .kit-container .counts .unmatched-stocks-needing-resolution,
.receipt-box-container .kit-container .counts .inspections-needing-attn,
.receipt-box-container .tray-container .counts .extra-entry-needing-resolution,
.receipt-box-container .tray-container .counts .unmatched-stocks-needing-resolution,
.receipt-box-container .tray-container .counts .inspections-needing-attn,
.receipt-box-container .loan-container .counts .extra-entry-needing-resolution,
.receipt-box-container .loan-container .counts .unmatched-stocks-needing-resolution,
.receipt-box-container .loan-container .counts .inspections-needing-attn,
.receipt-box-container .bin-container .counts .extra-entry-needing-resolution,
.receipt-box-container .bin-container .counts .unmatched-stocks-needing-resolution,
.receipt-box-container .bin-container .counts .inspections-needing-attn {
  color: #9F0620;
}