@import "../../../../theme/theme";

.picks-component {
  height: 100%;

  .picks-grid-row {

    .home-location {
      font-size: 11px;
      color: #9c9c9c;
      line-height: 12px;
      margin-bottom: 5px;
    }

    .resp-party {
      font-size: 11px;
      color: #026847;
      line-height: 12px;
      margin-bottom: 5px;
    }

    .inventory-type {
      font-size: 11px;
      color: $C_TEAL;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 5px;
    }

    .case-event,
    .demand-loan,
    .consignment-loan {
      font-size: 11px;
      color: #9f0620;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 5px;
    }

    .ops-data {
      color: #0020d4;
      cursor: pointer;
    }

    .secondary-data {
      border-top: 1px solid $C_GREY_WARM_2;
      margin-top: 5px;
      padding-top: 2px;
      padding-bottom: 5px;

      .label {
        font-size: 10px;
        color: $C_GREY_COOL_1A;
        padding-right: 2px;
        font-weight: 500;
      }

      .value {
        font-size: 10px;
        color: $C_GREY_COOL_1A;
      }
    }
  }
}
