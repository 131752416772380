@import "../../theme/theme";

.construct-fullscreen-modal{
  overflow: hidden!important;
}
.constructs-modal-content {
  flex: 1;
  height: 100%;
  margin-bottom: 20px;
}

.construct-sub-nav-bar {
  min-height: 44px;
  border-bottom: 1px solid #d6d6d6!important;
  box-shadow: 0 0 5px #d6d6d6;
  padding: 0 20px;
}
.construct-components-container {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 125px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.construct-title-block {
  font-size: 18px;
  margin-right: 10px;
  display: flex;
  min-width: 600px;
  align-items: center;

  .description {
    margin: 0 10px;
    color: $C_GREY_COOL_7;
  }

}

.price-construct-component {
  margin-top: 20px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: $C_WHITE_2;
  border: 1px solid $C_GREY_WARM_6;
  padding: 12px 20px 23px 20px;
  width: 1000px;


  .construct-component-header {
    height: 36px;
    line-height: 39px;
    justify-content: space-between;
    margin-bottom: 3px;

    .left-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.disable-construct {
  opacity: .6;
}

.price-construct-component.disable-construct:hover {
  border: 1px solid $C_GREY_WARM_6;
}

.price-construct-component.added-construct {
  border: 1px solid $C_BLUE_LIGHT;
}

.price-construct-component:hover {
  border: 1px solid $C_BLUE_LIGHT;
  transition: 0.3s;
}

.children-button:hover {
  cursor: default;
}

.construct-grid-title {
  margin-top: 5px;
  margin-bottom: 5px;
}

.confidence-pill {
  height: 18px;
  padding: 6px;
  margin-right: 10px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  color: $C_WHITE;
  line-height: 8px;

  &.best {
    background-color: $C_QA_GREEN;
  }

  &.next {
    background-color: $C_BLUE_LIGHT;
  }

  &.last {
    background-color: $C_ORANGE;
  }
}

.constructs-empty-container {
  text-align: center;
  top: 35%;
  position: relative;
  transform: translateY(-50%);
}
.constructs-empty {
  display: inline-block;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 300px;
  border-radius: $S_BORDER_RADIUS;
  border: 1px solid $C_GREY_COOL_2;
  background-color: $C_GREY_COOL_3;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #E0E0E0;
  }
  .sub-title {
    font-size: 12px;
  }
}

.remaining-usage-component {
  margin-top: 20px;
  margin-bottom: 25px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.construct-review-container {
  display: grid;
  padding-bottom: 100px;
  height: 100%;
  overflow-y: scroll;

  .review-title {
    font-size: 23px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 5px;
    margin: auto;
    width: 1000px;
  }
}

.construct-review-container.grid-1 {
  grid-template-rows: 45px 1fr 45px 2fr;
}
.construct-review-container.grid-2 {
  grid-template-rows: 45px 1fr 1fr 45px 1.5fr;
}
.construct-review-container.grid-3 {
  grid-template-rows: 45px 1fr 1fr 1fr 45px 1fr
}

.usage-grid-container {
  overflow: hidden;
  display: grid;
  grid-template-columns: 480px 480px;
}

.usage-grid-container.no-remaining-usage {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
}

.right-padding {
  padding-right: 9px;
}

.remaining-usage-grid {
  padding-left: 9px;
}

.add-construct-disabled {
  position: relative;
}

.add-construct-disabled:hover .tooltip {
  z-index: 1000;
  visibility: visible;
  background-color: $C_WHITE;
  margin: 8px;
  padding: 12px;
  border: $C_GREY_WARM_4 1px solid;
  border-radius: 9px;
  width: 283px;
  color: $C_TEXT;
  cursor: help;
  transition: 0.3s;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  opacity: .9;
  border: $C_GREY_WARM_4 1px solid;
}

.right-content {
  display: flex;
  align-items: center;
}
