@import "../../../../../theme/theme";

.move-appbar-popover {
  max-height: 800px;
  width: 500px;
  padding: 15px;
  z-index: 100000;

  & > div {
    overflow-y: unset !important;
  }

  button {
    top: -10px;
    width: 35px !important;
    padding: 0 !important;

    &.close-button {
      align-self: flex-end;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    height: 25px;

    .title-container {
      display: flex;

      .title {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .read-unread-heading-toggle {
    font-size: 12px;
    margin-bottom: 10px;

    & a {
      color: $C_MINT !important;
    }
  }

  .notification-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid $C_GREY_COOL_3;

    &:last-child {
      padding-bottom: 0;
      border: none;
    }

    .new {
      text-transform: uppercase;
      color: $C_AQUA_LIGHT;
      font-size: 11px;
      font-weight: bold;
    }

    .unread {
      font-weight: bold;

      .warning {
        color: $C_ORANGE;
      }
    }

    .timestamp {
      color: $C_GREY_COOL_5;
      font-size: 11px;
    }
  }
}
