@import "../../../../../../../theme/theme";

.audit-detail-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  .container {
    flex-direction: column;

    .title {
      font-size: 18px;
    }

    .subtitle {
      font-size: 14px;
      line-height: 12px;
      color: $C_GREY_COOL_8;
    }
  }

  .color-block {
    width: 7px;
    height: 20px;
    margin-right: 10px;

    &.planning {
      color: $C_GOLD;
    }

    &.planned {
      color: $C_MINT;
    }

    &.open {
      color: $C_BLUE;
    }

    &.reconciling {
      color: $C_ORANGE;
    }

    &.applied {
      color: $C_GREEN;
    }

    &.unapplied {
      color: $C_RED;
    }
  }
}

.automation-running-overlay {
  cursor: default;
  height: 400px;
  width: 50%;
  margin: auto;
  opacity: .75;
  color: $C_GREY_COOL_5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 26px;
  font-weight: 300;
}

.automation-running-overlay .loader {
  margin: 10px;
}

.variance-pill-container {
  padding-top: 5px;
  margin-left: 20px;
}
