@import "../theme/theme";

.package-dimensions-lwh-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;

  .dimension {
    flex: .5;
    max-width: 50px;
  }

  .separator {
    padding: 0 5px;
  }

  .measurement-type {
    flex: 1;
    max-width: 135px;
    padding-left: 10px;
  }
}
