@import "../theme/theme";

.item-hold-status-pill-container {
  display: flex;
  flex-direction: column;

  .item-hold-status-pill {
    padding: 2px 4px;
    border-radius: $S_BORDER_RADIUS;
    font-size: 11px;
    background-color: $C_GREY_COOL_5;
    color: $C_WHITE;
    margin-right: auto;
  }
}
