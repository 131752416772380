@import "../../theme/theme";

.tree-item {

  .tree-item-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      cursor: pointer;
    }

    &.selection-disabled:not(.editable) {
      .name-lbl {
        cursor: default;
      }
    }

    &.no-children {
      padding-left: 25px;
    }

    .name-lbl {
      display: flex;
      cursor: pointer;
      user-select: none;
      padding: 0 10px;
      border-radius: 4px;
      margin-bottom: 5px;

      &.current-ou {
        background-color: $C_MINT;
        color: $C_WHITE;
      }
    }

    .subtitle {
      margin-left: 5px;
      font-size: 14px;
      color: $C_GREY_COOL_8;
    }

    .nested-item-count {
      margin-left: 3px;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      font-size: 11px;
      color: $C_WHITE;
      background-color: var(--brand-color);
      align-self: center;
      margin-bottom: 5px;
      text-align: center;
    }

    &.selected {
      .name-lbl {
        background-color: $C_GREY_COOL_1;
        color: $C_WHITE;

        &.original-link {
          background-color: var(--brand-color);
        }
      }
    }
  }

  .tree-children {
    padding-left: 15px;

    .loading {
      padding-left: 30px;
      font-size: 12px;
      color: $C_GREY_COOL_2;
    }
  }
}
