@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.choose-to-case-page {
  display: flex;
  flex: 1;
  justify-content: center;
}
.choose-to-case-page .choose-to-case {
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 400px;
}
.choose-to-case-page .choose-to-case .case-selector-container {
  flex: 1;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-to-case-page .choose-to-case .case-selector-container .case-selector {
  flex: 1;
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-shadow: 0 2px 2px 0 rgba(160, 167, 175, 0.14);
  padding: 60px;
  color: #868E97;
}
.choose-to-case-page .choose-to-case .case-selector-container div {
  flex: 1;
}
.choose-to-case-page .choose-to-case .info {
  margin: 20px auto 0;
  flex: 2;
  font-size: 16px;
  color: #868E97;
}