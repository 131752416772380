@import "../theme/theme";

.sub-nav-bar {
  z-index: $Z_SUB_NAV_BAR;
  min-height: $S_SUB_HEADER_HEIGHT;
  padding: 0 $S_GUTTER;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  font-size: 15px;

  &.bottom-border {
    border-bottom: 1px solid $C_BORDER_PRIMARY !important;
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &.no-bottom-border {
    border-bottom: none !important;
    box-shadow: none;
  }
}

.tab-sub-nav-bar {
  z-index: $Z_SUB_NAV_BAR;
  min-height: $S_SUB_HEADER_HEIGHT;
  padding-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  bottom: 12px;


  .right {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &.no-bottom-border {
    border-bottom: none !important;
    box-shadow: none;
  }
}
