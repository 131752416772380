@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.attr-note-cell {
  padding: 5px 0;
}
.attr-note-cell .note-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.attr-note-cell .from {
  color: #31373D;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attr-note-cell .from .date {
  color: var(--brand-color);
  font-weight: 400;
  font-size: 10px;
  margin-left: 5px;
}
.attr-note-cell .content {
  font-weight: 300;
  color: #4C5660;
  white-space: pre-wrap;
}
.attr-note-cell .message-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
}