@import "../../../../../../../theme/theme";

.audit-days-until-due {
  &-ok {
    @extend .audit-days-until-due-layout;
    color: $C_MINT;
  }

  &-coming-due {
    @extend .audit-days-until-due-layout;
    color: $C_ORANGE;
  }

  &-closed {
    display: none;
  }

  &-due-today, &-overdue {
    @extend .audit-days-until-due-layout;
    color: $C_RED;
  }
}

.audit-days-until-due-layout {
  display: flex;
  justify-content: flex-end;
  max-width: 45%;
}