@import "../../theme/theme";


.map-overlay {
  background-color: $C_WHITE_2;
  position: absolute;
  height: 138px;
  width: 330px;
  margin-top: 10px;
  margin-left: 10px;
  box-shadow: 0 0 10px $C_GREY_WARM_6;
  padding: 10px;
  z-index: 100000;

  .map-title {
    display: flex;
    align-items: center;
    color: $C_TEXT;
    font-size: 20px;
    padding-bottom: 5px;
  }

  .address-block {
    font-size: 14px;
  }
}
