@import "../../theme/theme";

.checkbox-multiselect {
  .multiselect-option {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    .option-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50px;
      opacity: 0.0;
      cursor: pointer;
      z-index: 1;
    }
  }
}