@import "../../../../../../../../theme/theme";

.audit-processing-page {
  .barcode-scanner, .search-header {
    width: 450px;
  }

  .barcode-audit-container {
    display: flex;

    .scan-count {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 2rem;
    }
  }

  .child-row {
    height: 40px;
  }
}
