.modal-form-field-label {
  color: #89929b;
  text-align: right;
  font-size: 13px;
  padding-top: 15px;
}
.modal-form-field-label.no-padding {
  padding-top: 10px;
}
.modal-form-field-label.text-left {
  justify-content: flex-start !important;
  text-align: left;
}