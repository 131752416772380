@import "../../../theme/theme";

.receiving-page {
  .receiving-header {
    z-index: $Z_APP_BAR;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    padding: 0 11px 0 8px;
    height: $S_HEADER_HEIGHT;
    position: relative;
    min-width: 730px;
    font-size: 17px;
    font-weight: 300;
    color: $C_WHITE_2;

    background-color: var(--brand-color-lighter);
    box-shadow: 0 3px 5px -3px $C_GREY_COOL_11;

    .center {
      display: flex;
      justify-content: center;
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .receiving-header > .center > .nav-bar-location {
    font-size: 17px;

    &.clickable {
      cursor: pointer;
    }
  }

  .turn-around {
    color: $C_DEV_RED;
  }

  .choose-location {
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 400px;

    .location-selector-container {
      flex: 1;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .location-selector {
        justify-content: flex-end;
        background-color: $C_WHITE;
        border: 1px solid $C_GREY_WARM_2;
        box-shadow: 0 2px 2px 0 rgba(160, 167, 175, 0.14);
        padding: 60px;
        color: $C_GREY_COOL_5;
      }

      div {
        flex: 1;
      }
    }

    //TODO: need to put in media queries for height here, flex 1 if short enough
    .info {
      margin-top: 20px;
      flex: 2;
      font-size: 16px;
      color: $C_GREY_COOL_5;
    }
  }

  .process-tracking-number {
    .tracking-number {
      font-size: 18px;
    }

    .clear-anchor {
      padding-left: 5px;
      font-size: 11px;
      text-transform: uppercase;
    }
  }

  .counts {
    text-align: left;
    margin-top: 10px;
    font-size: 12px;

    > * {
      margin-top: 2px;
    }

    .unmatched-stocks {
      color: $C_GREY_COOL_1;
    }

    .extra-entry-needing-resolution,
    .unmatched-stocks-needing-resolution,
    .inspections-needing-attn {
      color: $C_RED;
    }
  }

  .title-pill {
    padding: 2px 4px;
    color: #4c5660;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }

  .tag-anchor {
    color: $C_GREY_COOL_1 !important;

    &:hover {
      color: $C_GREY_COOL_1A !important;
      text-decoration: underline !important;
    }
  }

  .count-box-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-top: 24px;
    margin-bottom: 24px;

    .count-box {
      display: inline-block;
      padding: 24px 32px;
      margin-left: 6px;
      margin-right: 6px;
      border-width: 2px;
      border-style: solid;
      color: $C_BLACK;
      width: 150px;
      text-align: center;

      .count-box__quantity {
        font-size: 24px;
      }

      .count-box__label {
        font-size: 12px;
      }

      &.count-box--missing {
        background-color: $C_RED_LIGHT;
        border-color: $C_RED_PASTEL;
      }

      &.count-box--incomplete {
        background-color: $C_ORANGE_LIGHTER;
        border-color: $C_ORANGE;
      }

      &.count-box--reserved {
        background-color: $C_PURPLE_LIGHTER;
        border-color: $C_PURPLE;
      }

      &.count-box--scanned {
        background-color: $C_BLUE_LIGHTER;
        border-color: $C_BLUE;
      }
    }
  }
}
