@import "../../../../../../../../theme/theme";

.usage-changes-modal {
  .no-changes {
    color: $C_GREY_COOL_2;
    font-size: 22px;
    text-align: center;
  }

  .grid-title {
    font-weight: $F_WEIGHT_MEDIUM;
    font-size: 22px;
    margin-top: $S_GUTTER;

    &:first-child {
      margin-top: 0;
    }
  }

}