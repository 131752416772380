@import "../theme/theme";

.airs-trained-pill-container {
  display: inline;

  .airs-trained-pill {
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 11px;
    background-color: $C_AQUA_LIGHT;
    color: $C_WHITE;
    margin-right: auto;
  }
}
