@import "../../../../../../theme/theme";

.file-name-container {
  display: flex;
  align-items: center;

  .img-container {
    display: inline-block;
    margin: 10px;
    max-width: 150px;
    max-height: 150px;
    border:1px solid white;

  }

  .img-container:hover {
    border: var(--brand-color-lighter) solid 1px;
    cursor: pointer;
  }
}
