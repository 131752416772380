.messages-list-page {
  background-color: #f7f7f7;

  .grid-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 44px;
    bottom: 0px;
    width: 100%;
    background-color: #f7f7f7;
  }
}