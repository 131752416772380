.inventory-location-badge {
  border-radius: 2px;
  margin-left: 5px;
  padding: 1px 3px;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
}
.inventory-location-badge.facility {
  background-color: #ccc;
}
.inventory-location-badge.rep {
  background-color: #88cc8f;
}
.inventory-location-badge.team {
  background-color: #7ba3cc;
}
.inventory-location-badge.in-transit {
  background-color: #cc453f;
}