@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.bundle-suggestion-action-container {
  align-items: center;
  justify-content: space-between;
  min-width: 1190px;
}
.bundle-suggestion-action-container .select-menu {
  align-items: center;
  padding-right: 20px;
}

.full-height-grid-container.bundle-suggestion-grid-container {
  position: inherit;
  padding-top: 20px;
}

.loading-suggestions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
.loading-suggestions-container .title {
  padding-bottom: 25px;
  color: #979797;
  font-size: 24px;
  font-weight: 300;
}

.bundle-suggestion-component {
  width: 1200px;
  background-color: #FDFDFD;
  border-radius: 5px;
  margin: 20px auto 40px;
  padding: 20px;
  box-shadow: 0 0 14px #edf1f5;
}
.bundle-suggestion-component .main-bundle-suggestion-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 10px;
}
.bundle-suggestion-component .main-bundle-suggestion-header .main-title {
  display: flex;
  grid-column-start: 2;
  margin: auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #31373D;
}
.bundle-suggestion-component .main-bundle-suggestion-header .total-price {
  display: flex;
  margin-left: auto;
  font-weight: 400;
  color: #31373D;
}
.bundle-suggestion-component .main-bundle-suggestion-header .total-price .price {
  padding-left: 5px;
  font-weight: 400;
}
.bundle-suggestion-component .suggestion-container {
  padding: 25px;
}

.main-bundle-suggestion-header {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
}

.bundle-suggestion-header {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.bundle-suggestion-header .suggestion-title-block {
  display: flex;
}
.bundle-suggestion-header .bundle-desc {
  margin: 0 10px;
  color: #979797;
  font-weight: 300;
}
.bundle-suggestion-header .left-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bundle-suggestion-header .price {
  font-weight: 200;
}

.remaining-usage-container {
  padding: 25px;
}