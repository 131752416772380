@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.ops-dash-header-container {
  display: flex;
  flex-direction: row;
}

.ops-dash-header-section {
  border: 1px solid #CCCCCC;
  background-color: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}
.ops-dash-header-section:not(:last-child) {
  margin-right: 1rem;
}

.ops-dash-header {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  margin: 2rem;
  align-items: center;
}

.ops-dash-grid {
  margin-top: 2rem;
}