@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.move-left-nav-container > div {
  box-shadow: none !important;
  transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
}

.move-left-nav-container.minimized > div {
  width: 60px !important;
  overflow: hidden !important;
  transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.move-left-nav-container.minimized > div > span > div > div > button {
  opacity: 0;
}

.move-left-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  background-color: #181818;
  scrollbar-color: #616161 #181818;
  font-weight: 300;
}
.move-left-nav .brand {
  margin: 5px 10px 7px 13px;
  height: 38px;
  cursor: pointer;
}
.move-left-nav .brand.dev {
  filter: hue-rotate(325deg);
}
.move-left-nav .brand.qa {
  filter: hue-rotate(30deg);
}
.move-left-nav .brand img {
  height: 34px;
  margin: 6px 0 20px;
}
.move-left-nav .menu {
  flex: 1;
  overflow-y: auto;
}
.move-left-nav .menu > div > div > div {
  margin: 2px 6px;
}
.move-left-nav .menu > div > div > div span {
  height: 42px;
}
.move-left-nav div > span > div > div > button {
  padding: 0 !important;
  opacity: 1;
  display: block !important;
  transition: display 450ms, opacity 450ms !important;
  right: -5px !important;
}
.move-left-nav svg {
  color: #FDFDFD !important;
}
.move-left-nav .list-item-text {
  text-wrap: nowrap;
}
.move-left-nav .sub-menu-item-container {
  display: flex;
}
.move-left-nav .sub-menu-item-container > div:nth-child(2) {
  width: 100%;
}
.move-left-nav .nested-sub-menu-parent-container {
  display: flex;
  margin: 6px 0 0 10px;
}
.move-left-nav .nested-sub-menu-parent-container > div {
  width: 100%;
}
.move-left-nav .nested-sub-menu-item-container > div:nth-child(2) {
  width: 100%;
}

div.move-left-nav-container.minimized > div > div > div.menu > div > div > div > div > span > div > div > button {
  display: none !important;
  opacity: 0 !important;
}