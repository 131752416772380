@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.full-screen-img-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  padding: 50px;
  background-color: rgba(29, 29, 29, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.full-screen-img-lightbox .lightbox-top-row {
  display: flex;
  width: 100%;
}
.full-screen-img-lightbox .lightbox-gallery-row {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.full-screen-img-lightbox .lightbox-gallery-row > .img-container {
  width: inherit;
  height: inherit;
  padding: 30px 60px 100px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  font-weight: 100;
  color: #FFFFFF;
}
.full-screen-img-lightbox .lightbox-gallery-row > .img-container > img {
  object-fit: contain;
  width: inherit;
  height: inherit;
  cursor: pointer;
}
.full-screen-img-lightbox .lightbox-gallery-row > .img-container > .img-title-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.full-screen-img-lightbox .lightbox-gallery-row > .img-container > .img-number-count {
  display: flex;
  justify-content: center;
  padding-top: 8px;
  margin-bottom: 100px;
}