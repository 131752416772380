@import "../../../../../../theme/theme";

/*
Ops Calendar
 */

.opsCalendar {
  height: 100%;
}

/*
Headers
 */

.durationHeader {
  text-align: center;
  font-weight: bold;
  font-size: 3rem;

  .durationText {
    display: inline-block;
    width: 25rem;
  }

  button {
    background: 0;
    border: 0;
    padding: 0;
    color: $C_GREY_COOL_5;
    cursor: pointer;
    outline: 0;
  }
}

.yearHeader {
  text-align: center;
  font-size: 1.5rem;
  color: $C_GREY_COOL_7
}

.dayHeader {
  text-align: center;
}

/*
Month Grid
 */

.dayHeader,
.dateGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.dateGrid {
  grid-auto-rows: 1fr;
  height: 95%;
  border-top: 1px solid $C_GREY_COOL_3;
  border-left: 1px solid $C_GREY_COOL_3;

  .dateCellToday {
    background-color: $C_MINT_LIGHTER;
  }

  .dateCellGrey {
    color: $C_GREY_COOL_7;
    background-color: $C_GREY_WARM_2;
  }

  .dateCell {
    background-color: $C_WHITE;
  }

  .dateCellToday,
  .dateCellGrey,
  .dateCell {
    padding: 1rem;
    height: 100%;
    border-bottom: 1px solid $C_GREY_COOL_3;
    border-right: 1px solid $C_GREY_COOL_3;

    &:hover {
      background-color: $C_MINT_LIGHTEST;
      cursor: pointer;
    }

    .dateKey {
      text-align: right;
    }

    .dateValue {
      margin: auto;
      padding: 1rem 0;
      font-size: 3rem;
      text-align: center;
    }
  }
}

/*
Week Grid
 */

.weekGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  height: 100%;
  width: 100vw;
}

.isToday,
.weekCellContainer {
  margin: 5px;
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid $C_GREY_COOL_3;
  width: 45rem;
}

.isToday {
  background-color: $C_MINT_LIGHTER;
}

.weekCellContainer {
  background-color: $C_WHITE;
}

.weekColumnHeader {
  display: inline-block;
  margin: 1rem auto;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.weekColumn {
  margin-left: 2px;
  margin-bottom: 2px;
}

.weekRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
