@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.tab-box {
  margin: 20px 0;
}
.tab-box > div:first-child {
  background-color: #e8ebed !important;
  border-radius: 5px 5px 0 0;
}
.tab-box > div:first-child button {
  font-weight: 400 !important;
  text-transform: none !important;
  border-radius: 2px;
  color: #4C5660 !important;
  z-index: 1;
}
@media (max-width: 750px) {
  .tab-box > div:first-child {
    display: flex;
    flex-wrap: wrap;
  }
  .tab-box > div:first-child > button {
    width: auto !important;
    margin: 0 10px !important;
  }
  .tab-box > div:nth-child(2) {
    display: none !important;
  }
}

.tab-content {
  min-height: 100px;
  padding-top: 18px;
  overflow-x: clip;
}
.tab-content.detail-page {
  position: relative;
  bottom: 48px;
  margin-bottom: -48px;
}
.tab-content .child-margin-right > * {
  margin-right: 10px !important;
}
.tab-content .child-margin-right > *:last-child {
  margin-right: 0 !important;
}