@import "../theme/theme";

.shipment-status-pill {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  margin-left: 3px;
  color: $C_WHITE;

  &.pending {
    background-color: $C_SALMON;
  }

  &.awaiting-release {
    background-color: $C_PURPLE_LIGHT;
  }

  &.released {
    background-color: $C_BLUE_LIGHT;
  }

  &.assigned {
    background-color: $C_GOLD;
  }

  &.ready-to-ship {
    background-color: $C_MINT_LIGHT;
  }

  &.shipped {
    background-color: $C_AQUA;
  }

  &.delivered {
    background-color: $C_QA_GREEN;
  }

  &.complete {
    background-color: $C_LAVENDER;
  }

  &.exception {
    background-color: $C_ORANGE_BURNT_DARK;
  }

  &.review {
    background-color: $C_TAN;
  }

  &.cancelled {
    background-color: $C_GREY_COOL_5;
  }
}
