@import "../../theme/theme";

.barcode-input {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 10px;

  .scan-heading {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .scan-instructions {
      font-size: 20px;
      font-weight: 500;
    }

    .removal-options {
      display: flex;
      flex-direction: row;
      align-self: center;
      gap: 20px;
      width: 350px;
    }
  }

  .barcode-scanner-container {
    display: flex;
    height: 34px;
  }

  .scanning-text {
    display: flex;
    align-items: center;
  }
}

.holds-warning {
  color: $C_RED;
  font-size: 16px;
  margin: 20px 0;
}
