@import "../theme/theme";

.urgency-pill {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  color: $C_WHITE;

  &.late {
    background-color: $C_RED;
  }

  &.today {
    background-color: $C_MINT;
  }

  &.tomorrow {
    background-color: $C_BLUE;
  }

  &.one-week,
  &.future {
    background-color: $C_GREY_COOL_1;
  }
}