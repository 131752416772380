@import "../theme/theme";

.picks-qty-pill {
  padding: 1px 3px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  font-weight: 500;
  color: $C_WHITE;
  margin-right: 5px;

  &.assigned {
    background-color: $C_BLUE;
  }

  &.unassigned {
    background-color: $C_SALMON;
  }

  &.complete {
    background-color: $C_GREEN;
  }
}
