@import "../theme/theme";

.credit-hold-pill {
  padding: 2px 4px 2px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  color: $C_WHITE;
  margin-left: 5px;
  margin-top: -2px;
  background-color: $C_RED;
}
