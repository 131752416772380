@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.manifest-shipment .content-header,
.manifest-shipment .packages-grid {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.manifest-shipment .content-header {
  margin-bottom: 25px;
}
.manifest-shipment .content-header .address-validation {
  margin-left: 10px;
  font-size: 16px;
  color: #FFAC00;
}
.manifest-shipment .shipped {
  margin-top: 25px;
}
.manifest-shipment .shipped .title {
  font-size: 18px;
}
.manifest-shipment .packages-grid .package,
.manifest-shipment .packages-grid .tracking,
.manifest-shipment .packages-grid .rate {
  flex: 1;
}
.manifest-shipment .packages-grid .dimensions .predefined-box {
  flex: 1;
}
.manifest-shipment .packages-grid .dimensions .dimension-input-toggle {
  font-size: 11px;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 25px;
}
.manifest-shipment .packages-grid .tracking {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  text-transform: uppercase;
}