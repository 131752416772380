@import "../../../../../../../../theme/theme";

.usage-construct-component {
  margin-left: 20px;
  margin-bottom: 20px;

  .construct-title {
    font-size: 18px;
    display: inline-block;
    font-weight: 400;
    margin-right: 10px;

    .remove-link {
      color: $C_RED;
      font-size: 10px;
      padding-left: 5px;
      display: inline-block;
    }
  }

  .construct-desc {
    display: inline-block;
    font-size: 18px;
    font-weight: 200;
    color: $C_GREY_COOL_2;
    margin-top: -5px;
    margin-bottom: 5px;
    margin-right: 10px;
    margin-left: 8px;
  }

  .construct-price {
    font-weight: 300;
    font-size: 17px;
    display: inline-block;
  }
}
