@import "../theme/theme";

.item-type-pill {
  width: 50px;
  height: 20px;
  padding: 3px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  color: $C_WHITE;

  &.kit {
    background-color: $C_SALMON;
  }

  &.tray {
    background-color: $C_MINT;
  }

  &.set {
    background-color: $C_AQUA_LIGHT;
  }

  &.tote, &.pack, &.package, &.pkg, &.loan, &.loc, &.bin, &.asset {
    padding: 2px;
    color: $C_GREY_COOL_1;
    border: 1px solid $C_GREY_WARM_6;
    background-color: $C_WHITE;
  }

  &.loose {
    background-color: $C_GREY_COOL_2;
  }

  &.piece {
    background-color: $C_ORANGE;
  }

  &.bundle {
    background-color: $C_PURPLE_LIGHT;
  }

  &.fee {
    background-color: $C_LAVENDER;
  }

  &.small {
    width: fit-content;
    height: fit-content;
    padding: 2px 4px 2px;
    border-radius: 4px;
  }
}
