@import "../theme/theme";

.item-tag-number-pill-container {
  display: flex;

  .item-tag-number-pill {
    padding: 2px 4px;
    border-radius: $S_BORDER_RADIUS;
    border: 1px solid $C_GREY_COOL_5;
    color: $C_GREY_COOL_5;
    font-size: 11px;
    white-space: nowrap;
  }
}
