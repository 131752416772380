@import "../theme/theme";

.circular-progress-with-label {
  position: relative;
  height: 50px;
  width: 50px;
  margin-right: 5px;

  .percentage-label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 11px;
  }
}

.circular-progress-with-label-and-tooltip {
  position: relative;
  height: 50px;
  width: 50px;
  margin-right: 5px;

  .percentage-label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 11px;
  }

  &:hover {
    cursor: help;
  }
}

.circular-progress-with-label-and-tooltip .tooltip {
  visibility: hidden;
}

.circular-progress-with-label-and-tooltip:hover .tooltip {
  border: $C_GREY_WARM_4 1px solid;
  border-radius: 4px;
  padding: 9px 9px 9px 9px;
  display: block;
  background-color: $C_WHITE;
  z-index: 100;
  left: 63px;
  margin: -33px;
  width: 210px;
  position: absolute;
  top: 10px;
  text-decoration: none;
  opacity: 0.85;
  visibility: visible;
}
