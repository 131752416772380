@import "../../../theme/theme";

.attr-note-cell {
  padding: 5px 0;

  .note-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .from {
    color: $C_TEXT;
    @include RobotoRegular;
    font-size: 15px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;

    .date {
      color: var(--brand-color);
      font-weight: $F_WEIGHT_REGULAR;
      font-size: 10px;
      margin-left: 5px;
    }
  }

  .content {
    font-weight: $F_WEIGHT_LIGHT;
    color: $C_GREY_COOL_1;
    white-space: pre-wrap;
  }

  .message-badge {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
