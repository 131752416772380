.item-chooser-search-cell {
  .item-header {
    display: flex;
    flex-direction: column;

    .child-lines-toggle {
      margin-top: 3px;
      font-size: 11px;
      text-transform: uppercase;
    }
  }
}

// Child Line - Offset to Border left Color
.move-grid-body {
  .child-row {
    .item-chooser-search-cell {
      .item-column {
        margin-right: 0 !important;
      }
    }
  }
}