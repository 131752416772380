@import "../../../../../theme/theme";

.process-picks-page {
  .page-content {
    padding-top: 0;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #f7f7f7;
    z-index: 100;
    padding-top: 20px;
    padding-bottom: 20px;

    .last-scanned {
      font-size: 16px;
      margin-top: 5px;
    }
  }
}