@import "../../theme/theme";

.product-chooser {
  overflow: revert;

  .add-shell {
    height: 100%;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 410px;
    min-width: 1138px;

    .choose-items-container {
      padding: 2px 20px 20px 20px;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      overflow: auto;

      .top-bar {
        display: flex;
        justify-content: space-between;
      }
    }

    .no-bottom-padding {
      padding-bottom: 0;
    }
  }

  .selected-items-container {
    height: calc(50vh - 105px);
    min-height: calc(50vh - 105px);
    min-width: 1000px;
    padding: 5px 20px 20px 20px;


    &.expanded {
      height: calc(100vh - 150px);
    }

    .selected-items-grid-container {
      height: 100%;
    }
  }

  .half-height {
    height: 50%;
    min-height: 140px;
  }

  .full-height {
    height: 100%;
  }

  .selected-items-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    min-width: 1100px;

    .filter-section {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      max-width: 900px;
      align-items: center;
    }

    .item-actions {
      display: flex;
      align-items: center;
    }
  }

  .selected-items-title {
    font-size: 1.5em;
    font-weight: 500;
    width: 230px;
  }

  .container-contents-title {
    padding: 10px 20px 10px;
    color: $C_TEXT;
    font-size: 17px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .container-content {
    padding: 0 20px 20px 20px;
    overflow: auto;
    flex: 1;
  }

  .no-bottom-padding {
    padding-bottom: 0;
  }

  .item-desc {
    font-size: .8em;
    color: $C_GREY_COOL_5;

  }

  .product-chooser-search-cell {
    &.highlighted {
      background-color: $C_GREY_WARM_6;
    }

    &.flash {
      background-color: $C_BLUE_LIGHT;
      color: #444 !important;
    }

    &.fade {
      transition: background-color 0.45s linear;
    }

    .inventory-processing-info {
      .label {
        color: $C_RED !important;
      }

      &.green-label {
        .label {
          color: #3e7800 !important;
        }
      }
    }

    .item-number {

      .explore {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .description {
      color: $C_GREY_COOL_8;
      font-size: 14px;
      font-weight: $F_WEIGHT_LIGHT;

      &.qty-in-standard {
        font-weight: $F_WEIGHT_REGULAR;
      }

      p {
        margin: 0;
      }
    }
  }

  .review-items-container {
    height: 100%;
    overflow-y: auto;
    padding: 0 40px 80px 40px;
    min-width: 1020px;

    .no-changes {
      margin-top: 20px;
      color: $C_GREY_COOL_2;
      font-size: 22px;
      text-align: center;
    }

    .grid-title {
      font-weight: $F_WEIGHT_MEDIUM;
      font-size: 22px;
      margin-top: 10px;
    }
  }

  .review-component-title {
    padding-top: 9px;
    padding-bottom: 4px;
    color: $C_TEXT;
    font-size: 19px;
    display: grid;
    min-width: 895px;
    grid-template-columns: 50px 1fr 100px;

    &.catalog {
      align-items: center;
      padding: 7px 0;
    }

    &.disabled {
      opacity: .4;
    }

    .catalog-title {
      display: flex;
      align-items: center;
      font-weight: 200;
    }

    .item-title {
      color: $C_GREY_COOL_1;
      font-weight: 300;
      grid-column-start: 2;

      .main-title-block {
        display: flex;
      }

      .item-desc {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .qty-buttons {
    display: flex;
    position: relative;
    align-items: center;
  }

  .nested-bundle-qty-buttons {
    display: flex;
    position: relative;
    align-items: center;
  }

  .action-buttons {
    justify-self: end;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    left: 5px;
  }

  .review-component-container {
    padding-bottom: 20px;

    .class-header {
      background-color: $C_BLUE_PASTEL;
      color: $C_WHITE;
      text-align: center;
      font-weight: 100;
      font-size: 15px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      min-width: 894px;
      border-radius: $S_BORDER_RADIUS $S_BORDER_RADIUS 0 0;

      .class-title {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 36px;
        grid-column-start: 2;

        &.nested-bundle {
          padding-right: 20px;
        }
      }

      .toggle-expand {
        display: flex;
        align-items: center;
        color: $C_WHITE;
        font-size: 10px;
        font-weight: 200;
      }
    }
  }

  .catalog-items-container {

    padding: 40px 20px;
    min-width: 1056px;

    .catalog-title {
      padding-top: 7px;
      color: $C_GREY_COOL_1;
      font-size: 19px;
      display: flex;
      justify-content: center;
      font-weight: 200;
      margin: 7px;
    }

    &.background {
      padding: 0 20px;
      background-color: $C_WHITE_2;
      border-radius: 6px;
      border: solid 1px $C_BORDER_PRIMARY;
      margin-top: 18px;
      box-shadow: 0 0 10px $C_GREY_WARM_3;
      min-width: 1024px;

    }
  }

  .bundle-component-container {
    padding: 0 20px;
    border-radius: $S_BORDER_RADIUS;
    border: solid 1px $C_BORDER_PRIMARY;
    background-color: $C_WHITE_2;
    margin-top: 15px;
    min-width: 1076px;

    &:hover {
      box-shadow: 0 0 10px $C_GREY_WARM_3;
    }

    .grid-container {
      &.disabled {
        opacity: .4;
        pointer-events: none;
      }
    }
  }

  .nested-bundle-component-container {
    padding: 0 20px;
    margin-bottom: 20px;
    border-radius: $S_BORDER_RADIUS;
    background-color: $C_GREY_WARM_7;
    border: solid 1px $C_GREY_WARM_2;
    font-weight: 300;

    &:hover {
      border-color: $C_GREY_WARM_3;
    }

    .nested-bundle-title {
      padding-top: 9px;
      padding-bottom: 4px;
      color: $C_TEXT;
      font-size: 19px;
      display: grid;
      min-width: 895px;
      grid-template-columns: 50px 1fr 82px;

      .item-title {
        grid-column-start: 2;

        .main-title-block {
          display: flex;
          justify-content: space-between;
        }
      }

    }

    .grid-container {
      &.disabled {
        opacity: .4;
        pointer-events: none;
      }
    }
  }

  .empty-message-container {
    text-align: center;
    padding-top: 130px;

    .items-empty {
      display: inline-block;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      width: 300px;
      border-radius: $S_BORDER_RADIUS;
      border: 1px solid $C_GREY_COOL_2;
      background-color: $C_GREY_COOL_3;
      text-align: center;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: #E0E0E0;
      }

      .title {
        font-size: 22px;
      }

      .sub-title {
        font-size: 16px;
        color: $C_GREY_COOL_1A;
        padding-top: 7px;
        font-weight: 200;
      }
    }
  }

}
