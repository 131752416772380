@import "../theme/theme";

.transaction-types-container {
  .advanced-types {
    margin-top: 25px;

    .title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 15px;
      color: $C_BLUE_GREY;
    }
  }
}
