@import "../theme/theme";

.loading-ellipsis {
  position: absolute;
  left: 50%;
  top: 50%;

  &.active {
    //visibility: visible;

    > span {
      width: 1em;
      height: 1em;
      background-color: $C_WHITE;
      border-radius: 50%;
      display: inline-block;
      -webkit-animation: bouncedelay 1.5s infinite ease-in-out;
      animation: bouncedelay 1.5s infinite ease-in-out;
      /* Prevent first frame from flickering when animation starts */
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      margin-left: 2px;
    }

    .bounce1 {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }
  }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(.5);
    -webkit-transform: scale(.5);
    opacity: 0;
  }
}