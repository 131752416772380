@import "../theme/theme";

.expiration-level-pill {
  padding: 1px 3px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  font-weight: 500;
  color: $C_WHITE;
  margin-right: auto;

  &.large {
    padding: 4px 8px;
  }

  &.red {
    background-color: $C_DEV_RED;
    border: 1px solid $C_DEV_RED;
  }

  &.orange {
    background-color: $C_ORANGE_BURNT;
    border: 1px solid $C_ORANGE_BURNT;
  }

  &.light_orange {
    background-color: $C_ORANGE_LIGHT;
    border: 1px solid $C_ORANGE_BURNT;
    color: $C_ORANGE_BURNT;
  }

  &.yellow {
    background-color: $C_YELLOW;
    border: 1px solid $C_YELLOW_ORANGE;
    color: $C_ORANGE_BURNT;
  }

  &.blue {
    background-color: $C_BLUE;
    border: 1px solid $C_BLUE;
  }

  &.green {
    background-color: $C_GREEN;
    border: 1px solid $C_GREEN;
  }

  &.spacing {
    margin-top: 5px;
  }

  &.no-color {
    font-size: 12px;
    font-weight: 400;
    color: $C_BLACK;
  }
}
