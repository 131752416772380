@import "../../../../../../theme/theme";

.usage-tab {

  .order-block {
    margin-bottom: 20px;

    .order-block-header {

      .order-name {
        font-size: 20px;
      }

      .bill-to {
        font-size: 12px;
        color: $C_GREY_COOL_2;
        margin-left: 5px;
      }
    }

    .construct-block {
      margin-left: 20px;
      margin-top: 20px;

      .construct-header-block {
        .construct-title {
          font-size: 16px;
        }

        .construct-desc {
          font-size: 12px;
          color: $C_GREY_COOL_2;
          margin-left: 5px;
        }
      }
    }

    .order-block-header,
    .construct-header-block {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 5px;

      > .left-col {
        display: flex;
        align-items: baseline;
        flex: 2;
      }

      > .right-col {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: flex-end;

        > .price-totals {
          display: flex;
          flex-direction: column;
          flex: 1;

          .line-item {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .label {
              display: flex;
              flex: 4;
              justify-content: flex-end;
              margin-right: 3px;
            }

            .value {
              display: flex;
              flex: 1;
              justify-content: flex-start;
            }
          }

          .bold {
            font-weight: 600;
          }
        }
      }
    }
  }
}
