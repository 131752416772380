@import "../../../../../../../../theme/theme";

.auto-add-control {
  padding: $S_BORDER_RADIUS * 2;
  margin-top: 10px;
  font-size: 18px;

  .medium {
    font-weight: 600;
    margin-left: 5px;
    margin-right: 10px;
  }
}