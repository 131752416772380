@import "../../../../../theme/theme";

.message-component {
  .from {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2px;

    .name {
      color: $C_BLACK;
      font-size: 16px;
      font-weight: $F_WEIGHT_BOLD;
    }
  }

  .avatar-container,
  .time {
    flex-basis: 68px;
    flex-shrink: 0;
    text-align: right;
    padding-right: 10px;
  }

  .message {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    margin-top: 3px;

    &:hover {
      background-color: $C_GREY_WARM_2;

      .time {
        visibility: visible;
      }
    }
  }

  .time {
    padding-top: 5px;
    color: $C_GREY_COOL_1;
    font-size: 11px;
    font-weight: $F_WEIGHT_LIGHT;
    visibility: hidden;
  }

  .content {
    flex: 1;
    color: $C_TEXT;
    font-size: 15px;
    font-weight: $F_WEIGHT_LIGHT;
    white-space: pre-wrap;
  }
}
