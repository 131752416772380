@import "../../../../../../theme/theme";

.location-history-cell {
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;

  .right {
    width: 80px;

    .timestamp {
      color: $C_GREY_COOL_2;
      font-size: 11px;
    }

    .device {
      color: $C_MINT;
      font-size: 11px;
    }
  }
}
