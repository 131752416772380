@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.process-picks-2-page .sub-nav-bar {
  border-bottom: solid 1px #D6D6D6;
}
.process-picks-2-page .content-header .actions-btn {
  float: right;
}
.process-picks-2-page .detail-panel > .body {
  padding: 10px 20px;
}
.process-picks-2-page .container-title {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #4C5660;
}
.process-picks-2-page .scan-section,
.process-picks-2-page .actions-section,
.process-picks-2-page .issues-section,
.process-picks-2-page .picks-section,
.process-picks-2-page .success-section {
  margin-bottom: 5px;
  margin-top: 20px;
}
.process-picks-2-page .scan-section:first-child,
.process-picks-2-page .actions-section:first-child,
.process-picks-2-page .issues-section:first-child,
.process-picks-2-page .picks-section:first-child,
.process-picks-2-page .success-section:first-child {
  margin-top: 0;
}
.process-picks-2-page .picks-section,
.process-picks-2-page .issues-section {
  margin-top: 25px;
}
.process-picks-2-page .picks-section .header,
.process-picks-2-page .issues-section .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.process-picks-2-page .picks-section .header .title,
.process-picks-2-page .issues-section .header .title {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.process-picks-2-page .picks-section .header .title .errors,
.process-picks-2-page .issues-section .header .title .errors {
  color: #9F0620;
}
.process-picks-2-page .picks-section .header .title .results-count,
.process-picks-2-page .issues-section .header .title .results-count {
  color: #65707A;
  font-size: 14px;
  margin-left: 10px;
}
.process-picks-2-page .picks-section .header .filter-section,
.process-picks-2-page .issues-section .header .filter-section {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.process-picks-2-page .picks-section .header .filter-section .status-filter,
.process-picks-2-page .issues-section .header .filter-section .status-filter {
  margin-right: 15px;
}
.process-picks-2-page .picks-section .header .filter-section .search-filter,
.process-picks-2-page .issues-section .header .filter-section .search-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.process-picks-2-page .picks-section .picks-grid {
  display: block !important;
}
.process-picks-2-page .picks-section .picks-grid .child-pick-row {
  overflow: hidden;
  background-color: #f7f8fb;
}
.process-picks-2-page .picks-section .picks-grid .child-pick-row:hover {
  background-color: #F5F5F5;
}
.process-picks-2-page .issues-section .issue-item-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.process-picks-2-page .issues-section .issue-item-container:nth-child(n+2) {
  margin-top: 5px;
}
.process-picks-2-page .issues-section .issue-item-container .item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.process-picks-2-page .issues-section .issue-item-container .item .lot-serial {
  margin: 0 10px;
}
.process-picks-2-page .issues-section .issue-item-container .tag {
  font-size: 14px;
  margin-right: 10px;
}
.process-picks-2-page .issues-section .supporting-msg {
  font-size: 12px;
  font-weight: 400;
  color: #BBC2CA;
}