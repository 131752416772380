@import "../../../../../theme/theme";

.participant-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  margin-right: 15px;
  max-height: 49px;
  min-height: 49px;

  .title {
    margin-left: 5px;

    &.inactive {
      color: $C_GREY_COOL_2;
    }
  }

  &.remaining {
    min-width: 100px;
    max-width: 100px;
  }

  > div {
    min-width: 25px;
  }
}