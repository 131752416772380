@import "../../../../../../theme/theme";

.pending-transfer-review-shell {
  .pending-transfer-component {
    margin-bottom: 20px;
    border: none;

    &.collapsed {
      border: 1px solid $C_GREY_WARM_6;
    }

    .component-title {
      font-size: 18px;
    }
  }

  .pending-transfer-error-list {
    background-color: $C_WHITE;
    margin-bottom: 24px;

    .pending-transfer-error-list__item {
      color: $C_RED;
      padding: 12px;
      border: 1px solid $C_GREY_WARM_6;

      &:not(:last-child) {
        margin-bottom: -1px;
      }
    }
  }

  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ccc;
      opacity: .8;
    }
  }
}
