@import "../../../../../../../../theme/theme";

.audit-product-group-container {
  margin-bottom: 12px;

  .product-group-display {
    background-color: $C_WHITE;
    padding: 12px;
    border: 1px solid $C_GREY_COOL_3;
    cursor: pointer;
    box-shadow: 0px 15px 7px -15px $C_GREY_COOL_3;

    &.has-audit-items-to-display {
      border-bottom: none;
    }

    .product-group-name {
      font-size: 14px;
      font-weight: bold;
      color: $C_BLUE_DARKER;
    }

    .product-group-counted {
      font-size: 13px;
    }

    .product-group-diff {
      font-size: 13px;
      color: red;
    }
  }
}
