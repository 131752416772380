@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

/*
Ops Calendar
 */
.opsCalendar {
  height: 100%;
}

/*
Headers
 */
.durationHeader {
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
}
.durationHeader .durationText {
  display: inline-block;
  width: 25rem;
}
.durationHeader button {
  background: 0;
  border: 0;
  padding: 0;
  color: #868E97;
  cursor: pointer;
  outline: 0;
}

.yearHeader {
  text-align: center;
  font-size: 1.5rem;
  color: #979797;
}

.dayHeader {
  text-align: center;
}

/*
Month Grid
 */
.dayHeader,
.dateGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.dateGrid {
  grid-auto-rows: 1fr;
  height: 95%;
  border-top: 1px solid #D6D6D6;
  border-left: 1px solid #D6D6D6;
}
.dateGrid .dateCellToday {
  background-color: #AFE4CF;
}
.dateGrid .dateCellGrey {
  color: #979797;
  background-color: #EFEFEF;
}
.dateGrid .dateCell {
  background-color: #FFFFFF;
}
.dateGrid .dateCellToday,
.dateGrid .dateCellGrey,
.dateGrid .dateCell {
  padding: 1rem;
  height: 100%;
  border-bottom: 1px solid #D6D6D6;
  border-right: 1px solid #D6D6D6;
}
.dateGrid .dateCellToday:hover,
.dateGrid .dateCellGrey:hover,
.dateGrid .dateCell:hover {
  background-color: #DFF4EC;
  cursor: pointer;
}
.dateGrid .dateCellToday .dateKey,
.dateGrid .dateCellGrey .dateKey,
.dateGrid .dateCell .dateKey {
  text-align: right;
}
.dateGrid .dateCellToday .dateValue,
.dateGrid .dateCellGrey .dateValue,
.dateGrid .dateCell .dateValue {
  margin: auto;
  padding: 1rem 0;
  font-size: 3rem;
  text-align: center;
}

/*
Week Grid
 */
.weekGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  height: 100%;
  width: 100vw;
}

.isToday,
.weekCellContainer {
  margin: 5px;
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid #D6D6D6;
  width: 45rem;
}

.isToday {
  background-color: #AFE4CF;
}

.weekCellContainer {
  background-color: #FFFFFF;
}

.weekColumnHeader {
  display: inline-block;
  margin: 1rem auto;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.weekColumn {
  margin-left: 2px;
  margin-bottom: 2px;
}

.weekRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}