@import "../../theme/theme";

.modal-form-section-title-container {
  background-color: $C_GREY_WARM_2;
    padding-top: 10px;
    padding-bottom: 10px;
}

.modal-form-section-title {
  text-align: right;
  color: $C_GREY_COOL_1;
  font-size: 14px;
  font-weight: 300;
}

