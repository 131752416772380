@charset "UTF-8";
@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

a,
a:not([href]) {
  cursor: pointer;
  text-decoration: none;
  color: var(--brand-color-lighter);
}

a:hover,
a:hover:not([href]) {
  color: var(--brand-color);
  text-decoration: none;
}

textarea {
  resize: none;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: 16px;
  line-height: 1.5;
  color: #31373D;
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #BBC2CA;
  border-radius: 5px;
  transition: border-color ease-in-out 0.15s;
}
input:focus,
textarea:focus {
  border-color: var(--brand-color-lighter);
  outline: 0;
}
input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder,
textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #BBC2CA;
  font-weight: 300;
}

input[disabled] {
  background: #f9f9f9;
}

.overflow-hidden {
  overflow: hidden;
}

@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.detail-page-container {
  padding: 23px;
}
.detail-page-container.dashboard {
  padding-top: 20px;
}
.detail-page-container.with-sub-nav {
  padding-top: 54px;
}

.detail-page-title {
  font-size: 20px;
  margin-bottom: 17px;
  display: inline-block;
}

.justify-content-end {
  justify-content: flex-end;
}

.menu div {
  cursor: pointer;
}

.item-block .item-number {
  align-items: center;
  display: flex;
}
.item-block .item-type {
  color: #979797;
  font-size: 12px;
  font-weight: 400;
}
.item-block .item-desc {
  color: #979797;
  font-size: 12px;
  font-weight: 300;
}
.item-block .item-lot-serial {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: top;
}
.item-block .item-location {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.item-block .expand-collapse-toggle {
  align-items: center;
  display: grid;
  grid-template-columns: 32px 30px;
  color: #7BA3CC;
  font-size: 10px;
  font-weight: 200;
}

.grey-text-12 {
  color: #979797;
  font-size: 12px;
  font-weight: 300;
}

.flex-wrap {
  flex-wrap: wrap;
}

.menu-section-header {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  padding-left: 16px;
  width: 100%;
}

.full-height-grid-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 44px;
  bottom: 0px;
  width: 100%;
  min-width: 768px;
  background-color: #f7f7f7;
}
.full-height-grid-container.admin {
  margin-top: 54px;
  padding: 20px;
}

.locked-for-processing {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--brand-color);
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #31373D;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
  box-shadow: inset 0 0 20px 20px #FFFFFF29;
}

html {
  height: 100%;
}

iframe {
  border: none;
}

body {
  background-color: #f7f8fb;
  color: #31373D;
  height: 100%;
  scrollbar-color: #D0D0D0 #f7f8fb;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-weight: 400;
}
body.bg-white {
  background-color: #FDFDFD;
}
body.bg-grey {
  background-color: #f7f8fb;
}
body .filter-transition {
  transition: padding-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
body .filter-transition.filter-nav.filter-open {
  padding-left: 300px;
}
body .filter-transition.filter-nav.filter-closed {
  padding-left: 0;
}
body .filter-transition.filter-content {
  padding-top: 54px;
  padding-right: 23px;
}
body .filter-transition.filter-content.no-padding {
  padding-top: 0;
  padding-right: 0;
}
body .filter-transition.filter-content.filter-open {
  padding-left: 323px;
}
body .filter-transition.filter-content.filter-open.no-padding {
  padding-left: 300px;
}
body .filter-transition.filter-content.filter-closed {
  padding-left: 23px;
}
body .filter-transition.filter-content.filter-closed.no-padding {
  padding-left: 0;
}
body .nav-shell {
  height: 100%;
  display: flex;
  flex-direction: column;
}
body .nav-shell .app-content {
  flex: 1;
  overflow: auto;
  transition: padding 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
body .full-height-flex-column {
  height: 100%;
  display: flex;
  flex-direction: column;
}
body .full-height-flex-row {
  height: 100%;
  display: flex;
  flex-direction: row;
}
body .page-content {
  flex: 1;
  overflow: auto;
  padding: 23px;
}
body .page-content.no-top-padding {
  padding-top: 0;
}
body .tab-page-content {
  flex: 1;
  overflow: auto;
  overflow-y: hidden;
}
body .flex-sizing-min-height {
  min-height: 0;
}
body .align-self-start {
  align-self: flex-start;
}
body .wrap-line {
  word-wrap: break-word;
  hyphens: auto;
}

.Select-clear {
  padding-top: 8px;
}

a {
  color: var(--brand-color-lighter) !important;
}

a:hover {
  color: var(--brand-color) !important;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
   .ie11-flex-content-column {
    display: flex;
    flex-direction: column;
  }
}