@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.audit-detail-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.audit-detail-title .container {
  flex-direction: column;
}
.audit-detail-title .container .title {
  font-size: 18px;
}
.audit-detail-title .container .subtitle {
  font-size: 14px;
  line-height: 12px;
  color: #999999;
}
.audit-detail-title .color-block {
  width: 7px;
  height: 20px;
  margin-right: 10px;
}
.audit-detail-title .color-block.planning {
  color: #FFDF00;
}
.audit-detail-title .color-block.planned {
  color: #22bda9;
}
.audit-detail-title .color-block.open {
  color: #4A90E2;
}
.audit-detail-title .color-block.reconciling {
  color: #FFAC00;
}
.audit-detail-title .color-block.applied {
  color: #699A2E;
}
.audit-detail-title .color-block.unapplied {
  color: #9F0620;
}

.automation-running-overlay {
  cursor: default;
  height: 400px;
  width: 50%;
  margin: auto;
  opacity: 0.75;
  color: #868E97;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 300;
}

.automation-running-overlay .loader {
  margin: 10px;
}

.variance-pill-container {
  padding-top: 5px;
  margin-left: 20px;
}