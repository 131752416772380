.back-order-info-container .pair {
  display: flex;
}
.back-order-info-container .pair div {
  flex: 1;
}
.back-order-info-container .fields {
  flex: 0.5;
  margin-top: 4px;
  padding: 10px;
  background-color: #EEE;
}