@import "../../../theme/theme";

.order-tracking-modal-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.no-map {
    display: flex;
  }

  .left-container {
    overflow-y: auto;
    width: 100%;

    &.with-map:not(.single-order) {
      height: 800px;
    }

    .order-tracking-component {
      border-radius: 4px;
      background-color: $C_WHITE;
      padding: 10px 10px 10px 10px;
      margin: 20px;
      height: 600px;

      &:not(.single-order) {
        box-shadow: 0 0 10px $C_GREY_WARM_6;
        border: 1px solid $C_GREY_WARM_4;
      }

      &.selected:not(.single-order) {
        border: 1px solid $C_BLUE_LIGHTER;
      }

      .title-block {
        display: grid;
        grid-template-columns: 17px 115px 1fr 250px;
        align-items: center;
        margin-bottom: 15px;
        margin-left: 8px;
        color: $C_TEXT;

        .color-block {
          width: 7px;
          min-height: 20px;
          margin-right: 10px;
        }

        .title {
          font-size: 20px;
        }

        .sub-title {
          font-size: 16px;
          line-height: 12px;
          color: $C_GREY_COOL_8;
        }

        .action {
          margin: auto;
          color: $C_BLUE;
        }

        .location-block {
          width: 250px;
          padding-top: 10px;

          .sub-title {
            font-size: 13px;
            line-height: 12px;
            color: $C_GREY_COOL_4;
            padding-bottom: 2px;
          }

          .title {
            font-size: 14px;
            color: $C_TEXT;
          }
        }
      }

      .shipment-container {
        background-color: $C_GREY_WARM_8;
        padding: 7px;
        border-radius: 4px;
        margin-top: 10px;

        &.single-order {
          margin-top: 40px;
        }

        .shipment-header {
          display: grid;
          grid-template-columns: 1.2fr .7fr 1fr;
          padding: 15px 10px 15px 20px;

          .shipment-title {
            display: flex;
            align-items: center;
            color: $C_TEXT;
          }

          .header-block {

            .sub-title {
              font-size: 13px;
              color: $C_GREY_COOL_8;
            }
            .title {
              color: $C_GREY_COOL_1;
              font-size: 14px;
            }

            .carrier {
              font-weight: 400;
            }
          }

          .pill-container {
            margin-top: -4px;
            margin-left: 4px;
          }
        }
      }

      .no-shipments-container {
        background-color: $C_GREY_WARM_1;
        padding: 30px;
        border-radius: 4px;
        margin-top: 10px;
        font-size: 15px;
        text-align: center;
        font-weight: 100;
      }

      .pkg-container:not(.disabled) {
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 8px $C_GREY_COOL_2;
        }
      }

      .empty-pkg-component {
        background-color: $C_WHITE;
        padding: 20px;
        margin: 10px;
        border-radius: 4px;
        color: $C_GREY_COOL_2;
        font-size: 15px;
        text-align: center;
        font-weight: 100;
      }

      .pkg-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 50px;
        align-items: center;
        background-color: $C_WHITE;
        padding: 10px;
        margin: 10px;
        border-radius: 4px;
        border: solid 1px $C_WHITE;



        &.selected {
          box-shadow: 0 0 8px $C_GREY_WARM_4;
          &:hover {
            box-shadow: 0 0 8px $C_GREY_COOL_2;
          }
        }


        .pkg-title {
          display: flex;
          align-items: center;
          color: $C_TEXT;
        }

        .pkg-status {
          align-items: center;
        }

        .pkg-block {
          .sub-title {
            font-size: 12px;
            color: $C_GREY_COOL_8;
          }
          .tracking {
            color: $C_GREY_COOL_1;
            font-size: 16px;
          }
        }
      }
    }
  }

  .location-block {
    width: 320px;

    .sub-title {
      font-size: 13px;
      line-height: 12px;
      color: $C_GREY_COOL_4;
      padding-bottom: 2px;
    }

    .title {
      font-size: 14px;
      color: $C_TEXT;
    }
  }

  .pkg-tooltip-container {
    cursor: help;
    position: relative;
    text-align: center;
  }

  .pkg-tooltip-container:hover {
    cursor: help;
    z-index: 20;
  }

  .pkg-tooltip {
    position: absolute;
    width: 180px;
    height: 77px;
    font-size: 15px;
    background-color: $C_WHITE;
    opacity: .95;
    bottom: 0;
    left: -80px;
    top: -70px;
    display: none;
    border: 1px solid $C_GREY_WARM_4;
    border-radius: 9px;
    color: $C_TEXT;
    text-align: center;
    padding: 17px;
  }

  .pkg-tooltip-container:hover .pkg-tooltip {
    display: block;
  }
}
