@import "../../../../../theme/theme";

$HEIGHT: 120px;
$AVATAR_SIZE: 50px;
$AVATAR_TOP: 5px;

.left-nav-footer {
  position: relative;
  height: $HEIGHT;
  border-top: 1px solid $C_GREY_WARM_2;
  background-color: $C_GREY_WARM_1;

  .avatar {
    position: absolute;
    top: - calc($AVATAR_SIZE/2);
    left: 50%;
    margin-left: - calc($AVATAR_SIZE/2);
    z-index: 1;
  }

  .name-lbl {
    position: absolute;
    top: 34px;
    left: calc($S_GUTTER/2);
    right: calc($S_GUTTER/2);
    color: $C_GREY_COOL_1;
    font-size: 16px;
    text-align: center;
    z-index: 1;
    line-height: 18px;
  }

  .logout-btn-container {
    position: absolute;
    bottom: $S_GUTTER;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
