// Fonts

$F_FAMILY: 'Roboto', sans-serif;
$F_HEADER_FAMILY: 'TTFirsNeue', sans-serif;

$F_SIZE: 14px;

$F_WEIGHT_LIGHT: 300;
$F_WEIGHT_REGULAR: 400;
$F_WEIGHT_MEDIUM: 500;
$F_WEIGHT_BOLD: 700;
$F_WEIGHT_BLACK: 900;

@mixin RobotoLight {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_LIGHT;
}

@mixin RobotoLightItalic {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_LIGHT;
  font-style: italic;
}

@mixin RobotoRegular {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_REGULAR;
}

@mixin RobotoRegularItalic {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_REGULAR;
  font-style: italic;
}

@mixin RobotoMedium {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_MEDIUM;
}

@mixin RobotoMediumItalic {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_MEDIUM;
  font-style: italic;
}

@mixin RobotoBold {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_BOLD;
}

@mixin RobotoBoldItalic {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_BOLD;
  font-style: italic;
}

@mixin RobotoBlack {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_BLACK;
}

@mixin RobotoBlackItalic {
  font-family: $F_FAMILY;
  font-weight: $F_WEIGHT_BLACK;
  font-style: italic;
}

@font-face {
  font-family: $F_HEADER_FAMILY;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// Colors

$C_BLACK: #000000;
$C_BLACK_2: #181818;
$C_WHITE: #FFFFFF;
$C_WHITE_2: #FDFDFD;
$C_GREY_WARM_1: #f7f8fb;
$C_GREY_WARM_2: #EFEFEF;
$C_GREY_WARM_3: #dce2e7;
$C_GREY_WARM_4: #edf1f5;
$C_GREY_WARM_6: #CCCCCC;
$C_GREY_WARM_7: #FAFAFA;
$C_GREY_WARM_8: #f5f7f8;
$C_GREY_WARM_9: #555555;
$C_GREY_WARM_10: #353535;
$C_GREY_WARM_11: #222323;
$C_GREY_COOL_1: #4C5660;
$C_GREY_COOL_1A: #616161;
$C_GREY_COOL_2: #BBC2CA;
$C_GREY_COOL_3: #D6D6D6;
$C_GREY_COOL_4: #65707A;
$C_GREY_COOL_5: #868E97;
$C_GREY_COOL_6: #6D757E;
$C_GREY_COOL_7: #979797;
$C_GREY_COOL_8: #999999;
$C_GREY_COOL_9: #AFAEAE;
$C_GREY_COOL_10: #D0D0D0;
$C_GREY_COOL_11: #D4DCDF;
$C_GREY_COOL_12: #e8ebed;
$C_TEXT: #31373D;
$C_BLUE: #4A90E2;
$C_BLUE_DARKER: #1B82CC;
$C_BLUE_LIGHT: #78ACEA;
$C_BLUE_LIGHTER: #ECF6FF;
$C_BLUE_GREY: #386CA9;
$C_BLUE_GREY_LIGHT: #82A6CE;
$C_BLUE_GREY_LIGHTEST: #d6e5f6;
$C_BLUE_DEEP: #294bb3;
$C_BLUE_DEEP_DARK: #0C2B8C;
$C_BLUE_DEEP_DARKER: #182a5f;
$C_BLUE_DEEP_LIGHT: #1C4BD7;
$C_BLUE_PASTEL: #7BA3CC;
$C_BLUE_PASTEL_2: #6F9FD2;
$C_BLUE_PASTEL_3: #bbe2f2;
$C_BLUE_PASTEL_4: #d7eef7;
$C_LAVENDER: #C1C0EA;
$C_AQUA_LIGHT: #3EBFD0;
$C_AQUA: #018D9E;
$C_AQUA_DARK: #007085;
$C_MINT: #22bda9;
$C_MINT_LIGHT: #50E3C2;
$C_MINT_GREY: #7DBBB2;
$C_MINT_LIGHTER: #AFE4CF;
$C_MINT_LIGHTEST: #DFF4EC;
$C_TEAL: #3DBFC6;
$C_TEAL_LIGHT: #0FC6B5;
$C_TEAL_GREY: #76B1C4;
$C_PURPLE: #AF22C7;
$C_PURPLE_DARK: #911AA5;
$C_PURPLE_LIGHT: #D790E3;
$C_PURPLE_LIGHTER: #FBE5FF;
$C_GREEN: #699A2E;
$C_GREEN_LIGHT: #89C540;
$C_GREEN_LIGHTER: #e4f5cf;
$C_GREEN_LIGHTEST: #f0f7e7;
$C_GREEN_DEEP: #008000;
$C_GREEN_DARKER: #026847;
$C_GREEN_PASTEL: #54825a;
$C_RED: #9F0620;
$C_RED_LIGHT: #FFD4CE;
$C_RED_PASTEL: #CC453F;
$C_RED_PASTEL_LIGHTER: #FFEDEB;
$C_GOLD: #FFDF00;
$C_DEV_RED: #e26464;
$C_QA_GREEN: #69C00A;
$C_SALMON: #FF9889;
$C_SALMON_DARK: #E96649;
$C_ORANGE: #FFAC00;
$C_ORANGE_LIGHT: #FFDB77;
$C_ORANGE_LIGHTER: #F9EBC3;
$C_ORANGE_BURNT: #FF8500;
$C_ORANGE_BURNT_DARK: #CE6A00;
$C_YELLOW: #FFF9AD;
$C_YELLOW_INTERMEDIATE: #FFEB98;
$C_YELLOW_ORANGE: #FEDE87;
$C_TAN: #DAA479;
$C_BROWN: #836844;

// Color Variables
$C_ICON_PRIMARY: $C_GREY_COOL_6;
$C_BORDER_PRIMARY: $C_GREY_COOL_3;
$C_BACKGROUND_PRIMARY: $C_GREY_WARM_1;

// Sizes
$S_HEADER_HEIGHT: 58px;
$S_SUB_HEADER_HEIGHT: 54px;
$S_SIDEBAR_WIDTH: 256px;
$S_FILTER_WIDTH: 300px;
$S_GUTTER: 23px;
$S_SPACING: $S_GUTTER;
$S_BORDER_RADIUS: 5px;

$C_MAP_XS: 600px;
$C_MAP_SM: 800px;
$C_MAP_MD: 1000px;
$C_MAP_LG: 1260px;
$C_MAP_XL: 1500px;

:root {
  // These will be changed later in index.html based on the environment type. See env-theme-css-setup.js for more information.
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

// Bootstrap Grid Responsive Breakpoints

$grid-breakpoints: (
        xs: 0,
        sm: 544px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

// CSS Transitions

$T_BORDER_COLOR: border-color ease-in-out .15s;

// Z-Index
//$Z_MENU: 1000;
$Z_FILTER_BAR: 1101;
$Z_SUB_NAV_BAR: 1100;
$Z_APP_BAR: 1200;
//$Z_drawerOverlay: 1200;
//$Z_navDrawer: 1300;
//$Z_dialogOverlay: 1400;
//$Z_dialog: 1500;
//$Z_layer: 2000;
//$Z_popover: 2100;
//$Z_snackbar: 2900;
$Z_FULL_SCREEN: 1300;
$Z_TOOLTIP: 3000;
