@import "../../theme/theme";

.file-upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    background-color: #000000;
    opacity: 0.8;

    display: flex;
    flex-direction: column;
  }

  .dashed {
    position: fixed;
    top: 40px;
    left: 40px;
    bottom: 40px;
    right: 40px;
    padding: 40px;
    border: 4px dashed $C_WHITE;
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      color: $C_WHITE;
      text-align: center;
      margin-top: 20px;
      font-size: 50px;
    }
  }
}