@import "../../theme/theme";

.saved-search {
  .filter-lbl,
  .footer {
    display: flex;
  }

  .action {
    font-size: 11px;
  }

  .right-el {
    margin-left: auto;
  }

  .saved-search-fav-list {
    padding: 10px 0;

    .active-search-lbl {
      font-size: 12px;
    }

    .active-search-value {
      color: var(--brand-color-lighter);
    }

    .no-favorites {
      color: $C_GREY_COOL_2;
    }

    .saved-search-fav {
      cursor: pointer;
      padding: 5px 7px;
      border-radius: 4px;
      background-color: $C_GREY_WARM_3;
      color: $C_TEXT;
      margin: 5px 0;

      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }

      &.selected {
        background-color: var(--brand-color-lighter);
        color: $C_WHITE;
      }
    }
  }
}

.saved-search-manage-sharing {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .actions {
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  .add-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 12px;

    .share-input {
      flex: 1;
      margin: 0 10px;
    }
  }

  .shared-with-section {
    border-top: 1px solid $C_GREY_WARM_3;
    margin-top: 12px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text {
        flex: 1;
        padding-left: 12px;
      }
    }
  }
}

.shared-search-pill {
  margin-left: 10px;
  padding: 5px;
  color: $C_WHITE;
  background-color: $C_GREY_COOL_2;
  font-size: 10px;
  border-radius: 4px;
}

.shared-with-me-text {
  margin-left: 10px;
  padding: 5px;
  color: $C_GREY_COOL_1A;
  font-size: 10px;
}

.default-search-pill {
  margin-left: 10px;
  padding: 5px;
  color: $C_WHITE;
  background-color: $C_GREEN;
  font-size: 10px;
  border-radius: 4px;
}

.visible-columns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  color: $C_TEXT;

}

.hidden-columns-header {
  margin-top: 20px;
  margin-bottom: 8px;
  color: $C_TEXT;
}
