@import "../../../../theme/theme";

.receiving-tabs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;

  .receiving-tab-nav {
    display: flex;
    border-bottom: 1px solid $C_GREY_WARM_6;
    max-height: 40px;

    .receiving-tab {
      display: flex;
      background-color: $C_GREY_WARM_2;
      padding: 10px 20px;
      border-top: 1px solid $C_GREY_WARM_6;
      border-left: 1px solid $C_GREY_WARM_6;
      border-right: 1px solid $C_GREY_WARM_6;
      cursor: pointer;
      height: 40px;
      align-items: center;

      &.active {
        background-color: $C_WHITE;
      }

      &:not(.active) {
        height: 35px;
        position: relative;
        top: 5px;
        border-bottom: 1px solid $C_GREY_WARM_6;

        &.entries {
          border-right: 0;
        }

        &.contents {
          border-left: 0;
        }
      }
    }
  }

  .receiving-tab-content {
    padding: 10px 20px 20px;
    background-color: white;
    border-left: 1px solid $C_GREY_WARM_6;
    border-bottom: 1px solid $C_GREY_WARM_6;
    border-right: 1px solid $C_GREY_WARM_6;

    .highlight-extra {
      background-color: $C_YELLOW;
    }

    .warn-extra {
      background-color: $C_YELLOW_ORANGE;
    }

    .grid-section ~ .grid-section {
      margin-top: 20px;
    }

    .grid-title {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .entries-grid {
      & .unmatched {
        opacity: .3;
      }

      .reservation-container {
        color: $C_PURPLE;
        text-align: center;
      }
    }

    .restock-picks-container {
      margin-top: 5px;

      .restock-picks-header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .restock-picks-filter {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
}
