@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.receiving-tabs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;
}
.receiving-tabs-container .receiving-tab-nav {
  display: flex;
  border-bottom: 1px solid #CCCCCC;
  max-height: 40px;
}
.receiving-tabs-container .receiving-tab-nav .receiving-tab {
  display: flex;
  background-color: #EFEFEF;
  padding: 10px 20px;
  border-top: 1px solid #CCCCCC;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  cursor: pointer;
  height: 40px;
  align-items: center;
}
.receiving-tabs-container .receiving-tab-nav .receiving-tab.active {
  background-color: #FFFFFF;
}
.receiving-tabs-container .receiving-tab-nav .receiving-tab:not(.active) {
  height: 35px;
  position: relative;
  top: 5px;
  border-bottom: 1px solid #CCCCCC;
}
.receiving-tabs-container .receiving-tab-nav .receiving-tab:not(.active).entries {
  border-right: 0;
}
.receiving-tabs-container .receiving-tab-nav .receiving-tab:not(.active).contents {
  border-left: 0;
}
.receiving-tabs-container .receiving-tab-content {
  padding: 10px 20px 20px;
  background-color: white;
  border-left: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
}
.receiving-tabs-container .receiving-tab-content .highlight-extra {
  background-color: #FFF9AD;
}
.receiving-tabs-container .receiving-tab-content .warn-extra {
  background-color: #FEDE87;
}
.receiving-tabs-container .receiving-tab-content .grid-section ~ .grid-section {
  margin-top: 20px;
}
.receiving-tabs-container .receiving-tab-content .grid-title {
  font-size: 16px;
  margin-bottom: 5px;
}
.receiving-tabs-container .receiving-tab-content .entries-grid .unmatched {
  opacity: 0.3;
}
.receiving-tabs-container .receiving-tab-content .entries-grid .reservation-container {
  color: #AF22C7;
  text-align: center;
}
.receiving-tabs-container .receiving-tab-content .restock-picks-container {
  margin-top: 5px;
}
.receiving-tabs-container .receiving-tab-content .restock-picks-container .restock-picks-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.receiving-tabs-container .receiving-tab-content .restock-picks-container .restock-picks-header .restock-picks-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}