.item-chooser-search-cell .item-header {
  display: flex;
  flex-direction: column;
}
.item-chooser-search-cell .item-header .child-lines-toggle {
  margin-top: 3px;
  font-size: 11px;
  text-transform: uppercase;
}

.move-grid-body .child-row .item-chooser-search-cell .item-column {
  margin-right: 0 !important;
}