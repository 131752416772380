@import "../../../../../../../../theme/theme";

.review-container {
  .item-chooser-cell {
    .attn-required-container {
      text-align: center;

      .attn-required {
        color: $C_DEV_RED !important;

        .attn-icon {
          margin-right: 5px;
        }
      }
    }

    .restock-address {
      .toggle-extended {
        font-size: 11px;
      }
    }
  }
}
