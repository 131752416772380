.attr-conversation-cell .message-preview {
  color: #9c9c9c;
}
.attr-conversation-cell .sent-date {
  text-align: right;
}
.attr-conversation-cell.unread {
  background-color: #FFFFE9;
}
.attr-conversation-cell.unread .sender,
.attr-conversation-cell.unread .subject,
.attr-conversation-cell.unread .sent-date {
  font-weight: 400;
}
.attr-conversation-cell.unread:hover {
  background-color: #FFFFC4;
}
.attr-conversation-cell .cell {
  cursor: pointer;
}