@import "../../../../../../../theme/theme";

.source-stock-container {

  .source-to-search-grid {
    margin-bottom: 10px;
    height: 250px;
    min-height: 250px;
    max-height: 250px;
  }

  .source-stock-grid {

  }
}