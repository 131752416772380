@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.preview-pref-card-modal .search-card-shell .filters-container {
  display: flex;
  padding-bottom: 15px;
}
.preview-pref-card-modal .search-grid-container {
  color: #31373D;
  height: 600px;
}
.preview-pref-card-modal .preview-items-container {
  color: #31373D;
}
.preview-pref-card-modal .preview-items-container .title-block-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15px;
}
.preview-pref-card-modal .preview-items-container .title-block-container .main-title-block {
  display: flex;
  justify-content: center;
  height: 23px;
  margin-bottom: 2px;
  align-items: center;
}
.preview-pref-card-modal .preview-items-container .title-block-container .main-title-block .pill-container {
  margin: 4px 0;
}
.preview-pref-card-modal .preview-items-container .title-block-container .main-title-block .title {
  font-size: 19px;
  font-weight: 500;
}
.preview-pref-card-modal .preview-items-container .title-block-container .subtitle {
  font-size: 18px;
  font-weight: 100;
  display: flex;
  justify-content: center;
}
.preview-pref-card-modal .preview-items-container .items-grid-container {
  height: 545px;
}