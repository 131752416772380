.stepper-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 40px;

  .stepper-divider {
    flex: 1;
    height: 1px;

    &.complete {
      background-color: #373a3c;
    }

    &.incomplete {
      background-color: #ccc;
    }
  }

  .stepper-item {
    position: relative;

    .title {
      position: absolute;
      bottom: 15px;
      left: -45px;
      width: 100px;
      font-size: 12px;
      color: rgb(171, 171, 171);
      text-align: center;

      &.active {
        bottom: 30px;
        left: -35px;
        font-size: 16px;
        color: #373a3c;
      }

      &.complete {
        color: #373a3c;
      }
    }

    .circle {
      height: 10px;
      width: 10px;
      border-radius: 5px;

      &.active {
        height: 30px;
        width: 30px;
        border-radius: 15px;
        border: 1px solid #373a3c;
      }
    }
  }
}