@import "../../theme/theme";

.rfid-el {

  .queued-batch-container {
    background-color: $C_GREY_WARM_4;
    border-bottom: none !important;
    border-radius: 4px;
    padding: 10px;
  }

  .tag-read-batch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $C_GREY_WARM_6;

    .batch-container,
    .kit-container,
    .waiting-container,
    .start-container {
      display: inline-block;
      padding: 10px 10px 10px 0;
      max-width: 200px;

      .title {
        width: 100%;
        margin-left: 5px;
        margin-bottom: 10px;
        color: $C_GREY_WARM_6;
      }

      &.canManuallyStopReader {
        .listening {
          display: block;
          opacity: 1;
          transition: opacity 0.6s linear;
        }

        .stopHint {
          font-size: 12px;
          color: $C_WHITE;
          display: none;
          opacity: 0;
          transition: opacity 0.6s linear;
        }

        > :hover {
          background-color: $C_RED_PASTEL;
          transition: background-color 0.6s linear;
          cursor: pointer;

          .stopHint {
            display: block;
            opacity: 1;
            transition: opacity 0.6s linear;
          }

          .listening {
            display: none;
            opacity: 0;
            transition: opacity 0.6s linear;

          }
        }
      }

      &.errors {
        .info {
          border-color: $C_RED;

          .details {
            .errors {
              color: $C_RED;
            }
          }
        }
      }

      > .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        background-color: $C_WHITE;
        border: 1px solid $C_GREY_WARM_2;
        padding: 10px;
        min-height: 90px;
        min-width: 140px;

        > .header {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: $C_GREY_COOL_7;
          font-weight: 400;

          .header-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
          }

          span {
            cursor: pointer;
          }
        }

        > .details {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }

    .batch-container {
      > .details {
        padding-top: 10px;
      }

      &:hover {
        cursor: pointer;

        > .info {
          border-color: $C_GREY_COOL_7;
          transition: border 0.3s linear;
        }

        &.errors {
          > .info {
            border-color: $C_RED_PASTEL;
          }
        }
      }
    }

    .start-container {
      .info {
        background-color: $C_MINT;
        opacity: 1;
        cursor: default;

        .details {
          color: $C_WHITE;
        }
      }

      &.canStart {
        .info {

          &:hover {
            opacity: .8;
            cursor: pointer;
          }
        }
      }
    }

    .kit-group {
      border: 1px dashed $C_GREY_WARM_6;
      margin: 5px;

      > .info {
        padding: 10px;

        > .header {
          .item-type-pill.kit {
            background-color: $C_GREY_COOL_3;
          }

          .header-row,
          .inspection-failed {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .view-inspection {
            cursor: pointer;
          }
        }
      }

      &.failed-inspection {
        border-color: $C_RED;
      }

      &.inspected {
        border-style: solid;

        > .info {
          > .header {
            .item-type-pill.kit {
              background-color: $C_SALMON;
            }

            .inspection-failed {
              font-size: 12px;
              color: $C_RED;
              cursor: pointer;
              margin-right: 10px;
            }
          }
        }
      }

      .containers {
        display: flex;
        flex-direction: row;

        .kit-container {
          display: flex;
          padding-bottom: 0;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          .info {
            min-height: unset;
            background-color: transparent;

            .item-type-pill {
              background-color: transparent;
            }
          }

          &.picked {
            .info {
              background-color: $C_WHITE;
            }

            &:hover {
              cursor: pointer;

              > .info {
                border-color: $C_GREY_COOL_7;
                transition: border 0.3s linear;
              }

              &.errors {
                > .info {
                  border-color: $C_RED_PASTEL;
                }
              }
            }
          }
        }
      }
    }

    .tote-batch-container {
      border: 1px dashed $C_GREY_WARM_6;
      margin-bottom: 10px;
      margin-right: 10px;
      padding: 5px;

      .tote-batch-contents {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .batch-container {
          padding-right: 10px;
        }
      }
    }
  }
}

.barcode-footer {
  height: 48px;
}

.rfid-footer {
  margin-bottom: 30px;
  font-size: 12px;
  padding-top: 10px;
  border-top: 1px solid $C_GREY_WARM_6;
}

.rfid-input-type {
  display: flex;
  flex: 1;
  align-items: center;
}

.tag-info {
  font-size: 12px;
  color: $C_GREY_COOL_7;

  .tag-number {
    font-weight: 400;
  }

  .tag-batch {
    font-weight: 300;
  }
}
