@import "../../../../theme/theme";

.receipt-box-container {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $C_GREY_WARM_6;

  .shipment-container,
  .kit-container,
  .tray-container,
  .loan-container,
  .bin-container {
    display: inline-block;
    cursor: pointer;
    padding: 5px;

    > .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      background-color: $C_WHITE;
      border: 1px solid $C_GREY_WARM_2;
      padding: 10px;
      opacity: 0.8;
      min-height: 170px;
      min-width: 190px;

      > .line {
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &.selected {
      > .info {
        border-color: $C_BLUE;
        opacity: 1;
      }
    }

    .counts {
      text-align: left;
      margin-top: 10px;
      font-size: 12px;

      > * {
        margin-top: 2px;
      }

      .unmatched-stocks {
        color: $C_GREY_COOL_1;
      }

      .extra-entry-needing-resolution,
      .unmatched-stocks-needing-resolution,
      .inspections-needing-attn {
        color: $C_RED;
      }
    }
  }
}
