@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.filter-sidebar {
  width: 300px;
  transition: margin 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border: 1px solid #D6D6D6;
  scrollbar-color: #D0D0D0 #FDFDFD;
  border-radius: 0 5px 0 0;
  font-weight: 400;
}
.filter-sidebar.no-border-radius {
  border-radius: 0;
}
.filter-sidebar.all-border-radius {
  border-radius: 5px;
}
.filter-sidebar .header {
  border-bottom: 1px solid #D6D6D6;
  font-size: 14px;
  height: 32px;
  line-height: 38px;
  padding: 0 14px;
  flex-wrap: wrap;
  align-content: center;
}
.filter-sidebar .header .clear-anchor {
  font-size: 11px;
  margin-left: 5px;
}
.filter-sidebar .content {
  flex: 1;
  padding: 11.5px 11.5px 64px 11.5px;
  overflow-y: auto;
  overflow-x: hidden;
}
.filter-sidebar .content .divider {
  margin: 11.5px 0;
  background-color: #e2e2e2;
  height: 1px;
}
.filter-sidebar .content .filter-field {
  margin: 10px 0px;
  padding: 5px 10px;
  border: 1px solid #EFEFEF;
  border-radius: 5px;
  background-color: #f5f7f8;
}
.filter-sidebar .content .filter-field:first-child {
  margin-top: 0;
}
.filter-sidebar .content .filter-field .filter-lbl {
  font-size: 12px;
}
.filter-sidebar .content .filter-field .filter-lbl span:not(:last-child) {
  padding-right: 5px;
}
@-moz-document url-prefix() {
  .filter-sidebar .content:after {
    content: "";
    display: block;
    height: 64px;
    opacity: 0;
  }
}