@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.saved-search .filter-lbl,
.saved-search .footer {
  display: flex;
}
.saved-search .action {
  font-size: 11px;
}
.saved-search .right-el {
  margin-left: auto;
}
.saved-search .saved-search-fav-list {
  padding: 10px 0;
}
.saved-search .saved-search-fav-list .active-search-lbl {
  font-size: 12px;
}
.saved-search .saved-search-fav-list .active-search-value {
  color: var(--brand-color-lighter);
}
.saved-search .saved-search-fav-list .no-favorites {
  color: #BBC2CA;
}
.saved-search .saved-search-fav-list .saved-search-fav {
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 4px;
  background-color: #dce2e7;
  color: #31373D;
  margin: 5px 0;
}
.saved-search .saved-search-fav-list .saved-search-fav:first-child {
  margin-top: 0 !important;
}
.saved-search .saved-search-fav-list .saved-search-fav:last-child {
  margin-bottom: 0 !important;
}
.saved-search .saved-search-fav-list .saved-search-fav.selected {
  background-color: var(--brand-color-lighter);
  color: #FFFFFF;
}

.saved-search-manage-sharing .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.saved-search-manage-sharing .header .actions {
  font-size: 10px;
  text-transform: uppercase;
}
.saved-search-manage-sharing .add-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 12px;
}
.saved-search-manage-sharing .add-section .share-input {
  flex: 1;
  margin: 0 10px;
}
.saved-search-manage-sharing .shared-with-section {
  border-top: 1px solid #dce2e7;
  margin-top: 12px;
}
.saved-search-manage-sharing .shared-with-section div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.saved-search-manage-sharing .shared-with-section div .text {
  flex: 1;
  padding-left: 12px;
}

.shared-search-pill {
  margin-left: 10px;
  padding: 5px;
  color: #FFFFFF;
  background-color: #BBC2CA;
  font-size: 10px;
  border-radius: 4px;
}

.shared-with-me-text {
  margin-left: 10px;
  padding: 5px;
  color: #616161;
  font-size: 10px;
}

.default-search-pill {
  margin-left: 10px;
  padding: 5px;
  color: #FFFFFF;
  background-color: #699A2E;
  font-size: 10px;
  border-radius: 4px;
}

.visible-columns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  color: #31373D;
}

.hidden-columns-header {
  margin-top: 20px;
  margin-bottom: 8px;
  color: #31373D;
}