@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.multi-select {
  padding-bottom: 10px;
  margin-top: 5px;
}
.multi-select .Select-control {
  border: none;
  background-color: inherit;
  box-shadow: 0 1px 0 #bbc2ca;
  border-radius: 0;
  height: 28px;
}
.multi-select .Select-control:hover {
  box-shadow: 0 1px 0 #bbc2ca;
}
.multi-select.is-open .Select-control {
  border: none;
  background-color: inherit;
}
.multi-select.is-open .Select-arrow {
  margin-top: 3px;
  transform: rotate(-45deg);
}
.multi-select .Select-placeholder {
  padding-left: 0;
  font-size: 16px;
  color: #bbc2ca;
}
.multi-select.Select--multi .Select-input {
  margin-left: 0;
}
.multi-select .Select-arrow {
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-radius: 1px;
  border-color: #4C5660;
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  position: relative;
  left: 2px;
  top: 8px;
  transform: rotate(135deg);
  vertical-align: top;
}
.multi-select .Select-option {
  background-color: orange;
}
.multi-select .Select-input {
  height: 31px;
}