@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.barcode-input {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 10px;
}
.barcode-input .scan-heading {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.barcode-input .scan-heading .scan-instructions {
  font-size: 20px;
  font-weight: 500;
}
.barcode-input .scan-heading .removal-options {
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 20px;
  width: 350px;
}
.barcode-input .barcode-scanner-container {
  display: flex;
  height: 34px;
}
.barcode-input .scanning-text {
  display: flex;
  align-items: center;
}

.holds-warning {
  color: #9F0620;
  font-size: 16px;
  margin: 20px 0;
}