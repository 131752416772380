@import "theme";

.detail-page-container {
  padding: $S_GUTTER;

  &.dashboard {
    padding-top: 20px;
  }

  &.with-sub-nav {
    padding-top: $S_SUB_HEADER_HEIGHT;
  }
}

.detail-page-title {
  font-size: 20px;
  margin-bottom: 17px;
  display: inline-block;
}


.justify-content-end {
  justify-content: flex-end;
}

.menu div {
  cursor: pointer;
}

.item-block {
  .item-number {
    align-items: center;
    display: flex;
  }

  .item-type {
    color: $C_GREY_COOL_7;
    font-size: 12px;
    font-weight: 400;
  }

  .item-desc {
    color: $C_GREY_COOL_7;
    font-size: 12px;
    font-weight: 300;
  }

  .item-lot-serial {
    display: inline-block;
    margin-left: 1rem;
    vertical-align: top;
  }

  .item-location {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .expand-collapse-toggle {
    align-items: center;
    display: grid;
    grid-template-columns: 32px 30px;
    color: $C_BLUE_PASTEL;
    font-size: 10px;
    font-weight: 200;
  }
}

.grey-text-12 {
  color: $C_GREY_COOL_7;
  font-size: 12px;
  font-weight: 300;
}

.flex-wrap {
  flex-wrap: wrap;
}

.menu-section-header {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  padding-left: 16px;
  width: 100%;
}

.full-height-grid-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 44px;
  bottom: 0px;
  width: 100%;
  min-width: 768px;
  background-color: #f7f7f7;

  &.admin {
    margin-top: $S_SUB_HEADER_HEIGHT;
    padding: 20px;
  }
}

.locked-for-processing {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--brand-color);
  color: $C_WHITE;
  padding: 2px 8px;
  border-radius: $S_BORDER_RADIUS;
}
