@import "../../theme/theme";

@font-face {
  font-family: "TTFirsNeue";
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format('woff2');
}

$LOGIN_BG_URL: "https://static.movemedical.com/login-bg-new-20241024-193739.png";

.auth-page {
  font-family: "TTFirsNeue", sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;

  #logo.dev {
    filter: hue-rotate(325deg);
  }
  #logo.qa {
    filter: hue-rotate(30deg);
  }

  .flex-shrink-0 {
    flex-shrink: 0;
  }

  #logo {
    max-width: 280px;
    height: auto;
    display: block;
    margin: 0 auto 20px auto;
  }

  .login-header {
    font-size: 16px;
    font-weight: 700;
    color: white;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .secondary-action {
    font-size: 14px;
    font-weight: 200;
    color: white;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;

    a, a:hover {
      color: white !important;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  #dev-url {
    font-size: 16px;
    font-weight: 200;
    color: white;
    width: 100%;
    text-align: center;
  }

  #dev-url,
  #copyright {
    padding: 0 10px;
  }

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url($LOGIN_BG_URL), linear-gradient(#171717, #000000);
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
    z-index: -1;
    filter: brightness(0.4) saturate(0.8);

    &.dev {
      filter: hue-rotate(325deg) brightness(0.4) saturate(0.8);
    }

    &.qa {
      filter: hue-rotate(30deg) brightness(0.4) saturate(0.8);
    }
  }

  .form-container {
    padding: 25px;
    text-align: center;

    label {
      font-family: "TTFirsNeue", sans-serif;
      font-size: 14px;
      margin-bottom: 0;
      float: left;
      margin-left: 3px;
    }

    button {
      font-family: "TTFirsNeue", sans-serif !important;
      background-color: var(--brand-color) !important;


      &:disabled{
        background-color: $C_GREY_WARM_4 !important;
      }
    }
  }

  #copyright {
    position: fixed;
    bottom: 10px;
    font-weight: 200;
    opacity: .3;
    font-size: 12px;
    color: white;
    text-align: center;
    width: 100%;
  }

  #build-number {
    position: fixed;
    bottom: 5px;
    right: 5px;
    color: #fff;
    font-size: 10px;
    opacity: .3;
  }
}
