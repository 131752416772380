@import "../../../../theme/theme";

.inventory-processing-info {

  .label {
    font-size: 12px;
    color: $C_GREY_COOL_1A;
    padding-right: 2px;
    font-weight: 500;
  }

  .value {
    font-size: 12px;
    color: $C_GREY_COOL_1A;
  }
}
