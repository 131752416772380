@import "../../../../../../../theme/theme";

.inspection-outcome {
  .pad {
    margin-top: 25px;
  }

  .grid-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .errors {
    color: $C_RED;
  }
}
