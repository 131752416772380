@import "../../../../../../theme/theme";

.stock-search-grid-cell {
  .line-container {
    min-width: 290px;

    .counting {
      padding-left: 10px;
    }

    .not-counting {
      display: flex;
    }
  }
}