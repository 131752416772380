@import "../theme/theme";

.hold-info-pill-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .hold-info-pill {
        display: flex;
        flex-direction: column;
        padding: 2px 4px;
        border-radius: $S_BORDER_RADIUS;
        font-size: 11px;
        background-color: $C_GREY_COOL_5;
        color: $C_WHITE;
        margin-right: 10px;
    }

    .hold-info-display {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: $C_GREY_COOL_5;
        padding-top: 3px;
    }
}
