@import "../../../../../../../Theme/theme";

.manifest-shipment {
  .content-header,
  .packages-grid {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .content-header {
    margin-bottom: 25px;

    .address-validation {
      margin-left: 10px;
      font-size: 16px;
      color: $C_ORANGE;
    }
  }

  .shipped {
    margin-top: 25px;

    .title {
      font-size: 18px;
    }
  }

  .packages-grid {
    .package,
    .tracking,
    .rate {
      flex: 1;
    }

    .dimensions {
      .predefined-box {
        flex: 1
      }

      .dimension-input-toggle {
        font-size: 11px;
        white-space: nowrap;
        padding-left: 10px;
        padding-right: 25px;
      }
    }

    .tracking {
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}
