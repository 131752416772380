@import "theme";

a,
a:not([href]) {
  cursor: pointer;
  text-decoration: none;
  color: var(--brand-color-lighter);
}

a:hover,
a:hover:not([href]) {
  color: var(--brand-color);
  text-decoration: none;
}

textarea {
  resize: none;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: 16px;
  line-height: 1.5;
  color: $C_TEXT;
  background-color: $C_WHITE;
  background-image: none;
  border: 1px solid $C_GREY_COOL_2;
  border-radius: $S_BORDER_RADIUS;
  transition: $T_BORDER_COLOR;

  &:focus {
    border-color: var(--brand-color-lighter);
    outline: 0;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: $C_GREY_COOL_2;
    font-weight: $F_WEIGHT_LIGHT;
  }
}

input[disabled] {
  background: #f9f9f9;
}

.overflow-hidden {
  overflow: hidden;
}
