@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.tray-inspection-details {
  display: flex;
  min-width: 1290px;
}
.tray-inspection-details .directions-panel {
  display: inline-block;
  width: 40%;
  vertical-align: top;
  margin: 0 1rem 2rem 0;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px #EFEFEF;
}
.tray-inspection-details .files-panel {
  display: inline-block;
  width: 60%;
  vertical-align: top;
}
.tray-inspection-details .files-panel .grid-container {
  padding: 25px 80px;
}
.tray-inspection-details .title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
  padding: 20px 0 0 25px;
}
.tray-inspection-details .directions {
  padding: 5px 25px 25px 25px;
  display: inline-block;
  width: 95%;
}

.step-active {
  background-color: #DFF4EC;
}
.step-active:hover {
  background-color: #DFF4EC !important;
}

.step-disabled {
  color: #999999;
  background-color: #f7f8fb;
}
.step-disabled:hover {
  background-color: #f7f8fb !important;
}