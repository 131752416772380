@import "../../../theme/theme";

.attr-note-visibility-badge {
  border-radius: 4px;
  padding: 4px 4px 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;

  &.public {
    background-color: forestgreen;
  }

  &.org {
    background-color: $C_BLUE_DARKER;
  }

  &.org-unit {
    background-color: #A7A7A7;
  }
}
