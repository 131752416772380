@import "../../../../../theme/theme";

.report-menu-item {
  padding: 12px 0 10px;
  line-height: initial !important;
  min-width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .timestamp {
    color: var(--brand-color);
    font-size: 10px;
    margin-left: 20px;
  }

  .scheduled-name {
    font-size: 10px;
    color: $C_MINT;
    margin-top: 5px;
  }
}
