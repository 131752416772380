@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.left-nav-footer {
  position: relative;
  height: 120px;
  border-top: 1px solid #EFEFEF;
  background-color: #f7f8fb;
}
.left-nav-footer .avatar {
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -25px;
  z-index: 1;
}
.left-nav-footer .name-lbl {
  position: absolute;
  top: 34px;
  left: 11.5px;
  right: 11.5px;
  color: #4C5660;
  font-size: 16px;
  text-align: center;
  z-index: 1;
  line-height: 18px;
}
.left-nav-footer .logout-btn-container {
  position: absolute;
  bottom: 23px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}