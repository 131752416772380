@import "../../theme/theme";

.material-symbols-outlined {
  text-align: center;
  font-size: 22px;

  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;

  > span {
    font-size: 22px;
  }

  &.filled {
    font-variation-settings: 'FILL' 1;
  }
}

.group-spacing-10 {
  > *:not(:last-child) {
    margin-right: 10px;
  }
}

.group-spacing-20 {
  > *:not(:last-child) {
    margin-right: 20px;
  }
}
