.full-screen-loading-overlay, .full-screen-feedback-overlay {
  position: fixed;
  cursor: default;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999998;

  padding: 20px;
  background-color: rgba(38, 50, 56, .8);
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 26px;
  font-weight: 300;

  div {
    margin: 10px;
    text-align: center;
    line-height: 25px;

    .sub {
      font-size: 18px;
    }
  }

  .success {
    color: greenyellow;
  }
}