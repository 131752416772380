@import "../theme/theme";

.pref-card-type-pill {
  border-radius: $S_BORDER_RADIUS;
  margin-left: 5px;
  padding: 3px 4px;
  color: $C_WHITE;
  font-size: 11px;
  text-transform: uppercase;
  align-content: center;

  &.org-unit {
    background-color: $C_BLUE_GREY_LIGHT;
  }

  &.facility {
    background-color: $C_MINT;
  }

  &.facility-physician {
    background-color: $C_TEAL;
  }

  &.physician {
    background-color: $C_ORANGE;
  }
}
