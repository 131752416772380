@import "../../../../../theme/theme";

.move-left-nav-container > div {
  box-shadow: none !important;
  transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
}

.move-left-nav-container {
  &.minimized > div {
    width: 60px !important;
    overflow: hidden !important;
    transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }

  &.minimized > div > span > div > div > button {
    opacity: 0;
  }
}

.move-left-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  background-color: $C_BLACK_2;
  scrollbar-color: $C_GREY_COOL_1A $C_BLACK_2;
  font-weight: 300;


  .brand {
    margin: 5px 10px 7px 13px;
    height: 38px;
    cursor: pointer;

    &.dev {
      filter: hue-rotate(325deg) ;
    }
    &.qa {
      filter: hue-rotate(30deg);
    }

    img {
      height: 34px;
      margin: 6px 0 20px;
    }
  }

  .menu {
    flex: 1;
    overflow-y: auto;
  }

  .menu > div > div > div {
    margin: 2px 6px;

    span {
      height: 42px;
    }
  }

  // todo this is lame, but works for aligning the nesting arrow in the smaller buttons
  div > span > div > div > button {
    padding: 0 !important;
    opacity: 1;
    display: block !important;
    transition: display 450ms, opacity 450ms !important;
    right: -5px !important;
  }

  svg {
    color: $C_WHITE_2 !important;
  }

  .list-item-text {
    text-wrap: nowrap;
  }

  .sub-menu-item-container {
    display: flex;
  }

  .sub-menu-item-container  > div:nth-child(2) {
    width: 100%;
  }

  .nested-sub-menu-parent-container {
    display: flex;
    margin: 6px 0 0 10px;
  }

  .nested-sub-menu-parent-container > div {
    width: 100%;
  }

  .nested-sub-menu-item-container  > div:nth-child(2) {
    width: 100%;
  }
}

div.move-left-nav-container.minimized > div > div > div.menu > div > div > div > div > span > div > div > button {
  display: none !important;
  opacity: 0 !important;
}
