@import "../../theme/theme";

.history-event-display-grid {
  .move-grid .move-grid-body .move-grid-cell>div.history-cell {
    align-items: flex-start;
  }

  .move-grid .move-grid-body .move-grid-cell:hover {
    background: inherit;
  }

  .move-grid .move-grid-body .move-grid-cell hr {
    margin: 5px 0;
  }

  .move-grid .move-grid-body .move-grid-cell .record-detail {
    margin: 10px 0;
  }
}
