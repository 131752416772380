@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.pick-container-component {
  position: relative;
  padding: 10px;
  width: 175px;
  border: 1px solid #dddddd;
  background-color: #fafafa;
  border-radius: 4px;
  margin: 10px 10px 10px 0;
  cursor: pointer;
  user-select: none;
}
.pick-container-component .sub-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  color: #00bcd4;
}
.pick-container-component .print-button {
  position: absolute !important;
  right: 0;
}
.pick-container-component.selected {
  border: 1px solid #f0f0b5;
  background-color: #ffffc4;
}