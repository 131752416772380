@import "../../../../../../theme/theme";

.prepare-item-files-modal {
  .item-file-row {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    > div {
      &:first-child {
        margin-bottom: 5px;
      }

      display: flex;
      flex-direction: row;
      align-items: center;

      > div {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}