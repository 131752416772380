/* fullcalendar Custom style
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  height: 0;
  margin: 0;
}

.fc-head {
  margin-bottom: 12px;
}

.fc-more {
  background-color: #a9a9a9;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 10px;
}

.fc td.fc-widget-header {
  font-size: 14px;
  text-transform: uppercase;
  border-width: 0;
}

.fc td.fc-day.fc-widget-content + .fc-widget-content {
  border-left-width: 1px;
}

.fc .fc-row {
  border-width: 0;
}

.fc table > thead > tr > th {
  font-weight: 100;
  border-width: 0;
}

.fc .fc-axis.fc-time {
  text-transform: uppercase;
}

.fc-button.fc-prev-button,
.fc-button.fc-next-button {
  background-color: transparent;
  background-image: none;
  border: 0 solid transparent;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  padding: 0 20px;
}

.fc-button.fc-month-button,
.fc-button.fc-agendaWeek-button,
.fc-button.fc-agendaDay-button {
  height: auto;
  padding: 8px 14px;
  font-size: 14px;
  text-transform: capitalize;
  background-color: #f6f8f8;
  background-image: none;
  border-color: #e4eaec;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fc-button.fc-state-active,
.fc-button.fc-state-hover {
  background-color: #fff;
}

.fc-unthemed .fc-today {
  background-color: #f4f7f8;
}

.fc-agendaWeek-view .fc-event,
.fc-agendaDay-view .fc-event {
  border-radius: 3px;
}

.fc-row.fc-widget-header {
  border-color: transparent;
}

.fc-event {
  background-color: #62a8ea;
  border-color: #62a8ea;
  border-radius: 10px;
}

.fc-day-grid-event .fc-content {
  padding: 2px 10px;
  font-size: 12px;
  line-height: 14px;
}

.fc-basic-view td.fc-day-number {
  padding-top: 5px;
  padding-bottom: 5px;
}

.fc-basic-view .fc-day-number {
  padding: 0 8px;
}

.fc-highlight {
  background: #f3f7f9;
}

@media (max-width: 750px) {
  .fc-button.fc-prev-button {
    padding-left: 0;
  }

  .fc-button.fc-next-button {
    padding-right: 0;
  }
}
.camber-full-calendar .calendar-container {
  overflow: hidden;
}
.camber-full-calendar .panel-heading {
  padding-top: 10px;
  border-width: 0;
}
.camber-full-calendar .panel-body {
  padding-top: 10px;
}
.camber-full-calendar .calendar-list .list-group-item {
  cursor: pointer;
}
.camber-full-calendar .bootstrap-touchspin .input-group-btn-vertical i {
  left: 3px;
}