@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.message-cell {
  background-color: #FFFFFF;
  position: relative;
  user-select: none;
  cursor: pointer;
  /* Urgency Div to left of checkbox*/
  /* Cell Content */
}
.message-cell:hover {
  background-color: #fcfcfc;
}
.message-cell.unread, .message-cell.unread.selected {
  background-color: #FFFBDC;
}
.message-cell.unread:hover, .message-cell.unread.selected:hover {
  background-color: #fffad7;
}
.message-cell.selected {
  opacity: 1;
  box-shadow: 0 2px 2px 0 rgba(160, 167, 175, 0.14);
  z-index: 1;
  background-color: #FFFFFF;
}
.message-cell.selected:hover {
  background-color: #fafafa;
}
.message-cell .checkbox-container {
  padding-left: 10px;
  padding-right: 0;
}
.message-cell .message-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-wrap;
}
.message-cell.selected:before {
  opacity: 1;
}
.message-cell.late:before {
  background-color: #9F0620;
}
.message-cell.today:before {
  background-color: #22bda9;
}
.message-cell.tomorrow:before {
  background-color: #4A90E2;
}
.message-cell.one-week:before, .message-cell.future:before {
  background-color: #4C5660;
}
.message-cell .purpose {
  color: #31373D;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message-cell.selected .purpose {
  color: #000000;
}
.message-cell .participants,
.message-cell .message-preview {
  color: #31373D;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
.message-cell .participants {
  font-weight: 700;
}
.message-cell .message-preview {
  font-weight: 300;
}
.message-cell .timestamp-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.message-cell .timestamp-container .timestamp {
  text-align: right;
  font-size: 13px;
  font-weight: 300;
}
.message-cell .pill-container {
  text-align: right;
  margin-right: 24px;
}
.message-cell .pill-container.tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
}