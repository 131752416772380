@import "../../../theme/theme";

.tag-read-batch-detail-container {
  .page-content {
    padding-bottom: 80px;
  }

  .container-tags-section,
  .component-tags-section,
  .unrecognized-tags-section {
    margin-bottom: 25px;

    &:first-child {
      margin-top: 0;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;

        .errors {
          color: $C_RED;
        }

        .results-count {
          color: $C_GREY_COOL_4;
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }
  }

  .issues-section {
    padding: 10px 0;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;

        .errors {
          color: $C_RED;
        }

        .results-count {
          color: $C_GREY_COOL_4;
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }

    .filter-section {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .status-filter {
        margin-right: 15px;
      }

      .search-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
