@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.shipment-tracking-modal-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.shipment-tracking-modal-container.no-map {
  display: flex;
}
.shipment-tracking-modal-container .left-container {
  overflow-y: auto;
  width: 100%;
}
.shipment-tracking-modal-container .left-container.with-map {
  height: 600px;
}
.shipment-tracking-modal-container .left-container .shipment {
  padding: 10px 10px 10px 10px;
  margin: 20px;
}
.shipment-tracking-modal-container .left-container .shipment .title-block {
  display: grid;
  grid-template-columns: 17px 115px 1fr 250px;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 8px;
  color: #31373D;
}
.shipment-tracking-modal-container .left-container .shipment .title-block .color-block {
  width: 7px;
  min-height: 20px;
  margin-right: 10px;
}
.shipment-tracking-modal-container .left-container .shipment .title-block .shipment-title {
  width: 200px;
}
.shipment-tracking-modal-container .left-container .shipment .title-block .title {
  font-size: 20px;
}
.shipment-tracking-modal-container .left-container .shipment .title-block .sub-title {
  font-size: 16px;
  line-height: 12px;
  color: #999999;
}
.shipment-tracking-modal-container .left-container .shipment .info-block .sub-title {
  font-size: 13px;
  line-height: 12px;
  color: #65707A;
  padding-bottom: 2px;
}
.shipment-tracking-modal-container .left-container .shipment .info-block .title {
  font-size: 14px;
  color: #31373D;
}
.shipment-tracking-modal-container .left-container .shipment .main-body-container {
  border-radius: 4px;
  border: 1px solid #EFEFEF;
  background-color: #FDFDFD;
  margin-top: 20px;
}
.shipment-tracking-modal-container .left-container .shipment .main-body-container .block {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container {
  padding: 20px;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkgs-title {
  font-size: 18px;
  color: #31373D;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .no-pkgs-container {
  background-color: #f5f7f8;
  padding: 30px;
  border-radius: 4px;
  font-size: 15px;
  text-align: center;
  font-weight: 100;
  margin: 15px 0;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkg-container:not(.disabled) {
  cursor: pointer;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkg-container {
  display: grid;
  grid-template-columns: 0.9fr 1fr 1fr 50px;
  align-items: center;
  background-color: #f5f7f8;
  padding: 15px 20px;
  border-radius: 4px;
  margin: 15px 0;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkg-container:hover {
  background-color: #EFEFEF;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkg-container .pkg-title {
  display: flex;
  align-items: center;
  color: #31373D;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkg-container .pkg-status {
  align-items: center;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkg-container .pkg-block .sub-title {
  font-size: 12px;
  color: #999999;
}
.shipment-tracking-modal-container .left-container .shipment .main-pkgs-container .pkg-container .pkg-block .tracking {
  color: #4C5660;
  font-size: 16px;
}
.shipment-tracking-modal-container .location-block {
  width: 320px;
}
.shipment-tracking-modal-container .location-block .sub-title {
  font-size: 13px;
  line-height: 12px;
  color: #65707A;
  padding-bottom: 2px;
}
.shipment-tracking-modal-container .location-block .title {
  font-size: 14px;
  color: #31373D;
}
.shipment-tracking-modal-container .pkg-tooltip-container {
  cursor: help;
  position: relative;
  text-align: center;
}
.shipment-tracking-modal-container .pkg-tooltip-container:hover {
  cursor: help;
  z-index: 20;
}
.shipment-tracking-modal-container .pkg-tooltip {
  position: absolute;
  width: 180px;
  height: 77px;
  font-size: 15px;
  background-color: #FFFFFF;
  opacity: 0.95;
  bottom: 0;
  left: -80px;
  top: -70px;
  display: none;
  border: 1px solid #edf1f5;
  border-radius: 9px;
  color: #31373D;
  text-align: center;
  padding: 17px;
}
.shipment-tracking-modal-container .pkg-tooltip-container:hover .pkg-tooltip {
  display: block;
}