@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.conversation-detail {
  background-color: #fff;
}
.conversation-detail .title-subnav {
  z-index: 1101;
  box-shadow: none;
}
.conversation-detail .title-subnav .purpose {
  font-size: 22px;
  margin-right: 10px;
}
.conversation-detail .participants-subnav {
  box-shadow: none;
  max-height: 49px;
  min-height: 49px;
  padding-right: 0;
  height: auto;
}
.conversation-detail .participants-subnav .add-participant {
  padding-left: 10px;
}
.conversation-detail .participants-subnav .participants-container {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  flex: 10;
}
.conversation-detail .participants-subnav .participants-container .participant-component {
  justify-content: center;
}
.conversation-detail .participants-subnav .participants-container .participant-component:first-of-type {
  justify-content: flex-start;
}
.conversation-detail .messages-panel {
  background-color: #FFFFFF;
  min-height: 60px;
  border-top: 2px solid #4A90E2;
  border-bottom: 2px solid #4A90E2;
  padding-top: 23px;
  padding-bottom: 23px;
  width: 100%;
  position: fixed;
  overflow: auto;
  top: 157px;
  bottom: 102px;
  display: flex;
  flex-flow: column;
}
.conversation-detail .messages-panel .from {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 26px;
  margin-right: 23px;
}
.conversation-detail .messages-panel .from .name {
  color: #4C5660;
  font-size: 15px;
  font-weight: 400;
  margin-left: 8px;
}
.conversation-detail .messages-panel .day {
  font-size: 15px;
  color: #666;
  text-align: center;
  border-top: 1px solid #bbc2ca;
  margin-top: 15px;
  margin-bottom: -25px;
}
.conversation-detail .messages-panel .day-label {
  position: relative;
  top: -17px;
  margin: 0 auto;
  background-color: #fff;
  display: inline-block;
  padding: 0.25rem 0.75rem;
}
.conversation-detail .messages-panel > :first-child {
  margin-top: auto !important;
}
.conversation-detail .messages-panel > :first-child {
  margin-top: auto !important;
}