.inventory-location-badge {
  border-radius: 2px;
  margin-left: 5px;
  padding: 1px 3px;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;

  &.facility {
    background-color: #ccc;
  }

  &.rep {
    background-color: #88cc8f;
  }

  &.team {
    background-color: #7ba3cc;
  }

  &.in-transit {
    background-color: #cc453f;
  }
}