@import "../theme/theme";

.filter-empty-component {
  display: inline-block;
  margin-top: 190px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 300px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  .title {
    font-size: 22px;
    padding-top: 8px;
  }

  .sub-title {
    font-size: 14px;
    padding-top: 7px;
    font-weight: 200;
  }

}
