.package-weight-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.package-weight-input .weight-value {
  flex: 0.5;
  max-width: 50px;
}
.package-weight-input .measurement-type {
  flex: 1;
  max-width: 135px;
  padding-left: 10px;
}