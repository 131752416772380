@import "../../../theme/theme";

.shipment-tracking-modal-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.no-map {
    display: flex;
  }

  .left-container {
    overflow-y: auto;
    width: 100%;

    &.with-map {
      height: 600px;
    }

    .shipment {
      padding: 10px 10px 10px 10px;
      margin: 20px;

      .title-block {
        display: grid;
        grid-template-columns: 17px 115px 1fr 250px;
        align-items: center;
        margin-bottom: 15px;
        margin-left: 8px;
        color: $C_TEXT;

        .color-block {
          width: 7px;
          min-height: 20px;
          margin-right: 10px;
        }

        .shipment-title {
          width: 200px;
        }

        .title {
          font-size: 20px;
        }

        .sub-title {
          font-size: 16px;
          line-height: 12px;
          color: $C_GREY_COOL_8;
        }
      }

      .info-block {
        .sub-title {
          font-size: 13px;
          line-height: 12px;
          color: $C_GREY_COOL_4;
          padding-bottom: 2px;
        }

        .title {
          font-size: 14px;
          color: $C_TEXT;
        }
      }

      .main-body-container {
        border-radius: 4px;
        border: 1px solid $C_GREY_WARM_2;
        background-color: $C_WHITE_2;
        margin-top: 20px;

        .block {
          display: flex;
          justify-content: space-between;
          padding: 20px;
        }
      }

      .main-pkgs-container {
        padding: 20px;

        .pkgs-title {
          font-size: 18px;
          color: $C_TEXT;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
        }

        .no-pkgs-container {
          background-color: $C_GREY_WARM_8;
          padding: 30px;
          border-radius: 4px;
          font-size: 15px;
          text-align: center;
          font-weight: 100;
          margin: 15px 0;
        }

        .pkg-container:not(.disabled) {
          cursor: pointer;

          &:hover {
          }
        }

        .pkg-container {
          display: grid;
          grid-template-columns: .9fr 1fr 1fr 50px;
          align-items: center;
          background-color: $C_GREY_WARM_8;
          padding: 15px 20px;
          border-radius: 4px;
          margin: 15px 0;

          &:hover {
            background-color: $C_GREY_WARM_2;
          }


          &.selected {

            &:hover {
            }
          }

          .pkg-title {
            display: flex;
            align-items: center;
            color: $C_TEXT;
          }

          .pkg-status {
            align-items: center;
          }

          .pkg-block {
            .sub-title {
              font-size: 12px;
              color: $C_GREY_COOL_8;
            }

            .tracking {
              color: $C_GREY_COOL_1;
              font-size: 16px;
            }
          }
        }

      }
    }
  }

  .location-block {
    width: 320px;

    .sub-title {
      font-size: 13px;
      line-height: 12px;
      color: $C_GREY_COOL_4;
      padding-bottom: 2px;
    }

    .title {
      font-size: 14px;
      color: $C_TEXT;
    }
  }

  .pkg-tooltip-container {
    cursor: help;
    position: relative;
    text-align: center;
  }

  .pkg-tooltip-container:hover {
    cursor: help;
    z-index: 20;
  }

  .pkg-tooltip {
    position: absolute;
    width: 180px;
    height: 77px;
    font-size: 15px;
    background-color: $C_WHITE;
    opacity: .95;
    bottom: 0;
    left: -80px;
    top: -70px;
    display: none;
    border: 1px solid $C_GREY_WARM_4;
    border-radius: 9px;
    color: $C_TEXT;
    text-align: center;
    padding: 17px;
  }

  .pkg-tooltip-container:hover .pkg-tooltip {
    display: block;
  }
}
