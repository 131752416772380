@import "../../../../../../../theme/theme";

.pref-card-tab-sub-nav-bar {
  z-index: $Z_SUB_NAV_BAR;
  border-bottom: 1px solid $C_GREY_WARM_1;
  margin-top: -5px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  .middle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &.no-bottom-border {
    border-bottom: none !important;
    box-shadow: none;
  }
}
