@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.lotserial-pill-container {
  display: flex;
  flex-direction: column;
}
.lotserial-pill-container .lotserial-pill {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  color: #FFFFFF;
  margin-right: auto;
  word-break: keep-all;
}
.lotserial-pill-container .lotserial-pill.unknown {
  padding: 0 5px;
  background-color: #FFFFFF;
  border: 1px solid #65707A;
  color: #65707A;
}
.lotserial-pill-container .lotserial-pill.lotScrubbedNotice {
  padding: 0 5px;
  background-color: #FFFFFF;
  border: 1px solid #386CA9;
  color: #386CA9;
}
.lotserial-pill-container .lotserial-pill.lot {
  background-color: #FFAC00;
}
.lotserial-pill-container .lotserial-pill.serial {
  background-color: #294bb3;
}
.lotserial-pill-container .lotserial-pill.spacing-top {
  margin-top: 5px;
}
.lotserial-pill-container .lotserial-pill.spacing-bottom {
  margin-bottom: 5px;
}