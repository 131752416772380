@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.tree-item .tree-item-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tree-item .tree-item-cell .icon {
  cursor: pointer;
}
.tree-item .tree-item-cell.selection-disabled:not(.editable) .name-lbl {
  cursor: default;
}
.tree-item .tree-item-cell.no-children {
  padding-left: 25px;
}
.tree-item .tree-item-cell .name-lbl {
  display: flex;
  cursor: pointer;
  user-select: none;
  padding: 0 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}
.tree-item .tree-item-cell .name-lbl.current-ou {
  background-color: #22bda9;
  color: #FFFFFF;
}
.tree-item .tree-item-cell .subtitle {
  margin-left: 5px;
  font-size: 14px;
  color: #999999;
}
.tree-item .tree-item-cell .nested-item-count {
  margin-left: 3px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  font-size: 11px;
  color: #FFFFFF;
  background-color: var(--brand-color);
  align-self: center;
  margin-bottom: 5px;
  text-align: center;
}
.tree-item .tree-item-cell.selected .name-lbl {
  background-color: #4C5660;
  color: #FFFFFF;
}
.tree-item .tree-item-cell.selected .name-lbl.original-link {
  background-color: var(--brand-color);
}
.tree-item .tree-children {
  padding-left: 15px;
}
.tree-item .tree-children .loading {
  padding-left: 30px;
  font-size: 12px;
  color: #BBC2CA;
}