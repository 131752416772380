@import "../../../../../../theme/theme";

.requirements-container {
  padding-top: 5px;
  min-width: 1082px;

  .component-container {
    background-color: $C_WHITE_2;
    border-radius: $S_BORDER_RADIUS;
    border: solid 1px $C_GREY_WARM_3;
    margin-bottom: 20px;
    min-width: 885px;

    &:hover {
      border-color: $C_GREY_COOL_3;
      box-shadow: 0 0 4px $C_GREY_WARM_3;
    }
  }

  .component-title {
    padding: 5px 0 5px 20px;
    color: $C_TEXT;
    font-size: 16px;
    display: flex;
    min-width: 812px;
    align-items: center;

    .catalog-title {
      display: flex;
      align-items: center;
    }
  }

  .nested-bundle-container {
    margin: 0 20px 20px 20px;
    border-radius: $S_BORDER_RADIUS;
    background-color: $C_GREY_WARM_7;
    border: solid 1px $C_GREY_WARM_2;

    &:hover {
      border-color: $C_GREY_WARM_3;
    }
  }

  .empty-items-container {
    padding-top: 10px;
  }

  .item-number-title {
    display: flex;
  }

  .bundle-desc {
    font-size: 14px;
    color: $C_GREY_COOL_7;
  }

  .catalog-title {
    color: $C_TEXT;
    font-size: 17px;
  }

  .review-component-container {
    padding: 0 20px 20px 20px;
    min-width: 1040px;

    &.combined-view {
      padding: 0 0 20px 0;
    }

    .class-header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      font-weight: 200;
      font-size: 15px;
      padding: 3px 0;
      color: $C_WHITE;
      border-radius: $S_BORDER_RADIUS $S_BORDER_RADIUS 0 0;

      &.collapsed {
        border-radius: $S_BORDER_RADIUS;
      }

      &.implant {
        background-color: $C_BLUE_GREY_LIGHT;
      }

      &.instrument {
        background-color: $C_MINT_GREY;
      }

      &.mixed {
        background-color: $C_TEAL_GREY;
      }

      .expand-collapse-container {
        grid-column: 1;
        padding-left: 15px;
        display: flex;
        align-items: center;
      }

      .class-title {
        grid-column: 2;
        display: flex;
        justify-content: center;
        margin-top: 4px;
      }
    }
  }

}


