@import "../../../theme/theme";

.source-to-case-review-shell {
  .filter-sidebar {
    z-index: 0;
  }

  .search-header {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0 10px;

    .text-search-container {
      flex-basis: 275px;
    }

    .add-btns {
      margin-right: 10px;
    }

    .scan-count {
      margin-left: auto;
      font-weight: 300;
      font-size: 16px;
      color: $C_GREY_COOL_1A;
    }
  }

  .msg-box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
  }

  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ccc;
      opacity: .8;
    }
  }

  .item-chooser-cell {
    .secondary-data {
      border-top: 1px solid $C_GREY_WARM_2;
      margin-top: 5px;
      padding-top: 2px;
      padding-bottom: 5px;
    }

    .secondary-data,
    .inventory-processing-info {
      .label {
        font-size: 10px;
        color: $C_GREY_COOL_1A;
        padding-right: 2px;
        font-weight: 500;
      }

      .value {
        font-size: 10px;
        color: $C_GREY_COOL_1A;
      }
    }

    .qty-col {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
