@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.move-app-bar {
  z-index: 1200;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 11px 0 8px;
  height: 54px;
  position: relative;
  min-width: 730px;
  background-color: #FDFDFD;
  box-shadow: 0 3px 5px -3px #e8ebed;
  border-bottom: solid 1px #D6D6D6;
}
.move-app-bar .left,
.move-app-bar .right {
  flex: 1;
}
.move-app-bar .left {
  margin-right: 23px;
}
.move-app-bar .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.move-app-bar .left .material-symbols-outlined {
  margin-right: 10px;
}
.move-app-bar .left title {
  font-size: 18px;
  line-height: 44px;
  font-weight: 300;
  color: #FDFDFD;
}
.move-app-bar.tall {
  height: 88px;
}
.move-app-bar.dark-grey {
  background-color: #4C5660;
}
.move-app-bar.mint {
  background-color: #22bda9;
}
.move-app-bar .notifications-unread-icon:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #22bda9;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
}
.move-app-bar .global-search-input {
  display: flex;
  margin-right: 5px;
  align-items: center;
  width: 360px;
  border-radius: 5px;
  height: 36px;
  transition: width 450ms, background-color 450ms;
}
.move-app-bar .global-search-input .input {
  margin-left: 5px;
}
.move-app-bar .global-search-input .button {
  position: relative;
  bottom: 12px;
}
.move-app-bar .global-search-input.minimized {
  width: 36px;
  border: none;
}
.move-app-bar .global-search-results-container:first-child {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  max-height: 900px;
}

.user-avatar {
  border: 3px solid #FDFDFD;
}
.user-avatar:hover {
  border-color: #D0D0D0;
}

.multi-org-quick-swap-menu {
  transform: translate(-2px) !important;
}

.multi-org-quick-swap-menu > div > div > div > div {
  padding: 0 !important;
}

.user-avatar-popover-menu > div > div > div > div {
  padding: 0 !important;
}

.user-profile-header {
  display: flex;
}
.user-profile-header .user-name {
  padding-left: 10px;
  color: #31373D !important;
}

.global-search-popover {
  max-height: 760px;
}