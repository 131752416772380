.package-weight-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .weight-value {
    flex: .5;
    max-width: 50px;
  }

  .measurement-type {
    flex: 1;
    max-width: 135px;
    padding-left: 10px;
  }
}
