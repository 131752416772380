@import "../../../../../../../../theme/theme";

.sales-order-detail-warning-item {
  background-color: $C_RED;
  color: $C_WHITE;
  font-weight: $F_WEIGHT_MEDIUM;
  border-radius: $S_BORDER_RADIUS;
  padding: 5px 10px;
}

.sales-order-warning-modal-label {

}

.sales-order-warning-modal-value.code {
  word-wrap: break-word;
  background-color: $C_GREY_WARM_2;
  margin-top: 13px;
}