@import "../theme/theme";

.unverified-pill {
  padding: 2px 4px 2px;
  border-radius: 4px;
  font-size: 11px;
  background-color: $C_GREY_COOL_2;
  color: $C_WHITE;
  display: inline;
  cursor: pointer;
}
