@import "../../../../theme/theme";

$location-width: 110px;
$order-line-width: 275px;
$order-line-row-height: 60px;

$border: 1px solid $C_GREY_COOL_7;
$box-shadow-top: inset 0 8px 8px -8px $C_GREY_COOL_9;
$box-shadow-bottom: inset 0 -8px 8px -8px $C_GREY_COOL_1A;

.matrix-2-component {
  overflow: scroll;
  user-select: none;
  cursor: default;

  .matrix-filters {
    display: flex;
    gap: 24px;
    margin-bottom: 12px;
    width: 800px;

    .filter-select-boolean {
      flex: 0 0 180px;
    }

    .filter-select-multiselect {
      flex: 1;
    }
  }

  .matrix-header-row {
    display: inline-flex;
    position: sticky;
    flex-direction: row;

    top: 0;
    background-color: $C_GREY_WARM_1;
    z-index: 102;

    .sourcing-locations-buffer {
      display: flex;
      flex-direction: row;
      position: sticky;

      left: 0;
      width: $order-line-width;
      min-width: $order-line-width;

      align-items: center;
      padding-right: 10px;
      padding-bottom: 10px;

      background-color: $C_GREY_WARM_1;
      border-right: $border;
      border-bottom: $border;

      .loading-container {
        flex: 1;
        text-align: center;
        justify-content: center;
        align-items: center;

        display: flex;
        flex-direction: row;
      }

      &.right-border-none {
        border-right: none;
      }

      &.bottom-border-none {
        border-bottom: none;
      }
    }

    .sourcing-locations-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      padding-right: 15px;

      .sourcing-location-component {
        display: flex;
        flex-direction: column;

        min-width: $location-width;
        width: $location-width;
        padding: 5px 2px 2px;

        background-color: $C_GREY_WARM_1;
        border-right: $border;
        border-top: $border;
        border-bottom: $border;
        font-size: 11px;

        .location {
          text-align: center;
          line-height: 12px;
          margin-bottom: 5px;
          cursor: pointer;
        }

        .home-location {
          color: $C_GREY_COOL_8;
          text-align: center;
          line-height: 12px;
          margin-bottom: 5px;
        }

        .resp-party {
          color: $C_GREEN_DARKER;
          text-align: center;
          line-height: 12px;
          margin-bottom: 5px;
        }

        .inventory-type {
          color: $C_TEAL;
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 5px;
        }

        .case-event,
        .demand-loan,
        .consignment-loan {
          color: $C_RED;
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 5px;
        }

        .ops-data {
          text-align: center;
          line-height: 12px;
          color: $C_BLUE_DEEP;
          cursor: pointer;
          overflow: hidden;

          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
        }

        .percentage-match {
          flex-shrink: 0;
          padding: 2px;
          text-align: center;
          font-size: 9px;
          color: $C_AQUA_LIGHT;
          font-weight: 300;

          .secondary-stock-percentage {
            color: $C_AQUA_DARK;
          }
        }

        &.virtual-stock {
          background-color: $C_LAVENDER;
        }
      }
    }
  }

  .matrix-content-row-container {
    padding-bottom: 15px;

    .matrix-content-row {
      display: inline-flex;
      flex-direction: row;

      &:last-child {
        .order-lines-container {
          border-bottom: $border;
        }
      }

      .order-lines-container {
        width: $order-line-width;
        min-width: $order-line-width;
        height: 100%;
        flex: 1;

        margin-top: -1px;

        position: sticky;
        left: 0;
        z-index: 101;
        background-color: $C_GREY_WARM_1;

        .order-line-component {

          /*
            If the last component in the container is an item-quantity-cell and not expanded add a bottom border to it. Or if
            its the last component and it is expanded, add a bottom border to that last item-quantity-cell in the child container
          */
          &:last-child {
            & > .item-quantity-cell,
            & > .child-container .item-quantity-cell {
              &:last-child {
                min-height: $order-line-row-height + 1px;
              }
            }
          }

          .item-quantity-cell {
            border-top: $border;
            border-left: $border;
            border-right: $border;

            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: $order-line-row-height + 1px;
            padding-left: 30px;

            &.has-children {
              padding-left: 0;
            }

            &.has-item-alternates-or-reserved-stock {
              height: 100%;
            }

            .item-container {
              flex: 1;
              overflow: hidden;

              &.has-item-alternates-or-reserved-stock {
                padding-top: 8px;
                padding-bottom: 8px;
              }

              .text-overflow-hidden {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .item-number {
                @extend .text-overflow-hidden;
                user-select: all;
                color: $C_BLUE_DARKER;
                font-size: 12px;
              }

              .item-class-and-type {
                @extend .text-overflow-hidden;
                color: black;
                font-size: 10px;
                font-weight: 300;
              }

              .item-desc {
                @extend .text-overflow-hidden;
                user-select: all;
                color: $C_GREY_WARM_6;
                font-size: 10px;
                font-weight: 300;
              }

              .x-alternates-picked {
                @extend .text-overflow-hidden;
                font-size: 10px;
                font-weight: 300;
                border-bottom: 1px solid $C_GREY_WARM_6;
                padding-bottom: 4px;
                margin-top: 4px;
                margin-bottom: 4px;
              }

              .alternate-potential-pick {
                font-size: 10px;
                padding-bottom: 4px;

                .alternate-potential-pick-item-quantity {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 2px;
                }

                .alternate-potential-pick-item-label {
                  @extend .text-overflow-hidden;
                  display: inline-block;
                  vertical-align: middle;
                  color: $C_BLUE_DARKER;
                }

                .alternate-potential-pick-item-location {
                  @extend .text-overflow-hidden;
                  color: $C_GREY_WARM_6;
                }
              }
            }

            .quantity-container {
              align-self: stretch;

              .quantity {
                min-width: 60px;
                width: 60px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $C_GREY_WARM_1;
                border-left: 1px solid $C_GREY_COOL_7;
                position: relative;

                &.open {
                  background-color: $C_YELLOW_INTERMEDIATE;
                }

                .potential-picks-qty {
                  position: absolute;
                  top: 2px;
                  left: 3px;
                  text-align: left;
                  font-size: 9px;
                  color: green;
                }

                .created-picks-qty {
                  position: absolute;
                  top: 2px;
                  right: 3px;
                  text-align: left;
                  font-size: 9px;
                }

                .percentage {
                  position: absolute;
                  bottom: 2px;
                  left: 2px;
                  right: 2px;
                  text-align: center;
                  font-size: 9px;
                  color: $C_AQUA;
                }
              }
            }
          }

          .child-container {
            display: flex;
            flex: 1;
            flex-direction: row;
            position: relative;

            .left-bar {
              width: 10px;
              min-width: 10px;
              border-top: $border;
            }
          }
        }
      }

      .cells-container {
        flex: 1;

        .loading-container {
          min-width: 100px;
        }

        .cell-component-container {
          display: flex;
          flex-direction: row;
          height: $order-line-row-height + 1px;

          &.has-item-alternates-or-reserved-stock {
            height: 100%;
          }

          .cell-component:hover .center-number{
            font-weight: 800;
          }

          .cell-component {
            min-height: $order-line-row-height;
            width: $location-width;
            min-width: $location-width;
            cursor: pointer;
            user-select: none;
            border-right: $border;
            border-bottom: $border;

            display: flex;
            flex-direction: row;

            &.container-with-secondary-qty{
              cursor: default;
            }

            &.no-primary-stock:hover .secondary-stock-container {
              font-weight: 800;
            }

            &.container-with-secondary-qty {
              cursor: default;
            }

            &.picks-created {
              cursor: default;

              &.secondary-stock-container {
                pointer-events: none;
                cursor: default;
                font-weight: 400;
              }
            }

            .center-number {
              flex: 1;
              cursor: pointer;

              display: flex;
              justify-content: center;
              align-items: center;

              font-weight: 500;
              font-size: 16px;
              color: $C_GREY_COOL_1A;

              &.percentage {
                font-weight: 400;
                font-size: 14px;
              }
            }

            .center-number:hover {
              font-weight: 800;
            }

            .center-number.has-picks {
              pointer-events: none;
            }

            .side-column {
              display: flex;
              flex-direction: column;
              width: 30px;
              padding-top: 5px;
              padding-bottom: 5px;

              & > * {
                flex-grow: 1;
                flex-basis: 0;
                align-items: center;
                justify-content: center;
                font-weight: 300;
                font-size: 11px;
              }

              &.left {
                text-align: left;
                padding-left: 7px;

                .top-qty {
                  color: $C_TEXT;
                  font-size: 16px;

                  &.has-secondary {
                    color: red;
                  }
                }

                .top-qty:hover {
                  cursor: pointer;
                  font-weight: 800;
                }
              }

              &.right {
                text-align: right;
                padding-right: 7px;

                .top-qty {
                  color: red;

                }

                .middle-qty &.kit-tray:hover {
                  font-weight: 500;
                  cursor: pointer;
                }

                .middle-qty {
                  color: red;
                }

                .middle-qty &container:hover {
                  font-weight: 500;
                }

                .bottom-qty {
                  color: $C_GREEN;
                }
              }
            }

            &:hover {
              background-color: $C_WHITE_2;
            }

            &.empty {
              pointer-events: none;
            }

            &.empty,
            &.empty:hover,
            &.order-line-filled {
              background-color: $C_GREY_WARM_2;
              cursor: default;
              color: $C_GREY_COOL_2;
            }

            &.virtual-stock {
              background-color: $C_LAVENDER;
            }

            &.picks-created {
              background-color: $C_YELLOW_ORANGE;

              &:hover {
                background-color: $C_YELLOW;
              }

              .center-number {
                color: $C_GREEN_DEEP;
              }
            }

            .secondary-stock-container {
              &.kit-tray:hover {
                font-weight: 800;
                cursor: pointer;
              }
            }

            .secondary-stock-container.has-picks.kit-tray {
                pointer-events: none;
            }
          }
        }
      }
    }

    > div:last-child > .matrix-content-row > .order-lines-container {
      &.expanded {
        border-bottom: 0;
        .child-container {
          > div:last-child {
            border-bottom: $border;
          }
        }
      }
    }
  }
}
