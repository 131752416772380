@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.move-appbar-popover {
  max-height: 800px;
  width: 500px;
  padding: 15px;
  z-index: 100000;
}
.move-appbar-popover > div {
  overflow-y: unset !important;
}
.move-appbar-popover button {
  top: -10px;
  width: 35px !important;
  padding: 0 !important;
}
.move-appbar-popover button.close-button {
  align-self: flex-end;
}
.move-appbar-popover .heading {
  display: flex;
  justify-content: space-between;
  height: 25px;
}
.move-appbar-popover .heading .title-container {
  display: flex;
}
.move-appbar-popover .heading .title-container .title {
  font-size: 16px;
  font-weight: 500;
}
.move-appbar-popover .read-unread-heading-toggle {
  font-size: 12px;
  margin-bottom: 10px;
}
.move-appbar-popover .read-unread-heading-toggle a {
  color: #22bda9 !important;
}
.move-appbar-popover .notification-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #D6D6D6;
}
.move-appbar-popover .notification-item:last-child {
  padding-bottom: 0;
  border: none;
}
.move-appbar-popover .notification-item .new {
  text-transform: uppercase;
  color: #3EBFD0;
  font-size: 11px;
  font-weight: bold;
}
.move-appbar-popover .notification-item .unread {
  font-weight: bold;
}
.move-appbar-popover .notification-item .unread .warning {
  color: #FFAC00;
}
.move-appbar-popover .notification-item .timestamp {
  color: #868E97;
  font-size: 11px;
}