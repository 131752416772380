@import "../../theme/theme";

.multi-select {
  padding-bottom: 10px;
  margin-top: 5px;

  .Select-control {
    border: none;
    background-color: inherit;
    box-shadow: 0 1px 0 rgb(187, 194, 202);
    border-radius: 0;
    height: 28px;

    &:hover {
      box-shadow: 0 1px 0 rgb(187, 194, 202);
    }
  }

  &.is-open {
    .Select-control {
      border: none;
      background-color: inherit;
    }

    .Select-arrow {
      margin-top: 3px;
      transform: rotate(-45deg);
    }
  }

  .Select-placeholder {
    padding-left: 0;
    font-size: 16px;
    color: rgb(187, 194, 202);
  }

  &.Select--multi {
    .Select-input {
      margin-left: 0;
    }
  }

  .Select-arrow {
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-radius: 1px;
    border-color: $C_GREY_COOL_1;
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    position: relative;
    left: 2px;
    top: 8px;
    transform: rotate(135deg);
    vertical-align: top;
  }

  .Select-option {
    background-color: orange;
  }

  .Select-input {
    height: 31px;
  }
}