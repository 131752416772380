@import "../theme/theme";

.loan-status-pill {
  padding: 2px 4px 2px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 11px;
  margin-left: 3px;
  color: $C_WHITE;

  &.planning {
    background-color: $C_GOLD;
  }

  &.awaiting-approval,
  &.awaiting-extension-approval {
    background-color: $C_ORANGE;
  }

  &.planned {
    background-color: $C_GREEN;
  }

  &.active-filling {
    background-color: $C_BLUE;
  }

  &.active-full {
    background-color: $C_MINT;
  }

  &.inactive,
  &.inactive-returning,
  &.denied {
    background-color: $C_RED;
  }

  &.canceled {
    background-color: $C_DEV_RED;
  }

  &.closed {
    background-color: $C_GREY_COOL_5;
  }
}
