@import "../theme/theme";

.kit-container-block {
  display: flex;

  .inline {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .container-name {
      font-weight: 400;
    }

    .container-lot-serial {
      margin-left: 3px;
      font-weight: 300;
    }
  }

  &.single {
    flex-direction: row;
    flex-wrap: wrap;

    > .inline {
      &:not(:last-child) {
        margin-right: 6px;
      }

      &:only-child {
        margin-right: 0;
      }
    }
  }

  &.multi-line {
    flex-direction: column;
  }
}
