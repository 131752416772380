@import "../theme/theme";

.not-in-standard-pill {
  padding: 5px 5px;
  border-radius: $S_BORDER_RADIUS;
  font-size: 12px;
  font-weight: 200;
  background-color: $C_GREY_COOL_9;
  color: $C_WHITE;
}
