.back-order-info-container {
  .pair {
    display: flex;

    div {
      flex: 1;
    }
  }

  .fields {
    flex: 0.5;
    margin-top: 4px;
    padding: 10px;
    background-color: #EEE;
  }
}