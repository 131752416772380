@import "../theme/theme";

.location-type-pill {
  border-radius: 2px;
  margin-left: $S_BORDER_RADIUS;
  padding: 1px 3px;
  color: $C_WHITE;
  font-size: 11px;
  text-transform: uppercase;

  &.facility {
    background-color: $C_GREY_WARM_6;
  }

  &.rep {
    background-color: $C_GREEN_PASTEL;
  }

  &.team {
    background-color: $C_BLUE_PASTEL;
  }

  &.in-transit {
    background-color: $C_RED_PASTEL;
  }
}
