@import "../../../../../theme/theme";

.move-app-bar {
  z-index: $Z_APP_BAR;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 11px 0 8px;
  height: $S_SUB_HEADER_HEIGHT;
  position: relative;
  min-width: 730px;

  background-color: $C_WHITE_2;
  box-shadow: 0 3px 5px -3px $C_GREY_COOL_12;
  border-bottom: solid 1px $C_BORDER_PRIMARY;

  .left,
  .right {
    flex: 1;
  }

  .left {
    margin-right: 23px;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .left {
    .material-symbols-outlined {
      margin-right: 10px;
    }

    title {
      font-size: 18px;
      line-height: 44px;
      font-weight: 300;
      color: $C_WHITE_2;
    }
  }

  &.tall {
    height: 88px;
  }

  &.dark-grey {
    background-color: $C_GREY_COOL_1;
  }

  &.mint {
    background-color: $C_MINT;
  }

  .notifications-unread-icon:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: $C_MINT;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .global-search-input {
    display: flex;
    margin-right: 5px;
    align-items: center;
    width: 360px;
    border-radius: $S_BORDER_RADIUS;
    height: 36px;
    transition: width 450ms, background-color 450ms;

    .input {
      margin-left: 5px;
    }

    .button {
      position: relative;
      bottom: 12px;
    }

    &.minimized {
      width: 36px;
      border: none;
    }
  }

  .global-search-results-container:first-child {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    max-height: 900px;
  }
}

.user-avatar {
  border: 3px solid $C_WHITE_2;

  &:hover {
    border-color: $C_GREY_COOL_10;
  }
}


.multi-org-quick-swap-menu {
  transform: translate(-2px) !important;
}

.multi-org-quick-swap-menu > div > div > div > div {
  padding: 0 !important;
}

.user-avatar-popover-menu > div > div > div > div {
  padding: 0 !important;
}

.user-profile-header {
  display: flex;

  .user-name {
    padding-left: 10px;
    color: $C_TEXT !important;
  }
}

.global-search-popover {
  max-height: 760px;
}
