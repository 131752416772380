@import "../../../../../../theme/theme";

.product-exchange-stock-search {

  .qty-col {
    display: inline-block;

    .item-hold-qty-pill {
      display: inline-block;
    }
  }

  .stock-search-needs-reconcile {

    .filter-field {
      border-color: $C_ORANGE !important;
    }

    .filter-lbl {
      color: $C_ORANGE;
    }
  }
}
