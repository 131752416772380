@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.matrix-2-component {
  overflow: scroll;
  user-select: none;
  cursor: default;
}
.matrix-2-component .matrix-filters {
  display: flex;
  gap: 24px;
  margin-bottom: 12px;
  width: 800px;
}
.matrix-2-component .matrix-filters .filter-select-boolean {
  flex: 0 0 180px;
}
.matrix-2-component .matrix-filters .filter-select-multiselect {
  flex: 1;
}
.matrix-2-component .matrix-header-row {
  display: inline-flex;
  position: sticky;
  flex-direction: row;
  top: 0;
  background-color: #f7f8fb;
  z-index: 102;
}
.matrix-2-component .matrix-header-row .sourcing-locations-buffer {
  display: flex;
  flex-direction: row;
  position: sticky;
  left: 0;
  width: 275px;
  min-width: 275px;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: #f7f8fb;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
}
.matrix-2-component .matrix-header-row .sourcing-locations-buffer .loading-container {
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.matrix-2-component .matrix-header-row .sourcing-locations-buffer.right-border-none {
  border-right: none;
}
.matrix-2-component .matrix-header-row .sourcing-locations-buffer.bottom-border-none {
  border-bottom: none;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-right: 15px;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component {
  display: flex;
  flex-direction: column;
  min-width: 110px;
  width: 110px;
  padding: 5px 2px 2px;
  background-color: #f7f8fb;
  border-right: 1px solid #979797;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  font-size: 11px;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .location {
  text-align: center;
  line-height: 12px;
  margin-bottom: 5px;
  cursor: pointer;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .home-location {
  color: #999999;
  text-align: center;
  line-height: 12px;
  margin-bottom: 5px;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .resp-party {
  color: #026847;
  text-align: center;
  line-height: 12px;
  margin-bottom: 5px;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .inventory-type {
  color: #3DBFC6;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .case-event,
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .demand-loan,
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .consignment-loan {
  color: #9F0620;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .ops-data {
  text-align: center;
  line-height: 12px;
  color: #294bb3;
  cursor: pointer;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .percentage-match {
  flex-shrink: 0;
  padding: 2px;
  text-align: center;
  font-size: 9px;
  color: #3EBFD0;
  font-weight: 300;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component .percentage-match .secondary-stock-percentage {
  color: #007085;
}
.matrix-2-component .matrix-header-row .sourcing-locations-container .sourcing-location-component.virtual-stock {
  background-color: #C1C0EA;
}
.matrix-2-component .matrix-content-row-container {
  padding-bottom: 15px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row {
  display: inline-flex;
  flex-direction: row;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row:last-child .order-lines-container {
  border-bottom: 1px solid #979797;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container {
  width: 275px;
  min-width: 275px;
  height: 100%;
  flex: 1;
  margin-top: -1px;
  position: sticky;
  left: 0;
  z-index: 101;
  background-color: #f7f8fb;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component {
  /*
    If the last component in the container is an item-quantity-cell and not expanded add a bottom border to it. Or if
    its the last component and it is expanded, add a bottom border to that last item-quantity-cell in the child container
  */
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component:last-child > .item-quantity-cell:last-child, .matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component:last-child > .child-container .item-quantity-cell:last-child {
  min-height: 61px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell {
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 61px;
  padding-left: 30px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell.has-children {
  padding-left: 0;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell.has-item-alternates-or-reserved-stock {
  height: 100%;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container {
  flex: 1;
  overflow: hidden;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container.has-item-alternates-or-reserved-stock {
  padding-top: 8px;
  padding-bottom: 8px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .text-overflow-hidden, .matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .alternate-potential-pick .alternate-potential-pick-item-location, .matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .alternate-potential-pick .alternate-potential-pick-item-label, .matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .x-alternates-picked, .matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .item-desc, .matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .item-class-and-type, .matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .item-number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .item-number {
  user-select: all;
  color: #1B82CC;
  font-size: 12px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .item-class-and-type {
  color: black;
  font-size: 10px;
  font-weight: 300;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .item-desc {
  user-select: all;
  color: #CCCCCC;
  font-size: 10px;
  font-weight: 300;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .x-alternates-picked {
  font-size: 10px;
  font-weight: 300;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .alternate-potential-pick {
  font-size: 10px;
  padding-bottom: 4px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .alternate-potential-pick .alternate-potential-pick-item-quantity {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .alternate-potential-pick .alternate-potential-pick-item-label {
  display: inline-block;
  vertical-align: middle;
  color: #1B82CC;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .item-container .alternate-potential-pick .alternate-potential-pick-item-location {
  color: #CCCCCC;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .quantity-container {
  align-self: stretch;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .quantity-container .quantity {
  min-width: 60px;
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f8fb;
  border-left: 1px solid #979797;
  position: relative;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .quantity-container .quantity.open {
  background-color: #FFEB98;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .quantity-container .quantity .potential-picks-qty {
  position: absolute;
  top: 2px;
  left: 3px;
  text-align: left;
  font-size: 9px;
  color: green;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .quantity-container .quantity .created-picks-qty {
  position: absolute;
  top: 2px;
  right: 3px;
  text-align: left;
  font-size: 9px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .item-quantity-cell .quantity-container .quantity .percentage {
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  text-align: center;
  font-size: 9px;
  color: #018D9E;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .child-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .order-lines-container .order-line-component .child-container .left-bar {
  width: 10px;
  min-width: 10px;
  border-top: 1px solid #979797;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container {
  flex: 1;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .loading-container {
  min-width: 100px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container {
  display: flex;
  flex-direction: row;
  height: 61px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container.has-item-alternates-or-reserved-stock {
  height: 100%;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component:hover .center-number {
  font-weight: 800;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component {
  min-height: 60px;
  width: 110px;
  min-width: 110px;
  cursor: pointer;
  user-select: none;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: flex;
  flex-direction: row;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.container-with-secondary-qty {
  cursor: default;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.no-primary-stock:hover .secondary-stock-container {
  font-weight: 800;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.container-with-secondary-qty {
  cursor: default;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.picks-created {
  cursor: default;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.picks-created.secondary-stock-container {
  pointer-events: none;
  cursor: default;
  font-weight: 400;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .center-number {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #616161;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .center-number.percentage {
  font-weight: 400;
  font-size: 14px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .center-number:hover {
  font-weight: 800;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .center-number.has-picks {
  pointer-events: none;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column {
  display: flex;
  flex-direction: column;
  width: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column > * {
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 11px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.left {
  text-align: left;
  padding-left: 7px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.left .top-qty {
  color: #31373D;
  font-size: 16px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.left .top-qty.has-secondary {
  color: red;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.left .top-qty:hover {
  cursor: pointer;
  font-weight: 800;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.right {
  text-align: right;
  padding-right: 7px;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.right .top-qty {
  color: red;
}
.middle-qty .matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.right.kit-tray:hover {
  font-weight: 500;
  cursor: pointer;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.right .middle-qty {
  color: red;
}
.middle-qty .matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.rightcontainer:hover {
  font-weight: 500;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .side-column.right .bottom-qty {
  color: #699A2E;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component:hover {
  background-color: #FDFDFD;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.empty {
  pointer-events: none;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.empty, .matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.empty:hover, .matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.order-line-filled {
  background-color: #EFEFEF;
  cursor: default;
  color: #BBC2CA;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.virtual-stock {
  background-color: #C1C0EA;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.picks-created {
  background-color: #FEDE87;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.picks-created:hover {
  background-color: #FFF9AD;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component.picks-created .center-number {
  color: #008000;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .secondary-stock-container.kit-tray:hover {
  font-weight: 800;
  cursor: pointer;
}
.matrix-2-component .matrix-content-row-container .matrix-content-row .cells-container .cell-component-container .cell-component .secondary-stock-container.has-picks.kit-tray {
  pointer-events: none;
}
.matrix-2-component .matrix-content-row-container > div:last-child > .matrix-content-row > .order-lines-container.expanded {
  border-bottom: 0;
}
.matrix-2-component .matrix-content-row-container > div:last-child > .matrix-content-row > .order-lines-container.expanded .child-container > div:last-child {
  border-bottom: 1px solid #979797;
}