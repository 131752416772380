@import "../../../../../../../theme/theme";

.sales-order-lines-block {
  margin-bottom: 20px;

  .construct-block {
    margin-left: 20px;
    margin-top: 20px;

    .construct-title {
      font-size: 16px;
    }

    .construct-desc {
      font-size: 14px;
      color: $C_GREY_COOL_2;
      margin-top: -5px;
      margin-bottom: 5px;
    }
  }
}
