@import "../../../../../../theme/theme";

.shipping-profile-description {
  margin-bottom: 12px;
}

.shipping-profile-container {
  .shipping-profile-combination-container {
    background-color: white;
    border: 1px solid #eee;
    padding: 16px;
    margin-bottom: 8px;

    .combination {
      flex: 1 1 auto;
      width: 600px;
      min-width: 500px;
      overflow: auto;
      padding-left: 8px;
      padding-right: 8px;

      &__field-name {
        font-weight: bold;
      }
    }
  }
}
