@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.source-to-case-add-shell .filter-sidebar {
  z-index: 0;
}
.source-to-case-add-shell .search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 10px;
  flex-shrink: 0;
}
.source-to-case-add-shell .search-header .batch-container {
  margin-right: 10px;
  flex-basis: 150px;
}
.source-to-case-add-shell .search-header .batch-container .title-lbl {
  font-size: 10px;
  margin-bottom: -10px;
}
.source-to-case-add-shell .search-header .loading-spinner-container {
  margin-right: 16px;
  margin-top: 6px;
}
.source-to-case-add-shell .search-header .scan-barcode-container {
  flex-basis: 275px;
  width: 250px;
}
.source-to-case-add-shell .search-header .scan-barcode-container .barcode-input {
  margin-top: 17px;
  margin-bottom: 10px;
  padding-bottom: 0;
}
.source-to-case-add-shell .search-header .text-search-container {
  margin-top: 0 !important;
  flex-basis: 275px;
  width: 250px;
}
.source-to-case-add-shell .search-header .text-search-container .align-items-center {
  margin-top: 18px;
}
.source-to-case-add-shell .search-header .scan-count {
  margin-left: auto;
  font-weight: 300;
  font-size: 16px;
  color: #616161;
}
.source-to-case-add-shell .msg-box {
  text-align: center;
  padding: 10px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
}
.source-to-case-add-shell .add-cell.highlighted {
  background-color: #EFEFEF;
}
.source-to-case-add-shell .add-cell.flash {
  background-color: #50E3C2;
}
.source-to-case-add-shell .add-cell.fade {
  transition: background-color 0.3s linear;
}
.source-to-case-add-shell .add-cell .qty-col {
  display: flex;
  flex-direction: row;
  align-items: center;
}