@font-face {
  font-family: "TTFirsNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(https://static.movemedical.com/TTFirsNeue-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300;
  src: url(https://static.movemedical.com/material-symbols-outlined-weight-300-fill.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --brand-color: $C_BLUE;
  --brand-color-darker: $C_BLUE_DARKER;
  --brand-color-lighter: $C_BLUE_LIGHT;
  --brand-color-lightest: $C_BLUE_GREY_LIGHTEST;
}

.usage-chooser-stock-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1310;
  background-color: #f7f8fb;
}
.usage-chooser-stock-modal > .modal-header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  height: 80px;
  background-color: #4C5660;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  padding: 0 23px;
}
.usage-chooser-stock-modal > .modal-header .cancel-container {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.usage-chooser-stock-modal > .modal-header .title-container {
  flex: 1;
  text-align: center;
  color: #FFFFFF;
}
.usage-chooser-stock-modal > .modal-header .title-container .title {
  font-size: 16px;
  margin-top: 15px;
}
.usage-chooser-stock-modal > .modal-header .title-container .item-info {
  font-size: 18px;
}
.usage-chooser-stock-modal > .modal-header .clear-container {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.usage-chooser-stock-modal > .content {
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  bottom: 0;
}
.usage-chooser-stock-modal .add-items-container .search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.usage-chooser-stock-modal .add-items-container .search-header .flex-basis-275 {
  flex-basis: 275px;
}
.usage-chooser-stock-modal .add-items-container .item-chooser-search-cell.highlighted {
  background-color: #EFEFEF;
}
.usage-chooser-stock-modal .add-items-container .item-chooser-search-cell.flash {
  background-color: #50E3C2;
}
.usage-chooser-stock-modal .add-items-container .item-chooser-search-cell.fade {
  transition: background-color 0.3s linear;
}
.usage-chooser-stock-modal .add-items-container .item-chooser-search-cell .item-number .explore {
  margin-left: 5px;
  font-size: 12px;
}
.usage-chooser-stock-modal .add-items-container .item-chooser-search-cell .item-desc {
  color: #BBC2CA;
  font-size: 14px;
  font-weight: 300;
}
.usage-chooser-stock-modal .configure-items-container .search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.usage-chooser-stock-modal .configure-items-container .search-header .flex-basis-290 {
  flex-basis: 290px;
}
.usage-chooser-stock-modal .diff-items-container {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 80px;
}
.usage-chooser-stock-modal .diff-items-container .no-changes {
  margin-top: 20px;
  color: #BBC2CA;
  font-size: 22px;
  text-align: center;
}
.usage-chooser-stock-modal .diff-items-container .grid-title {
  font-weight: 500;
  font-size: 22px;
  margin-top: 23px;
}
.usage-chooser-stock-modal .diff-items-container .save-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.usage-chooser-stock-modal .diff-items-container .save-container .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.3;
}