@import "../../theme/theme";

.modal-body {
  color: #76838f !important;

  .form-group {
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 300;
    }
  }
}

.modal-title {
  background-color: var(--brand-color);
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 19px !important;

  &.error {
    background-color: $C_DEV_RED;
  }
}

.alert-modal-body {
  overflow-y: auto !important;
}
